import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";

import { useAuth } from "../../context/auth";
import { useConnStatus } from "../../context/network";

import { useUpdateEffect } from "primereact/hooks";

import { ShowModal } from "./ShowModal";

import {
  useCitations,
  useCitation,
  useDeleteCitation,
} from "../../hooks/citationQueries";

import VisibleContext from "../../context/VisibleContext";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { useProperty } from "../../context/PropertyProvider";

import { CitationPrintable } from "./CitationPrintable";

import { toast } from "react-toastify";

import { TOAST_CONFIG } from "../../utils/Constansts";

import * as moment from "moment";
import { utcToLocale } from "../../helpers/helpers";
import secureLocalStorage from "react-secure-storage";

const Datatable = (permissionsObject) => {
  const navigate = useNavigate();
  const { removeStore, clearUser } = useAuth();
  const galleria = useRef(null);
  const { connStatus } = useConnStatus();

  const {
    VisibleFormContext,
    visibleLoadingModalContext,
    visibleModalContext,
    allowedOfflineActionContext,
    visibleCitationContext,
  } = useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleLoadingModal, setVisibleLoadingModal] =
    visibleLoadingModalContext;
  const [visibleModal, setVisibleModal] = visibleModalContext;
  const [visibleCitation, setVisibleCitation] = visibleCitationContext;

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [citations, setCitations] = useState([]);
  const [officerNames, setOfficerNames] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);

  const [property] = useProperty();

  let idToDelete = 0;

  const [filters, setFilters] = useState({
    details: { value: null, matchMode: FilterMatchMode.CONTAINS },
    license_plate: { value: null, matchMode: FilterMatchMode.CONTAINS },
    officer_name: { value: null, matchMode: FilterMatchMode.IN },
    citation_types: { value: null, matchMode: FilterMatchMode.CONTAINS },
    citation_date: { value: null, matchMode: FilterMatchMode.IN },
  });

  let [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "created_at",
    sortOrder: -1,
    filters: filters,
  });

  useUpdateEffect(() => {
    refetch();
  }, [lazyState, filters]);

  const {
    isPending,
    isSuccess,
    isError: isErrorfetchedCitations,
    data: fetchedCitations,
    error,
    refetch,
  } = useCitations(
    lazyState.rows,
    lazyState.page,
    property,
    lazyState.sortField,
    lazyState.sortOrder,
    lazyState.filters
  );

  const {
    mutate: mutateDeleteCitation,
    data: deletedCitation,
    error: errorDeleteCitation,
    isPending: isPendingDeleteCitation,
    isSuccess: isSuccessDeleteCitation,
    p,
  } = useDeleteCitation();

  const {
    isPending: isPendingCitation,
    isSuccess: isSuccessCitation,
    isError: isErrorCitation,
    data: fetchedCitation,
    errorCitation,
  } = useCitation(visibleModal.itemId, property);

  useUpdateEffect(() => {
    if (isSuccess) {
      setCitations(fetchedCitations?.data.citation.data);
      setTotalRecords(fetchedCitations?.data.citation.total);
      setFrom(fetchedCitations?.data.citation.from);
      setTo(fetchedCitations?.data.citation.to);

      setLoading(false);

      let uniqueOfficers = [];
      fetchedCitations?.data.citation.data.map((citation) => {
        if (uniqueOfficers.indexOf(citation.officer_name) === -1) {
          uniqueOfficers.push(citation.officer_name);
        }
      });

      setOfficerNames(uniqueOfficers.map((item) => ({ officer_name: item })));
    }
    if (isPending) {
      setLoading(true);
    }

    if (isErrorfetchedCitations) {
      if (error?.response.status == 401) {
        toast.error(`You need to authenticate`, TOAST_CONFIG);

        removeStore();
        clearUser();

        navigate("/login");
      }
    }
  }, [fetchedCitations, isErrorfetchedCitations]);

  useUpdateEffect(() => {
    if (isSuccessDeleteCitation) {
      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      toast.success(`You have delete a vechicle citation`, TOAST_CONFIG);
    }
    if (isPendingDeleteCitation) {
      setLoading(true);
      //console.log(isPendingDeleteCitation);
    }
    if (errorDeleteCitation) {
      console.log(errorDeleteCitation);
    }
  }, [deletedCitation]);

  useEffect(() => {
    setlazyState((old) => ({
      ...old,
      first: 0,
      page: 1,
      rows: 10,
    }));
  }, [property]);

  const citationDateBodyTemplate = (citation) => {
    return moment(utcToLocale(citation.citation_date)).format(
      "yyyy-MM-DD HH:mm"
    );
  };

  const createdDateBodyTemplate = (citation) => {
    return moment(utcToLocale(citation.created_at)).format("yyyy-MM-DD HH:mm");
  };

  const darDateDiffBodyTemplate = (citation) => {
    const firstDate = new Date(citation.citation_date);
    const secondDate = new Date(citation.created_at);

    const firstDateInMs = firstDate.getTime();
    const secondDateInMs = secondDate.getTime();

    const differenceBtwDates = secondDateInMs - firstDateInMs;

    const aDayInMs = 24 * 60 * 60 * 1000;

    const daysDiff = Math.round(differenceBtwDates / aDayInMs);
    //console.log(daysDiff)

    return `${daysDiff} days`;
  };

  const detailsTemplate = (citation) => {
    let details = citation.details;

    if (citation.details.length > 100) {
      details = citation.details.substr(0, 100) + "...";
    }
    return details;
  };

  const mapBodyTemplate = (citation) => {
    return (
      <>
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${citation.gps_coordinates}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button icon="pi pi-map-marker" rounded link text />
        </a>
      </>
    );
  };

  const showBodyTemplate = (citation) => {
    return (
      <Button
        severity="secondary"
        icon="pi pi-eye"
        size="small"
        onClick={() =>
          connStatus ? openModal(citation.citation_id) : noConectionAlert()
        }
        style={{ backgroundColor: "#64748b" }}
      />
    );
  };

  const editBodyTemplate = (citation) => {
    if (permissionsObject.permissionsObject.update) {
      return (
        <Button
          severity="info"
          icon="pi pi-pencil"
          size="small"
          onClick={() =>
            connStatus
              ? openSidebarForm("Update DAR", "Update", citation.citation_id)
              : noConectionAlert()
          }
        />
      );
    }
  };

  const printBodyTemplate = (citation) => {
    return (
      <Button
        severity="help"
        icon="pi pi-print"
        size="small"
        onClick={() =>
          connStatus
            ? setVisibleCitation({
                visible: true,
                itemId: citation.citation_id,
              })
            : noConectionAlert()
        }
      />
    );
  };

  const deleteBodyTemplate = (citation) => {
    if (permissionsObject.permissionsObject.delete) {
      //console.log(`allowedOfflineAction 'false' ${connStatus.isOnline}`)
      return (
        <>
          <Button
            icon="pi pi-trash"
            onClick={() =>
              connStatus
                ? confirmDelete(citation.citation_id)
                : noConectionAlert()
            }
            severity="danger"
            size="small"
          />
        </>
      );
    }
  };

  const accept = () => {
    mutateDeleteCitation(idToDelete);
  };

  const noConectionAlert = () => {
    toast.warn(
      "Action not allowed while you don't have internet connection",
      TOAST_CONFIG
    );
  };

  const reject = () => {
    toast.warn("You have rejected", TOAST_CONFIG);
  };

  const confirmDelete = (id) => {
    idToDelete = id;

    confirmDialog({
      key: 1,
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const onSelectionChange = (event) => {
    console.log(6666);
    const value = event.value;

    setSelectedItem(value);
    //setSelectAll(value.length === totalRecords);
  };

  const isSelectable = (data) => true;

  const isRowSelectable = (event) =>
    event.data ? isSelectable(event.data) : true;

  const onPage = (event) => {
    console.log(event.page);
    event.page = event.page + 1;
    setlazyState(event);
  };

  const onSort = (event) => {
    console.log(event);
    event.page = 0;

    if (event.sortField == null) {
      event.sortField = "created_at";
      event.sortOrder = 1;
    }
    setlazyState(event);
    //setlazyState(event);
  };

  const date_from = useRef();

  const onFilter = (event) => {
    //
    //console.log(new Date(event.filters.citation_date.value[0]).toISOString())

    let enhanceEvent = event;

    if (
      enhanceEvent.filters.citation_date.value &&
      !!enhanceEvent.filters.citation_date.value[0]
    ) {
      //enhanceEvent.filters.citation_date.value[0] = new Date(enhanceEvent.filters.citation_date.value[0]).toISOString()

      enhanceEvent.filters["from"] = {
        value: new Date(
          enhanceEvent.filters.citation_date.value[0]
        ).toISOString(),
      };
      enhanceEvent.filters.citation_date.value[0] = null;
    }

    //if(enhanceEvent.filters.citation_date.value && enhanceEvent.filters.citation_date.value[1]){
    if (
      enhanceEvent.filters.citation_date.value &&
      !!enhanceEvent.filters.citation_date.value[1]
    ) {
      enhanceEvent.filters["to"] = {
        value: new Date(
          enhanceEvent.filters.citation_date.value[1]
        ).toISOString(),
      };
      enhanceEvent.filters.citation_date.value[1] = null;
    }
    //console.log(enhanceEvent)
    //return
    enhanceEvent.page = 0;
    enhanceEvent["first"] = 0;
    setlazyState(enhanceEvent);
  };

  const openSidebarForm = (modalTitle, modalButton, id) => {
    setVisibleForm({
      visible: true,
      itemId: id,
      title: modalTitle,
    });
    setVisibleLoadingModal({
      visible: true,
      itemId: null,
      title: null,
    });
  };

  const openModal = (id) => {
    setVisibleModal({
      visible: true,
      itemId: id,
    });
  };

  const onHideModal = () => {
    setVisibleModal({
      visible: false,
      itemId: null,
    });
  };

  const itemTemplate = (item) => {
    return <img src={item} style={{ width: "100%", display: "block" }} />;
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item}
        style={{ maxWidth: "40%", width: "90px", display: "block" }}
      />
    );
  };

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  const footer = () => {
    if (totalRecords > 0) {
      return `Showing: ${from} - ${to} of ${totalRecords}`;
    }
    return null;
  };

  const representativesItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option.officer_name}</span>
      </div>
    );
  };

  const representativeRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={officerNames}
        itemTemplate={representativesItemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="officer_name"
        placeholder="Officer"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
      />
    );
  };

  const handleClear = () => {
    //lazyState, setlazyState
    console.log(lazyState);
    let newLazyState = lazyState;

    newLazyState.filters.to = null;
    newLazyState.filters.from = null;

    setlazyState(newLazyState);
  };

  const dateFilterTemplate1 = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        selectionMode="range"
        hideOnRangeSelection
        style={{ zIndex: 999999 }}
        className="z-5"
        hourFormat="24"
        maxDate={new Date()}
        hideOnDateTimeSelect
      />
    );
  };

  const dateFilterTemplate = (options) => {
    return (
      <>
        <InputText ref={date_from} type="date" id="date_from" />
        <InputText type="date" />
      </>
    );
  };

  return (
    <>
      <DataTable
        //scrollHeight="500px"
        tableStyle={{ minWidth: "60rem" }}
        //resizableColumns
        //tableStyle={{tableLayout:'fixed'}}
        //columnResizeMode="expand"

        value={citations}
        lazy
        dataKey="citation_id_format"
        paginator
        footer={footer}
        size="small"
        stripedRows
        scrollable
        /*onRowSelect={(e) =>
          permissionsObject.permissionsObject.update
            ? connStatus
              ? openSidebarForm(
                  "Update Incident Report",
                  "Update",
                  e.data.citation_id
                )
              : noConectionAlert()
            : null
        }*/
        onRowSelect={(e) =>
          connStatus ? openModal(e.data.citation_id) : noConectionAlert()
        }
        selectionMode="single"
        metaKeySelection={false}
        first={lazyState.first}
        rows={lazyState.rows}
        totalRecords={totalRecords}
        rowsPerPageOptions={[5, 10, 25, 50]}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
        onFilter={onFilter}
        filters={lazyState.filters}
        filterDisplay="menu"
        loading={loading}
        selection={selectedItem}
        isDataSelectable={isRowSelectable}
      >
        <Column
          field="citation_id_format"
          hidden
          header="ID"
          filterPlaceholder="Search"
        />
        <Column
          field="created_at"
          dataType="date"
          header="Created At"
          sortable
          body={createdDateBodyTemplate}
          filterPlaceholder="Search"
          //headerClassName=" w-19rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="citation_date"
          dataType="date"
          //filter
          //filterElement={dateFilterTemplate}
          header="Citation Date"
          showFilterMatchModes={false}
          sortable
          onFilterClear={handleClear}
          body={citationDateBodyTemplate}
          filterPlaceholder="Search"
          //headerClassName=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          header="License Plate"
          field="license_plate"
          sortable
          filter
          showFilterMatchModes={false}
          //headerClassName=" w-10rem md:w-8rem lg:w-5rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          header="Citation Received"
          field="citation_types"
          sortable
          filter
          filterField="citation_types"
          showFilterMatchModes={false}
          //headerClassName=" w-19rem md:w-14rem lg:w-16rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="officer_name"
          header="User"
          sortable
          filterField="officer_name"
          showFilterMatchModes={false}
          filter
          //filterElement={representativeRowFilterTemplate}

          //headerClassName=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          header="Location"
          hidden
          body={mapBodyTemplate}
          filterPlaceholder="Search"
        />
        <Column
          field="details"
          header="Details"
          sortable
          filter
          showFilterMatchModes={false}
          body={detailsTemplate}
          //headerClassName=" w-17rem md:w-18rem lg:w-22rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          header=""
          body={editBodyTemplate} //headerClassName="w-1rem"
        />

        <Column
          header=""
          body={printBodyTemplate} //headerClassName="w-1rem"
        />
        <Column
          header=""
          body={deleteBodyTemplate} //headerClassName="w-1rem"
        />
      </DataTable>

      <ShowModal />

      <CitationPrintable />
    </>
  );
};

export default Datatable;
