import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchUser,
  fetchUsers,
  fetchRoles,
  addUser,
  updateUser,
  deleteUser,
} from "../api/userApi";


export function useRoles() {
  return useQuery({
    queryKey: ["roles"],
    queryFn: () => fetchRoles(),
    retry: false,   
    //enabled: !!user,
    onSuccess: () => {
      console.log("ALL GOOD")
    },

  });
}

export function useUsers(rows, page, propertyId) {
  return useQuery({
    queryKey: ["users", { rows, page, propertyId }],
    queryFn: () => fetchUsers(rows, page, propertyId),
    retry: false,
    //staleTime: 10,
    refetchInterval:400000,
    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }

  });
}


export function useUser(userId) {
  return useQuery({
    queryKey: ["user", { userId }],
    queryFn: () => userId != null ? fetchUser(userId) : null,
    retry: false,

    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }
  });
}

export const useInsertUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addUser,
    networkMode: "offlineFirst",
    retry: false,
    throwOnError: false,
    onSuccess: () =>{
      queryClient.invalidateQueries({
        queryKey: ["users"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: (error) => {
      console.log(error)
      
    },
  })
}

export function useUpdateUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateUser,
    //networkMode: offlineFirst,
    onSuccess: () =>{
      queryClient.invalidateQueries({
        queryKey: ["users"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: () => {
      console.log("error occured")
      
    },
  })
}

export function useDeleteUser(){
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteUser,

    onSuccess(){
      queryClient.invalidateQueries({
        queryKey: ["users"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    }
  })

} 
