import axios from "./axios";
import * as moment from "moment";
import { useLocalState } from "../hooks/useLocalState";
import secureLocalStorage from "react-secure-storage";
import { DB_NAME, DB_VERSION } from "../utils/Constansts";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../utils/Constansts";

import { blobToArrayBuffer } from "../helpers/helpers";

//import { ACCESS_TOKEN } from "../utils/Constansts";

export async function fetchCreateCitations(property_id) {
  let url = `citation/create/${property_id}`;
  
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    throw error;
    //return error;
  }
}

export async function fetchCitations(rows, page, property_id, sort_field, sort_order, queryFilters) {
  let url = `citation/list/${rows}/${sort_field}/${sort_order}?page=${page}&context_property_id=${property_id}`;
  //console.log(queryFilters)
  if(queryFilters){
    url = url + queryFilters
  }
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    throw error;
    //return error;
  }
}


export async function fetchCitationTypes() {
  let url = "citationtype";
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    throw error;
  }
}

export function fetchCitation(id,context_property_id) {
  let url = `citation/show?citation_id=${id}&context_property_id=${context_property_id}`;
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    return error;
  }
}

export async function addCitation(data) {
  let url = `citation/store`;
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axios.post(url, data.json, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response;
  } catch (error) {
    //console.log(error)
    //console.log(data.persistOnStorage)

    //console.log(data);

    if (data && data.persistOnStorage) {
      //console.log(data.json.images_urls);
      //toast.success('step1',TOAST_CONFIG);
      //return

      if (data.json.images_urls.length > 0){
        //console.log("aaaca");
        let imagesArray = [];
        for (let number = 0; number < data.json.images_urls.length; number++) {
          //console.log("aaaca 2222");
          //console.log(data.json.images_urls[number]);
          imagesArray.push(
            await blobToArrayBuffer(data.json.images_urls[number])
          );
          //toast.success('step2',TOAST_CONFIG);
        }

        //console.log(imagesArray);
        //toast.success('step3',TOAST_CONFIG);
        
        if (imagesArray.length > 0){
          //console.log("aaaca 3333");
          data.json.images_urls = imagesArray
          //toast.success('step4',TOAST_CONFIG);
        }
      }
      //console.log(data.json);
      //return
      const requestDb = indexedDB.open(DB_NAME, DB_VERSION);

      requestDb.onerror = function () {
        console.log("error");
      };

      requestDb.onsuccess = function () {
        const db = requestDb.result;
        const transaction = db.transaction("offline_data", "readwrite");
        const store = transaction.objectStore("offline_data");

        let formattedDate = new Date();
        /*let randomId =
          formattedDate.getHours().toString() +
          formattedDate.getMinutes().toString() +
          formattedDate.getMilliseconds().toString();*/

        //console.log(store.autoIncrement);

        const stored = store.add({
          //id: randomId,
          object_type: "incidentReport",
          url: url,
          json: data.json,
          //imagesArray: imagesArray,
          date: moment(new Date()).format("yyyy-MM-DD").toString(),
          is_deleted: 0,
        });

        stored.onsuccess = () => {
          console.log("Reporte agregado al almacén", stored.result);
        };

        stored.onerror = function () {
          console.log("Error", stored.error);
        };

        transaction.oncomplete = function () {
          console.log("Transacción completa");
          //return "Transacción completa"
        };
      };
    }
    //return
    //throw new Error("I'm an error");
    throw error
  }
}

export function updateCitation(json) {
  let url = `citation/update`;
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  return axios.post(url, json, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
}

export function deleteCitation(id) {
  let url = `citation/delete`;
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  return axios.delete(url, {
    data: { citation_id: id },
    headers: { Authorization: `Bearer ${accessToken}` },
  });
}


