import { useState, useEffect } from "react";

import { useConnStatus } from "../../context/network";

import { useUpdateEffect } from "primereact/hooks";

import { useProperty as useContextProperty } from "../../context/PropertyProvider";

import { useProperties, useDeleteProperty } from "../../hooks/propertyQueries";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import { toast } from "react-toastify";

import { TOAST_CONFIG } from "../../utils/Constansts";

import * as moment from "moment";
import { utcToLocale } from "../../helpers/helpers";

const Datatable = ({ permissionsObject, onShowDetail, onEdit }) => {
  const { connStatus } = useConnStatus();

  const property = useContextProperty();

  let [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "properties_id",
    sortOrder: 0,
    filters: {
      daily_activity_report_id_format: { value: "", matchMode: "contains" },
      starting_details: { starting_details: "", matchMode: "contains" },
    },
  });

  const {
    isPending,
    isSuccess,
    isError: isErrorfetchedProperties,
    data: fetchedProperties,
  } = useProperties(lazyState.rows, lazyState.page, property);

  const {
    mutate: mutateDeleteProperty,
    data: deletedProperty,
    error: errorDeleteProperty,
    isPending: isPendingDeleteProperty,
    isSuccess: isSuccessDeleteProperty,
    p,
  } = useDeleteProperty();

  // const {
  //   isPending: isPendingProperty,
  //   isSuccess: isSuccessProperty,
  //   isError: isErrorProperty,
  //   data: fetchedProperty,
  //   errorProperty,
  // } = useProperty(visibleModal.itemId);

  const properties = fetchedProperties?.data.properties.data;
  const totalRecords = fetchedProperties?.data.properties.total;
  const from = fetchedProperties?.data.properties.from;
  const to = fetchedProperties?.data.properties.to;

  useUpdateEffect(() => {
    if (isSuccessDeleteProperty) {
      // setVisibleForm({
      //   visible: false,
      //   itemId: null,
      //   title: null,
      // });

      toast.success(`You have deleted property`, TOAST_CONFIG);
    }

    if (errorDeleteProperty) {
      console.log(errorDeleteProperty);
    }
  }, [deletedProperty]);

  useEffect(() => {
    setlazyState((old) => ({
      ...old,
      first: 0,
      page: 1,
      rows: 10,
    }));
  }, [property]);

  const createAtDateBodyTemplate = (property) => {
    return moment(utcToLocale(property.created_at)).format("yyyy-MM-DD HH:mm");
  };

  const addressBodyTemplate = (property) => {
    return (
      property.street +
      " " +
      property.number +
      " " +
      property.city +
      " " +
      property.zip_code
    );
  };

  const deleteBodyTemplate = (property) => {
    if (permissionsObject.delete) {
      //console.log(`allowedOfflineAction 'false' ${connStatus.isOnline}`)
      return (
        <>
          <Button
            icon="pi pi-trash"
            onClick={() =>
              connStatus
                ? confirmDelete(property.properties_id)
                : noConectionAlert()
            }
            severity="danger"
            size="small"
          />
        </>
      );
    }
  };

  const noConectionAlert = () => {
    toast.warn(
      "Action not allowed while you don't have internet connection",
      TOAST_CONFIG
    );
  };

  const confirmDelete = (id) => {
    confirmDialog({
      key: 1,
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => mutateDeleteProperty(id),
      reject: () => {
        toast.warn("You have rejected", TOAST_CONFIG);
      },
    });
  };

  const isSelectable = (data) => true;

  /*POR SI TENEMOS Q APLICAR EL PERMISO SHOW SOBRE CADA ITEM EN PARTICULAR
  const isRowSelectable = (event) =>{
    if(showPermission){
      if(event.data){
        isSelectable(event.data)
        return true
      }  
    } 
  }*/

  const isRowSelectable = (event) =>
    event.data ? isSelectable(event.data) : true;

  const onPage = (event) => {
    event.page = event.page + 1;
    setlazyState(event);
  };

  const onSort = (event) => {
    setlazyState(event);
  };

  const onFilter = (event) => {
    event["first"] = 0;
    setlazyState(event);
  };

  const footer = () => {
    if (totalRecords > 0) {
      return `Showing: ${from} - ${to} of ${totalRecords}`;
    }
    return null;
  };

  return (
    <>
      <DataTable
        className={"h-full hide-on-print"}
        resizableColumns
        columnResizeMode="expand"
        value={properties}
        lazy
        dataKey="property_id"
        paginator
        footer={footer}
        size="small"
        stripedRows
        scrollable
        onRowSelect={(e) =>
          connStatus ? onEdit(e.data.properties_id) : noConectionAlert()
        }
        selectionMode="single"
        metaKeySelection={false}
        first={lazyState.first}
        rows={lazyState.rows}
        totalRecords={totalRecords}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
        rowsPerPageOptions={[5, 10, 25, 50]}
        onFilter={onFilter}
        filters={lazyState.filters}
        loading={isPending || isPendingDeleteProperty}
        tableStyle={{ minWidth: "50rem" }}
        // selection={selectedItem}
        isDataSelectable={isRowSelectable}
      >
        <Column
          field="dispatch_log_id_format"
          hidden
          header="ID"
          filterPlaceholder="Search"
        />

        <Column
          field="created_at"
          header="Created At"
          body={createAtDateBodyTemplate}
          filterPlaceholder="Search"
          className=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="address"
          header="Address"
          body={addressBodyTemplate}
          filterPlaceholder="Search"
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="units"
          header="Units"
          filterPlaceholder="Search"
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="contact_name"
          header="Contact Name"
          filterPlaceholder="Search"
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="email"
          header="Email"
          filterPlaceholder="Search"
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="maintenance_email"
          header="Maintenance Email"
          filterPlaceholder="Search"
          style={{ whiteSpace: "unset" }}
        />

        <Column header="" body={deleteBodyTemplate} className="w-1rem" />
      </DataTable>
    </>
  );
};

export default Datatable;
