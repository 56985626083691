import Sidemenu from "./Sidemenu";
// import BottomTab from "./BottomTab";


import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { isMobile } from "react-device-detect";

export const Layout = () => {


  return (
    <>
    
        <>
          <Sidemenu />
        </>
     
      <>
        <div className="layout-main h-screen sm:h-fit md:h-fit lg:h-max">
          <Outlet></Outlet>
        </div>

        
          
          {!isMobile ? (
            <div className="layout-footer">
            <small>by Nico</small>
            </div>
          ) : (
            <div className="bottom-tab">
              {/* <BottomTab /> */}
            </div>
          )}
          
        
      </>

     
        

    </>
  );
};



export default Layout;
