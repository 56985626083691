import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import { Toast } from 'primereact/toast';
import { useLocalState } from '../hooks/useLocalState';
import { useLogout } from '../hooks/loginQueries';
import useAuth from '../hooks/useAuth';

const Logout = () => {
  const toast = useRef(null);
  const navigate = useNavigate();

  const [accessToken,setAccessToken] = useLocalState("", "accessToken")
  

  const {
    isPending,
    isSuccess,
    isError,
    data,
    error,
  } = useLogout();
  
  /*
  const logout = async (id) => {
    try {

      const response = await axios.get('auth/logout',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }

        });

        localStorage.removeItem("accessToken");
        localStorage.removeItem("dailyActivityReportTypes");
        localStorage.removeItem("dailyActivityReportCodes");
        localStorage.removeItem("userProperties");
        localStorage.removeItem("userContextProperty");
        localStorage.removeItem("userData");
        localStorage.removeItem("roles");
        
        
      navigate('/login');


    } catch (err) {
      if (err.code === 'ERR_INTERNET_DISCONNECTED') {
        //console.error("RROR", err.code);
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.code, life: 3000 });
      } else if (!err?.response) {
        //setErrMsg('No server response');
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'No server response', life: 3000 });
      } else if (err.response?.status === 400) {
        //setErrMsg('Missing username or pwd');
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Missing username or pwd', life: 3000 });
      } else if (err.response?.status === 401) {
        //setErrMsg('Unauthorized');
        //toast.current.show({ severity: 'error', summary: 'Error', detail: 'Unauthorized', life: 3000 });
        navigate('/login')
      } else {
        //setErrMsg('Login failed');
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Login failed', life: 3000 });
      }
    }
  }
  */

  useEffect(() => {

    localStorage.removeItem("accessToken");
    localStorage.removeItem("dailyActivityReportTypes");
    localStorage.removeItem("dailyActivityReportCodes");
    localStorage.removeItem("userProperties");
    localStorage.removeItem("userContextProperty");
    localStorage.removeItem("userData");
    localStorage.removeItem("roles");
    
 
    navigate('/login');
    

  }, [isSuccess]);


  return (
    <><Toast ref={toast} /></>

  )
}

export default Logout