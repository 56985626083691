import { v4 as uuidv4 } from "uuid";
import { Bounce } from "react-toastify";
import  secureLocalStorage  from  "react-secure-storage";

const getToken = () => localStorage.getItem("accessToken") ? localStorage.getItem("accessToken"): null;

const getAuthorizationHeader = () => `Bearer ${getToken()}`;

const ACCESS_TOKEN = JSON.parse(localStorage.getItem("accessToken"))

const DB_NAME = "starsprodb"
const DB_VERSION = "1"

const TOAST_CONFIG = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
    toastId: uuidv4()
  }

  const LOGO = `${process.env.PUBLIC_URL}/logo192.png`;

export {ACCESS_TOKEN,DB_NAME,DB_VERSION,TOAST_CONFIG,LOGO} ;