import axiosInstance from "./axios";
import * as moment from "moment";
import { useLocalState } from "../hooks/useLocalState";
import secureLocalStorage from "react-secure-storage";
import { DB_NAME, DB_VERSION } from "../utils/Constansts";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../utils/Constansts";

import { blobToArrayBuffer } from "../helpers/helpers";

//import { ACCESS_TOKEN } from "../utils/Constansts";

export async function fetchDispatchLogs(rows, page, property_id) {
  let url = `dispatchlog/list/${rows}/created_at/desc?page=${page}&context_property_id=${property_id}`;
  return axiosInstance.get(url);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    throw error;
    //return error;
  }
    */
}


export async function fetchDispatchLogTypes() {
  let url = "dispatchlogtype";
  return axiosInstance.get(url);
  /*let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    throw error;
  }*/
}

export function fetchDispatchLog(id) {
  let url = `dispatchlog/show?dispatch_log_id=${id}`;
  return axiosInstance.get(url);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    return error;
  }*/
}

export async function addDispatchLog(data) {
  let url = `dispatchlog/store`;
  return axiosInstance.post(url, data.json);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axiosInstance.post(url, data.json, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response;
  } catch (error) {

    throw error
  }*/
}

export function updateDispatchLog(json) {
  let url = `dispatchlog/update`;
  return axiosInstance.post(url, json);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  return axiosInstance.post(url, json, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });*/
}

export function deleteDispatchLog(id) {
  let url = `dispatchlog/delete`;
  return axiosInstance.delete(url, {data: { dispatch_log_id: id }});
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  return axiosInstance.delete(url, {
    data: { dispatch_log_id: id },
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  */
}


