import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import AuthProvider from "./context/auth";
import PositionProvider from "./context/position";
import PropertyProvider from "./context/PropertyProvider";
import NetworkProvider from "./context/network";
import { PrimeReactProvider } from "primereact/api";
import IndexedDB from "./utils/indexedDB";
import StoreOfflineData from "./components/StoreOfflineData";

import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from "@tanstack/react-query";

import swDev from "./swDev";
import "./config";

import "leaflet/dist/leaflet.css";

import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000, //60 seconds
    },
    onError: (error) => {
      console.log("nico");
      if (error.response.status === 403) {
        console.log("nico");
      }
    },
  },
  /*queryCache: new QueryCache({
    onError: (error) => { 
       console.log("Something went wrong");
       return
    },
  }),*/
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <IndexedDB />

      <Router>
        <NetworkProvider>
          <QueryClientProvider client={queryClient}>
            <PositionProvider>
              <AuthProvider>
                <StoreOfflineData />
                <PropertyProvider>
                  <App />
                  {/* <Routes> */}
                  {/* <Route path="/*" element={<App />} /> */}
                  {/* </Routes> */}
                </PropertyProvider>
              </AuthProvider>
            </PositionProvider>
          </QueryClientProvider>
        </NetworkProvider>
      </Router>
    </PrimeReactProvider>
  </React.StrictMode>
);

//Initialize service worker
swDev();
