import axiosInstance from "./axios";
import * as moment from "moment";
import { useLocalState } from "../hooks/useLocalState";
import secureLocalStorage from "react-secure-storage";
import { DB_NAME, DB_VERSION } from "../utils/Constansts";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../utils/Constansts";

import { blobToArrayBuffer } from "../helpers/helpers";

//import { ACCESS_TOKEN } from "../utils/Constansts";

export async function fetchVehicles(rows, page, property_id) {
  let url = `vehicle/list/${rows}/created_at/desc?page=${page}&context_property_id=${property_id}`;
  return axiosInstance.get(url);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    throw error;
    //return error;
  }*/
}

export function fetchVehicle(id) {
  let url = `vehicle/show?vehicle_id=${id}`;
  return axiosInstance.get(url);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    return error;
  }*/
}


export function fetchVehicleByPlate(license_plate,context_property_id) {
  let url = `vehicle/getVehicleByPlateOrHouseHold?license_plate=${license_plate}&context_property_id=${context_property_id}`;
  return axiosInstance.get(url);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    return error;
  }*/
}

export function fetchVehicleByPropertyId(context_property_id) {
  let url = `vehicle/getVehicleByPlateOrHouseHold?context_property_id=${context_property_id}`;
  return axiosInstance.get(url);
}

export function fetchVehicleByHouseHoldId(house_hold_id,context_property_id) {
  let url = `vehicle/getVehicleByPlateOrHouseHold?house_hold_id=${house_hold_id}&context_property_id=${context_property_id}`;
  return axiosInstance.get(url);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    return error;
  }*/
}

export function fetchVehicleByPropertyIdOrHouseHoldId(house_hold_id,context_property_id) {
  console.log("fetching veh")
  console.log(house_hold_id)
  let url = `vehicle/getVehicleByPlateOrHouseHold?context_property_id=${context_property_id}`;
  if(house_hold_id > 0){
    url = url + `&house_hold_id=${house_hold_id}`;
  }
  console.log("done fetching veh")
  return axiosInstance.get(url);
  
}

export async function addVehicle(data) {
  let url = `vehicle/store`;
  return axiosInstance.post(url, data.json);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axios.post(url, data.json, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response;
  } catch (error) {
  
    //return
    //throw new Error("I'm an error");
    throw error
  }*/
}

export function updateVehicle(json) {
  let url = `vehicle/update`;
  return axiosInstance.post(url, json);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  return axios.post(url, json, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });*/
}

export function deleteVehicle(id) {
  let url = `vehicle/delete`;
  return axiosInstance.delete(url, {data: { vehicle_id: id }});
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  return axios.delete(url, {
    data: { incident_report_id: id },
    headers: { Authorization: `Bearer ${accessToken}` },
  });*/
}


