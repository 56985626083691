import { useContext, useState, useEffect } from "react";
import { useUpdateEffect } from "primereact/hooks";
import { useUnmountEffect } from "primereact/hooks";
import { usePosition } from "../../context/position";
import VisibleContext from "../../context/VisibleContext";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";
import { Controller } from "react-hook-form";
import { z } from "zod";

import { useLocalState } from "../../hooks/useLocalState";
import secureLocalStorage from "react-secure-storage";

import { Sidebar } from "primereact/sidebar";

import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { ProgressSpinner } from "primereact/progressspinner";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";

import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";
import { v4 as uuidv4 } from "uuid";

import * as moment from "moment";
import { dateToFormat } from "../../helpers/helpers";

import { useQueryClient } from "@tanstack/react-query";

import {
  useVehicle,
  useInsertVehicle,
  useUpdateVehicle,
} from "../../hooks/vehicleQueries";

import { useHouseHoldByPropertyId } from "../../hooks/houseHoldQueries";

import { convertBase64, addDays } from "../../helpers/helpers";
import { useProperty } from "../../context/PropertyProvider";

const SideBar = (permissionsObject) => {
  const queryClient = useQueryClient();
  const [userData] = useLocalState("", "userData");
  const [userProperties, setUserProperties] = useLocalState(
    "",
    "userProperties"
  );
  const [userContextPropertyData, setUserContextPropertyData] = useLocalState(
    "",
    "userContextPropertyData"
  );
  const [permitsBy, setPermitsBy] = useState(999);
  const [daysInARow, setDaysInARow] = useState(999);

  const [property] = useProperty();

  const { VisibleFormContext, visibleLoadingModalContext } =
    useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleLoadingModal, setVisibleLoadingModal] =
    visibleLoadingModalContext;

  const [houseHolds, setHouseHolds] = useState([]);
  const [houseHoldId, setHouseHoldId] = useState(0);
  const [unknown_household, setUnknownHousehold] = useState(true);
  const [license_plate, setLicensePlate] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [color, setColor] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");

  const schema = z.object({
    house_hold_id: z.any().optional(),
    unknown_household: z.boolean(),
    license_plate: z.string(),
    make: z.string(),
    model: z.string(),
    color: z.string(),
    state: z.string(),
    address: z.string().nullish(),
  });

  const getPermitBy = () => {
    let filteredProperty = userProperties.filter(
      (property) =>
        property.property_id ==
        secureLocalStorage.getItem("userContextProperty")
    );
    setUserContextPropertyData(filteredProperty);

    let permits = filteredProperty[0].property_rules.filter(
      (rule) => rule.rule_id == 1
    )[0]?.value;
    setPermitsBy(permits);

    let days = filteredProperty[0].property_rules.filter(
      (rule) => rule.rule_id == 4
    )[0]?.value;
    setDaysInARow(days);
  };

  useEffect(() => {
    getPermitBy();
  }, [property]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
    setError,
    setValue,
    form,
  } = useForm({
    values: {
      house_hold_id: houseHoldId,
      unknown_household: unknown_household,
      license_plate: license_plate,
      make: make,
      model: model,
      color: color,
      state: state,
      address: address,
    },
    resolver: zodResolver(schema),
  });

  const {
    isPending: isPendingHouseHoldByPropertyId,
    isSuccess: isSuccessHouseHoldByPropertyId,
    isError: isErrorHouseHoldByPropertyId,
    data: fetchedHouseHoldByPropertyId,
    error: errorHouseHoldByPropertyId,
    refetch: refetchHouseHoldByPropertyId,
  } = useHouseHoldByPropertyId(property);

  useUpdateEffect(() => {
    if (isSuccessHouseHoldByPropertyId) {
      setHouseHolds(fetchedHouseHoldByPropertyId.data.households);
      if (fetchedHouseHoldByPropertyId.data.households.length == 1) {
        setHouseHoldId(
          getHousehold(
            fetchedHouseHoldByPropertyId.data.households[0].house_hold_id
          )
        );
      }
      //
    }
  }, [fetchedHouseHoldByPropertyId, isSuccessHouseHoldByPropertyId]);

  const {
    mutateAsync: mutateInsertVehicle,
    data: addedVehicle,
    error: errorInsertVehicle,
    isError: isErrorInsertVehicle,
    isPending: isPendingInsertVehicle,
    isSuccess: isSuccessInsertVehicle,
  } = useInsertVehicle();

  const {
    mutateAsync: mutateUpdateVehicle,
    data: updatedVehicle,
    error: errorUpdateVehicle,
    isError: isErrorUpdateVehicle,
    isPending: isPendingUpdateVehicle,
    isSuccess: isSuccessUpdateVehicle,
  } = useUpdateVehicle();

  useUpdateEffect(() => {
    if (isSuccessInsertVehicle || isSuccessUpdateVehicle) {
      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      queryClient.invalidateQueries({
        queryKey: ["vehicles_details"],
        refetchActive: true,
        refetchInactive: false,
      });

      if (visibleForm.itemId === null) {
        toast.success(
          `You have created Vehicle ID: ${addedVehicle?.data.data.vehicle_id}`,
          TOAST_CONFIG
        );
      } else {
        toast.success(
          `You have updaated Vehicle ID: ${updatedVehicle?.data.data.vehicle_id}`,
          TOAST_CONFIG
        );
      }
    }
  }, [addedVehicle, updatedVehicle]);

  const {
    isPending,
    isSuccess,
    isError,
    data: fetchedVehicle,
    error,
  } = useVehicle(visibleForm.itemId);

  /*useUpdateEffect(() => {
    if(vehicle){
      setHouseHold(getHousehold(vehicle.house_hold_id));

        setVehicle(getVehicle(vehicle.vehicle_id));
      
      
      //handleStartDate(new Date(vehicle.start_date));
      //setEndDate(new Date(vehicle.end_date));
    }
    
  },[vehicle]); */

  function getHousehold(house_hold_id) {
    try {
      return houseHolds.find(
        (element) => element.house_hold_id == house_hold_id
      );
    } catch (error) {
      console.log(error);
    }
  }

  useUpdateEffect(() => {
    if (isSuccess && fetchedVehicle) {
      setLicensePlate(fetchedVehicle?.data.data.license_plate);
      setMake(fetchedVehicle?.data.data.make);
      setModel(fetchedVehicle?.data.data.model);
      setColor(fetchedVehicle?.data.data.color);
      setState(fetchedVehicle?.data.data.state);
      setAddress(fetchedVehicle?.data.data.address);

      setVisibleLoadingModal({
        visible: false,
        itemId: null,
        title: null,
      });
    }
  }, [fetchedVehicle, isSuccess]);

  useUpdateEffect(() => {
    setHouseHoldId("");
    setLicensePlate("");
    setMake("");
    setModel("");
    setColor("");
    setState("");
    setAddress("");
  }, [visibleForm.visible]);

  const handleHide = () => {
    setVisibleForm({
      visible: false,
      itemId: null,
      title: null,
    });
  };

  useUpdateEffect(() => {
    if (isPending) {
      setVisibleLoadingModal({
        visible: true,
        itemId: null,
        title: null,
      });
    } else {
      setVisibleLoadingModal({
        visible: false,
        itemId: null,
        title: null,
      });
    }
  }, [isPending]);

  useUpdateEffect(() => {
    if (isErrorInsertVehicle) {
      console.log(isErrorInsertVehicle);
      console.log(errorInsertVehicle);

      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      toast.success(`You have created a Vehicle on this device.`, TOAST_CONFIG);
    }
  }, [isErrorInsertVehicle]);

  const onSubmit = async (data) => {
    try {
      //await new Promise((resolve) => setTimeout(resolve, 1000));
      let json = {
        unknown_household: data.unknown_household,
        user_id: data.house_hold_id.user_id,
        license_plate: data.license_plate,
        make: data.make,
        model: data.model,
        color: data.color,
        state: data.state,
        address: data.address,
        property_id: property,
      };

      let reponse;

      if (visibleForm.itemId === null) {
        reponse = await mutateInsertVehicle({
          json: json,
          persistOnStorage: true,
        });
      } else {
        json["vehicle_id"] = visibleForm.itemId;
        reponse = await mutateUpdateVehicle(json);
      }

      //console.log(reponse);
    } catch (error) {
      console.log(error);
      //setError("root", {message: error,});
    }
  };

  return (
    <>
      <Sidebar
        visible={visibleForm.visible}
        position="right"
        onHide={() => handleHide()}
        className="w-full md:w-20rem lg:w-30rem"
      >
        {visibleLoadingModal.visible ? (
          <div className="flex flex-wrap justify-content-between gap-2 mb-1">
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ProgressSpinner
                aria-label="Loading"
                style={{ width: "50px", height: "100vm" }}
                strokeWidth="2"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />

              <p>Loading</p>
            </div>
          </div>
        ) : (
          <>
            <h2>{visibleForm.title}</h2>

            <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
              <>
                <div className="field">
                  <span className="p-float-label">
                    <Controller
                      control={control}
                      name="unknown_household"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <InputSwitch
                          onBlur={onBlur} // notify when input is touched
                          onChange={(e) => {
                            setUnknownHousehold(e.value);
                            if (!e.value) {
                              setHouseHoldId(0);
                            }
                          }} // send value to hook form
                          checked={value}
                          inputRef={ref}
                        />
                      )}
                    />

                    <label htmlFor="unknown_household" className="ml-2">
                      &nbsp; &nbsp; &nbsp; &nbsp; Unknown Household
                    </label>
                  </span>
                </div>
                {!unknown_household ? (
                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="house_hold_id"
                        rules={{
                          validate: ({ value } = {}) =>
                            value !== undefined ||
                            "You must select a household",
                          valueAsNumber: true,
                        }}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            filter
                            placeholder="Select a household"
                            optionLabel="label"
                            options={houseHolds}
                            {...field}
                            onChange={(e) => {
                              setHouseHoldId(e.value);
                            }}
                            //optionValue={type}
                            value={field.value}
                          />
                        )}
                      />
                      <label htmlFor="house_hold_id">Household</label>
                    </span>
                    <small className="block pt-1">
                      {errors.house_hold_id && (
                        <div className="text-red-500">
                          {errors.house_hold_id.message}
                        </div>
                      )}
                    </small>
                  </div>
                ) : null}

                <div className="field">
                  <span className="p-float-label">
                    <Controller
                      name="license_plate"
                      control={control}
                      rules={{ required: "License plate is required." }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          value={field.value ?? ""}
                          maxLength={50}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />

                    <label htmlFor="license_plate">License Plate</label>
                  </span>
                  <small className="block pt-1">
                    {errors.license_plate && (
                      <div className="text-red-500">
                        {errors.license_plate.message}
                      </div>
                    )}
                  </small>
                </div>

                <div className="field">
                  <span className="p-float-label">
                    <Controller
                      name="make"
                      control={control}
                      rules={{ required: "Make is required." }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          value={field.value ?? ""}
                          maxLength={50}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />

                    <label htmlFor="make">Make</label>
                  </span>
                  <small className="block pt-1">
                    {errors.make && (
                      <div className="text-red-500">{errors.make.message}</div>
                    )}
                  </small>
                </div>

                <div className="field">
                  <span className="p-float-label">
                    <Controller
                      name="model"
                      control={control}
                      rules={{ required: "Model is required." }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          value={field.value ?? ""}
                          maxLength={50}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />

                    <label htmlFor="model">Model</label>
                  </span>
                  <small className="block pt-1">
                    {errors.model && (
                      <div className="text-red-500">{errors.model.message}</div>
                    )}
                  </small>
                </div>

                <div className="field">
                  <span className="p-float-label">
                    <Controller
                      name="color"
                      control={control}
                      rules={{ required: "Color is required." }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          value={field.value ?? ""}
                          maxLength={50}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />

                    <label htmlFor="color">Color</label>
                  </span>
                  <small className="block pt-1">
                    {errors.color && (
                      <div className="text-red-500">{errors.color.message}</div>
                    )}
                  </small>
                </div>

                <div className="field">
                  <span className="p-float-label">
                    <Controller
                      name="address"
                      control={control}
                      rules={{ required: "Address is required." }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          value={field.value ?? ""}
                          maxLength={50}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />

                    <label htmlFor="address">Address</label>
                  </span>
                  <small className="block pt-1">
                    {errors.address && (
                      <div className="text-red-500">
                        {errors.address.message}
                      </div>
                    )}
                  </small>
                </div>

                <div className="field">
                  <span className="p-float-label">
                    <Controller
                      name="state"
                      control={control}
                      rules={{ required: "State is required." }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          value={field.value ?? ""}
                          maxLength={50}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />

                    <label htmlFor="state">State</label>
                  </span>
                  <small className="block pt-1">
                    {errors.state && (
                      <div className="text-red-500">{errors.state.message}</div>
                    )}
                  </small>
                </div>

                <Button
                  disabled={isSubmitting}
                  type="submit"
                  label={isSubmitting ? "Loading..." : "Submit"}
                  className="mt-2"
                />
              </>

              {errors.root && (
                <div className="text-red-500">{errors.root.message}</div>
              )}
            </form>
          </>
        )}
      </Sidebar>
    </>
  );
};

export default SideBar;
