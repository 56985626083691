import { useContext, useState, useEffect } from "react";
import { useUpdateEffect } from "primereact/hooks";
import { useUnmountEffect } from "primereact/hooks";
import { usePosition } from "../../context/position";
import VisibleContext from "../../context/VisibleContext";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";
import { Controller } from "react-hook-form";
import { z } from "zod";

import { useLocalState } from "../../hooks/useLocalState";
import secureLocalStorage from "react-secure-storage";

import { Sidebar } from "primereact/sidebar";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";

import { InputSwitch } from 'primereact/inputswitch';
        
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { ProgressSpinner } from "primereact/progressspinner";
import { Image } from "primereact/image";
import { Tag } from "primereact/tag";

import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";
import { v4 as uuidv4 } from "uuid";

import { utcToLocale } from "../../helpers/helpers";

import {
  useIncidentReport,
  useInsertIncidentReport,
  useUpdateIncidentReport,
} from "../../hooks/incidentReportQueries";

import { convertBase64 } from "../../helpers/helpers";

const SideBar = () => {
  const [userData] = useLocalState("", "userData");
  let userContextProperty = secureLocalStorage.getItem("userContextProperty");
  const [incidentReportTypes] = useLocalState("", "incidentReportTypes");
  const [dailyActivityReportCodes] = useLocalState("","dailyActivityReportCodes");

  const positionContext = usePosition();

  const { VisibleFormContext, visibleLoadingModalContext } = useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleLoadingModal, setVisibleLoadingModal] = visibleLoadingModalContext;

  const [type, setType] = useState(incidentReportTypes[0]);
  const [code, setCode] = useState(dailyActivityReportCodes[2]);
  const [incident_date, setIncidentReportDate] = useState(new Date());
  const [details, setDetails] = useState("");
  const [send_notification, setSendNotification] = useState(false);
  const [imagesUploaded, setImagesUploaded] = useState([]);
  const [images, setImages] = useState([]);
  const [imagesUrls, setImagesUrls] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);

  const schema = z.object({
    incidentReportTypeId: z.object({
      type: z.string(),
      incident_report_type_id: z.number(),
    }),
    /*dailyActivityReportCodeId: z.object({
      type: z.string(),
      daily_activity_report_code_id: z.number(),
    }),*/
    incident_date: z.date(),
    details: z.string().min(1).max(500),
    send_notification: z.boolean(),
    images: z.any(),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
    setError,
    setValue,
    form,
  } = useForm({
    //shouldUnregister: true,

    /*defaultValues: {
      //dailyActivityReportTypeId: type,
      dailyActivityReportCodeId: dailyActivityReportCodes[2],

      details: "d",
      incident_date: new Date(),
      send_notification: false,
      include_gps_coordinates: false,
    },*/
    values: {
      incidentReportTypeId: type,
      dailyActivityReportCodeId: code,
      details: details,
      incident_date: incident_date,
      send_notification: send_notification,
    },

    resolver: zodResolver(schema),
  });

  useUpdateEffect(() => {
    setType(incidentReportTypes[0]);
    setCode(dailyActivityReportCodes[0]);
    setDetails("");
    setIncidentReportDate(new Date());

    setImages([]);
    setImagesUploaded([]);
    setImagesUrls([]);
    setDeletedImages([]);

    /*positionContext?.getPosition((position) => {
      if (position.error) {
        console.log(position.error.message);
      }
      //toast(position.latitude,TOAST_CONFIG)
      //gpsCoordinates = `${position.latitude},${position.longitude}`
      setLat(position.latitude);
      setLng(position.longitude);
      //console.log(position.latitude +","+position.longitude)
    });*/

    //userContextProperty = useLocalState("", "userContextProperty");
    /*if (visibleForm.visible) {
      positionContext?.getPosition((position) => {
        if (position.error) {
          console.log(position.error.message);
        }
        //toast(position.latitude,TOAST_CONFIG)
        //gpsCoordinates = `${position.latitude},${position.longitude}`
        setLat(position.latitude);
        setLng(position.longitude);
        //console.log(position.latitude +","+position.longitude)
      });
    }*/
  }, [visibleForm.visible]);

  const minDate = () => {
    if (userData[0].isAdmin || userData[0].isSupervisor) {
      return new Date("2022-01-01");
    } else {
      return new Date();
    }
  };

  const handleHide = () => {
    setVisibleForm({
      visible: false,
      itemId: null,
      title: null,
    });
  };

  const {
    mutateAsync: mutateInsertIncidentReport,
    data: addedIncidentReport,
    error: errorInsertIncidentReport,
    isError: isErrorInsertIncidentReport,
    isPending: isPendingInsertIncidentReport,
    isSuccess: isSuccessInsertIncidentReport,
  } = useInsertIncidentReport();

  const {
    mutateAsync: mutateUpdateIncidentReport,
    data: updatedIncidentReport,
    error: errorUpdateIncidentReport,
    isError: isErrorUpdateIncidentReport,
    isPending: isPendingUpdateIncidentReport,
    isSuccess: isSuccessUpdateIncidentReport,
  } = useUpdateIncidentReport();

  useUpdateEffect(() => {
    if (isSuccessInsertIncidentReport || isSuccessUpdateIncidentReport) {
      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      if (visibleForm.itemId === null) {
        toast.success(
          `You have created DAR ID: ${addedIncidentReport?.data.data.incident_report_id}`,
          TOAST_CONFIG
        );
      } else {
        toast.success(
          `You have updaated DAR ID: ${updatedIncidentReport?.data.data.incident_report_id}`,
          TOAST_CONFIG
        );
      }
    }
  }, [addedIncidentReport, updatedIncidentReport]);

  function getCode(code_id) {
    try {
      return dailyActivityReportCodes.find(
        (element) => element.daily_activity_report_code_id == code_id
      );
    } catch (error) {
      console.log(error);
    }
  }

  function getType(type_id) {
    return incidentReportTypes.find(
      (element) => element.incident_report_type_id == type_id
    );
  }

  const {
    isPending,
    isSuccess,
    isError,
    data: fetchedIncidentReport,
    error,
  } = useIncidentReport(visibleForm.itemId);

  useUpdateEffect(() => {
    if (isSuccess) {
      setCode(
        getCode(fetchedIncidentReport?.data.data.daily_activity_report_code_id)
      );
      setType(
        getType(fetchedIncidentReport?.data.data.incident_report_type_id)
      );
      setDetails(fetchedIncidentReport?.data.data.details);
      setIncidentReportDate(
        utcToLocale(fetchedIncidentReport?.data.data.incident_date)
      );
      setSendNotification(
        fetchedIncidentReport?.data.data.send_notification == 1 ? true : false
      );

      //setValue('images',fetchedIncidentReport?.data.data.thumb_images)
      setImagesUrls(fetchedIncidentReport?.data.data.thumb_images);
      setImagesUploaded(fetchedIncidentReport?.data.data.images);

      setVisibleLoadingModal({
        visible: false,
        itemId: null,
        title: null,
      });
    }
  }, [fetchedIncidentReport, isError]);

  useUpdateEffect(() => {
    if (isPending) {
      setVisibleLoadingModal({
        visible: true,
        itemId: null,
        title: null,
      });
    } else {
      setVisibleLoadingModal({
        visible: false,
        itemId: null,
        title: null,
      });
    }
  }, [isPending]);

  useUpdateEffect(() => {
    if (isErrorInsertIncidentReport) {
      console.log(isErrorInsertIncidentReport);
      console.log(errorInsertIncidentReport);

      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      toast.success(`You have created a DAR on this device.`, TOAST_CONFIG);
    }
  }, [isErrorInsertIncidentReport]);

  const onSubmit = async (data) => { 
    try {
      let json = {
        incident_report_type_id: data.incidentReportTypeId.incident_report_type_id,
        //daily_activity_report_code_id: data.dailyActivityReportCodeId.daily_activity_report_code_id,
        daily_activity_report_code_id: 3,
        incident_date: new Date(data.incident_date).toISOString(),
        details: data.details,
        //gps_coordinates: lat + "," + lng,
        gps_coordinates: positionContext.position.latitude + "," + positionContext.position.longitude,
        property_id: userContextProperty,
        //property_id: 1,
        //send_notification: data.send_notification,
        send_notification: true,
        images: images,
        images_urls: imagesUrls,
        images_del: deletedImages,
      };

      let reponse;

      if (visibleForm.itemId === null) {
        reponse = await mutateInsertIncidentReport({
          json: json,
          persistOnStorage: true,
        });
      } else {
        json["incident_report_id"] = visibleForm.itemId;
        reponse = await mutateUpdateIncidentReport(json);
      }

      //console.log(reponse);
    } catch (error) {
      console.log(error);
      //setError("root", {message: error,});
    }
  };

  const onTemplateSelect = (e) => {
    if (e.files.length < 13) {
      uploadFiles(e.files);
    }
  };

  const uploadFiles = async (uploadedFiles) => {
    //let uploadedFiles = e.files;
    const files = [];
    const fileUrls = imagesUrls;
    //console.log(imagesUrls)

    Object.keys(uploadedFiles).forEach(async (key) => {
      if (uploadedFiles[key].objectURL.includes("blob")) {
        const base64 = await convertBase64(uploadedFiles[key]);
        files.push(base64);
      }
      console.log(uploadedFiles[key]);
      fileUrls.push(uploadedFiles[key]);
    });
    setImages(files);
    setImagesUrls(fileUrls);
  };

  const removeFiles = async (e, file) => {
    e.preventDefault();

    //console.log(file);

    if (file.includes("blob")) {
      const fileUrls = imagesUrls;
      for (let number = 0; number < imagesUrls.length; number++) {
        var index = imagesUrls[number].objectURL.indexOf(file.objectURL);
        if (index !== -1) {
          fileUrls.splice(number, 1);
        }
      }

      setImagesUrls(fileUrls);
    } else {
      const files = deletedImages;
      files.push(file);
      setDeletedImages(files);

      const fileUrls = imagesUploaded;
      for (let number = 0; number < fileUrls.length; number++) {
        //console.log(file);
        setImagesUploaded((oldState) =>
          oldState.filter((item) => item !== file)
        );

        /*var index = fileUrls[number].indexOf(file);
        console.log(index);
        if (index !== -1) {
          fileUrls.splice(number, 1);
          console.log(fileUrls);
        }
        */
      }
      //setImagesUploaded(fileUrls)
    }

    //console.log(imagesUploaded);

    //callback();

    //const files = convertFiles(imagesUrls)
    //setImages(files);
  };

  return (
    <>
      <Sidebar
        visible={visibleForm.visible}
        position="right"
        onHide={() => handleHide()}
        className="w-full md:w-20rem lg:w-30rem"
      >
        {visibleLoadingModal.visible ? (
          <div className="flex flex-wrap justify-content-between gap-2 mb-1">
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ProgressSpinner
                aria-label="Loading"
                style={{ width: "50px", height: "100vm" }}
                strokeWidth="2"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />

              <p>Loading</p>
            </div>
          </div>
        ) : (
          <>
            <h2>{visibleForm.title}</h2>
            <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
              <div className="field">

                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="incidentReportTypeId"
                        rules={{
                          validate: ({ value } = {}) =>
                            value !== undefined ||
                            "You must select a Incident type",
                          valueAsNumber: true,
                        }}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            filter
                            placeholder="Select a type"
                            optionLabel="type"
                            options={incidentReportTypes}
                            {...field}
                            //optionValue={type}
                            //value = {field.value}
                          />
                        )}
                      />
                      <label htmlFor="type">Type</label>
                    </span>
                    <small className="block pt-1">{errors.incidentReportTypeId && (
                      <div className="text-red-500">{errors.incidentReportTypeId.message}</div>
                    )}</small>
                 
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Calendar
                    id="incident_date"
                    name="incident_date"
                    onChange={(e) => {
                      setIncidentReportDate(e.value);
                    }}
                    value={incident_date}
                    dateFormat="mm/dd/yy"
                    showButtonBar
                    showTime
                    hourFormat="24"
                    maxDate={new Date()}
                    minDate={minDate()}
                    //hideOnDateTimeSelect
                  />

                  <label htmlFor="incident_date">Incident Date</label>
                </span>
                <small className="block pt-1">{errors.incident_date && (
                  <div className="text-red-500">{errors.incident_date.message}</div>
                )}</small>
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="details"
                    control={control}
                    rules={{ required: "Details is required." }}
                    render={({ field, fieldState }) => (
                      <InputTextarea
                        id={field.name}
                        {...field}
                        value={field.value ?? ""}
                        autoFocus
                        maxLength={500}
                        rows={5} cols={30}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />

                  <label htmlFor="details">Details <small>(max 500 characters)</small></label>
                </span>
                <small className="block pt-1">{errors.details && (
                  <div className="text-red-500">{errors.details.message}</div>
                )}</small>
              </div>

              <div className="field">
                <Controller
                  control={control}
                  name={"images"}
                  rules={{ required: "Image is required" }}
                  render={({ field: { value, onChange, ...field } }) => {
                    return (
                      <FileUpload
                        multiple
                        mode="advanced"
                        {...field}
                        value={value?.fileName}
                        onSelect={onTemplateSelect}
                        type="file"
                        id="images"
                        chooseLabel="Select Image/s"
                        uploadOptions={{ className: "hidden" }}
                      />
                    );
                  }}
                />

                <small className="block pt-1">{errors.images && (
                  <div className="text-red-500">{errors.images.message}</div>
                )}</small>
              </div>
              {imagesUploaded ? (
                imagesUploaded.length > 0 ? (
                <div className="field">
                  <ul className="m-0 p-0 list-none border-1 surface-border border-round p-2 flex flex-column gap-1 w-full ">
                    
                       {imagesUploaded.map((url, i) => (
                          <li
                            key={uuidv4()}
                            className={`hover:surface-hover border-round border-1 border-transparent transition-all transition-duration-200 flex align-items-center justify-content-between border-primary'}`}
                            //onContextMenu={(event) => onRightClick(event, user)}
                          >
                            <div className="flex align-items-center gap-1">
                              <Image
                                src={url}
                                zoomSrc={imagesUploaded[i]}
                                key={i}
                                width="80"
                                height="80"
                                preview
                              />
                              <Button
                                icon="pi pi-trash"
                                onClick={(e) => removeFiles(e, url)}
                                severity="danger"
                                size="small"
                                style={{ backgroundColor: "#d32f2f80" }}
                              />
                            </div>
                          </li>
                        ))
                      }
                  </ul>
                </div>
                ): null
              ) : null}
              

              <Button
                disabled={isSubmitting}
                type="submit"
                label={isSubmitting ? "Loading..." : "Submit"}
                className="mt-2"
              />
              {errors.root && (
                <div className="text-red-500">{errors.root.message}</div>
              )}
            </form>
          </>
        )}
      </Sidebar>
    </>
  );
};

export default SideBar;
