import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useLocalState } from "../hooks/useLocalState";
import  secureLocalStorage  from  "react-secure-storage";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();

    let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"))
    let userData = JSON.parse(secureLocalStorage.getItem("userData")) 

    let roles
    let permissions = []

    if(userData!== null ){
        permissions = userData[0]?.user.roles[0].permissions
    }
    
    //console.log(permissions.find(permission => allowedRoles?.includes(permission.name)))

    //return null
    
    

    //return permissions
    //return null
    return (
        userData!= null 
            ? permissions.find(permission => allowedRoles?.includes(permission.name))
            //permissions.find(({ name }) => name === 'dar list')
                ? <Outlet />
                
                    : <Navigate to="unauthorized" state={{ from: location }} replace />

                : <Navigate to="/login" state={{ from: location }} replace />  
    );


    /*return (
        permissions !== null 
            ? permissions.find(permission => allowedRoles?.includes(permission.name))
            //permissions.find(({ name }) => name === 'dar list')
                ? <Outlet />
                : auth?.user
                    ? <Navigate to="unauthorized" state={{ from: location }} replace />
                    : <Navigate to="/login" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />  
    );*/
}

export default RequireAuth;