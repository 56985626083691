import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchVehicleMaintenances,
  fetchVehicleMaintenance,
  addVehicleMaintenance,
  updateVehicleMaintenance,
  deleteVehicleMaintenance,
  fetchVehicleMaintenanceTypes
} from "../api/vehicleMaintenanceApi";
import { useLocalState } from "./useLocalState";

let accessToken = JSON.parse(localStorage.getItem("accessToken")) || null


//import { ACCESS_TOKEN } from "../utils/Constansts";

//console.log(ACCESS_TOKEN)

export function useVehicleMaintenancTypes(permissionCreate) {
  return useQuery({
    queryKey: ["vehiclemaintenancetypes",permissionCreate],
    queryFn: () => fetchVehicleMaintenanceTypes() ,
    retry: false,   
    enabled: permissionCreate,
    onSuccess: () => {
      console.log("ALL GOOD")
    },

  });
}

export function useVehicleMaintenances(rows, page, propertyId) {
  return useQuery({
    queryKey: ["vehiclemaintenances", { rows, page, propertyId }],
    queryFn: () => fetchVehicleMaintenances(rows, page, propertyId),
    retry: false,
    //staleTime: 10,
    refetchInterval:400000,
    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }

  });
}


export function useVehicleMaintenance(itemId) {
  return useQuery({
    queryKey: ["vehiclemaintenance", { itemId }],
    queryFn: () => itemId != null ? fetchVehicleMaintenance(itemId) : null,
    retry: false,

    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }
  });
}

export const useInsertVehicleMaintenance = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addVehicleMaintenance,
    networkMode: "offlineFirst",
    retry: false,
    throwOnError: false,
    onSuccess: () =>{
      queryClient.invalidateQueries({
        queryKey: ["vehiclemaintenances"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: (error) => {
      console.log(error)
      
    },
  })
}

export function useUpdateVehicleMaintenance() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateVehicleMaintenance,
    //networkMode: offlineFirst,
    onSuccess: () =>{
      queryClient.invalidateQueries({
        queryKey: ["vehiclemaintenances"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: () => {
      console.log("error occured")
      
    },
  })
}

export function useDeleteVehicleMaintenance(){
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteVehicleMaintenance,

    onSuccess(){
      queryClient.invalidateQueries({
        queryKey: ["vehiclemaintenances"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    }
  })

} 
