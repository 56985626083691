import { Button } from 'primereact/button'

const PositionError = () => {
  const handleReload = () => {
    window.location.reload()
  }
  return (
<>
      <h1>Star pro access</h1>
      <p>
        You must enabled geolocation permissions to continue
      </p>
      <Button onClick={handleReload}>Try Again</Button>
      </>
  )
}
/*
const PositionErrorWrapper = styled.div`
  width: 600px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`

const AppTitle = styled.h1`
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 900;
`

const ErrorTitle = styled.h2`
  font-size: 1.125rem;
  text-align: center;
  font-weight: 500;
`

const ReloadButton = styled(PrimaryButton)`
  width: 40%;
`
*/

export default PositionError