import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchIncidentReports,
  fetchIncidentReport,
  fetchIncidentReportTypes,
  addIncidentReport,
  updateIncidentReport,
  deleteIncidentReport,
} from "../api/incidentReportApi";
import { useLocalState } from "./useLocalState";

let accessToken = JSON.parse(localStorage.getItem("accessToken")) || null


//import { ACCESS_TOKEN } from "../utils/Constansts";

//console.log(ACCESS_TOKEN)


export function useIncidentReportTypes(incidentCreate) {
  return useQuery({
    queryKey: ["incidentReportTypes",incidentCreate],
    queryFn: () => fetchIncidentReportTypes() ,
    retry: false,   
    enabled: incidentCreate,
    onSuccess: () => {
      console.log("ALL GOOD")
    },

  });
}

export function useIncidentReports(rows, page, propertyId) {
  return useQuery({
    queryKey: ["incidentReports", { rows, page, propertyId }],
    queryFn: () => fetchIncidentReports(rows, page, propertyId),
    retry: false,
    //staleTime: 10,
    refetchInterval:400000,
    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }

  });
}


export function useIncidentReport(darId) {
  return useQuery({
    queryKey: ["incidentReport", { darId }],
    queryFn: () => darId != null ? fetchIncidentReport(darId) : null,
    retry: false,

    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }
  });
}

export const useInsertIncidentReport = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addIncidentReport,
    networkMode: "offlineFirst",
    retry: false,
    throwOnError: false,
    onSuccess: () =>{
      queryClient.invalidateQueries({
        queryKey: ["incidentReports"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: (error) => {
      console.log(error)
      
    },
  })
}

export function useUpdateIncidentReport() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateIncidentReport,
    //networkMode: offlineFirst,
    onSuccess: () =>{
      queryClient.invalidateQueries({
        queryKey: ["incidentReports"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: () => {
      console.log("error occured")
      
    },
  })
}

export function useDeleteIncidentReport(){
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteIncidentReport,

    onSuccess(){
      queryClient.invalidateQueries({
        queryKey: ["incidentReports"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    }
  })

} 
