import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from 'leaflet'

const Map = ({ position  }) => {

  const legalIcon = new Icon ({
    iconUrl : `${process.env.PUBLIC_URL}/assets/location.png`,
    iconSize : [35,35], // size of the icon
   // iconAnchor : [22,94], // point of the icon which will correspond to marker's location
    //popupAnchor : [-3, -76] // point from which the popup should open relative to the iconAnchor

  })

  return (<>

    {position && position != 'null,null' ? (

      <MapContainer className="lg:h-40rem sm:h-18rem md:h-30rem w-auto" center={position} zoom={15} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} icon={legalIcon}>
          <Popup>
            <b>DAR</b><br />
            <em>type</em><br />
          </Popup>
        </Marker>
      </MapContainer>
    ):null}
</>
  )
  
};

//

//export default Map;
export default React.memo(Map);