import axiosInstance from "./axios";
import * as moment from "moment";
import { useLocalState } from "../hooks/useLocalState";
import secureLocalStorage from "react-secure-storage";
import { DB_NAME, DB_VERSION } from "../utils/Constansts";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../utils/Constansts";

import { blobToArrayBuffer } from "../helpers/helpers";

//import { ACCESS_TOKEN } from "../utils/Constansts";

export async function fetchVehicleMaintenances(rows, page, property_id, sort_field, sort_order) {
  let url = `vehiclemaintenance/list/${rows}/created_at/desc?page=${page}&context_property_id=${property_id}`;
  return axiosInstance.get(url);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    throw error;
    //return error;
  }*/
}

export async function fetchVehicleMaintenanceTypes() {
  let url = "vehiclemaintenancetype";
  return axiosInstance.get(url);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    throw error;
  }*/
}

export function fetchVehicleMaintenance(id) {
  let url = `vehiclemaintenance/show?vehicle_maintenance_id=${id}`;
  return axiosInstance.get(url);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    return error;
  }*/
}

export function fetchVehicleMaintenanceByPropertyId(id) {
  let url = `vehiclemaintenance?context_property_id=${id}`;
  return axiosInstance.get(url);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    return error;
  }*/
}

export async function addVehicleMaintenance(data) {
  let url = `vehiclemaintenance/store`;
  return axiosInstance.post(url, data.json);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axios.post(url, data.json, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response;
  } catch (error) {

    throw error
  }*/
}

export function updateVehicleMaintenance(json) {
  let url = `vehiclemaintenance/update`;
  return axiosInstance.post(url, json);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  return axios.post(url, json, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });*/
}

export function deleteVehicleMaintenance(id) {
  let url = `vehiclemaintenance/delete`;
  return axiosInstance.delete(url, {data: { vehicle_maintenance_id: id }});
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  return axios.delete(url, {
    data: { vehicle_maintenance_id: id },
    headers: { Authorization: `Bearer ${accessToken}` },
  });*/
}


