import * as React from 'react'
import { toast, Bounce } from "react-toastify";
import { v4 as uuidv4 } from "uuid";



const NetworkContext = React.createContext()
NetworkContext.displayName = 'NetworkContext'


const NetworkProvider = ({children}) => {
  const [connStatus, setConnStatus] = React.useState(navigator.onLine)

  const handler = React.useCallback(e => {
    const status = {
      isOnline: e.type === 'online',
    }
//console.log(status.isOnline)
    if(status.isOnline){

        toast.success(
            'You internet connection is restored.',
            {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              toastId: uuidv4()
            }
          );
    }else{

        toast.error(
            'You internet connection is lost.',
            {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              toastId: uuidv4()
            }
          );
          let networkTimeout = null;
          if (networkTimeout) {
            clearTimeout(networkTimeout);
          }
          //imitate delay of a backend call
          networkTimeout = setTimeout(() => {
      
            toast.warn('Your reports will be save on this device until network is restored.',
              {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                toastId: uuidv4()
              }
            );
      
          }, Math.random() * 1000);
    }
   
    setConnStatus(status.isOnline)
  }, [])

  React.useEffect(() => {
    window.addEventListener('online', handler)
    window.addEventListener('offline', handler)

    return () => {
      window.removeEventListener('online', handler)
      window.removeEventListener('offline', handler)
    }
  }, [handler])

  return (
    <NetworkContext.Provider
      value={{
        connStatus,
      }}
    >
      {children}
    </NetworkContext.Provider>
  )
}

const useConnStatus = () => {
  const context = React.useContext(NetworkContext)
  if (context === undefined) {
    throw new Error('useStore must be use withing a NetworkProvider')
  }
  return context
}

export {useConnStatus}
export default NetworkProvider