import { useContext, useState, useEffect } from "react";
import { useUpdateEffect } from "primereact/hooks";
import { useUnmountEffect } from "primereact/hooks";
import { usePosition } from "../../context/position";
import VisibleContext from "../../context/VisibleContext";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";
import { Controller } from "react-hook-form";
import { z } from "zod";

import { useLocalState } from "../../hooks/useLocalState";

import { Sidebar } from "primereact/sidebar";

import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { ProgressSpinner } from "primereact/progressspinner";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";

import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";
import { v4 as uuidv4 } from "uuid";

import * as moment from "moment";
import { dateToFormat } from "../../helpers/helpers";

import { useQueryClient } from "@tanstack/react-query";

import {
  useSafelisting,
  useInsertSafelisting,
  useUpdateSafelisting,
} from "../../hooks/safelistingQueries";

import { useVehicleByPropertyId } from "../../hooks/vehicleQueries";

import { useHouseHoldByPropertyId } from "../../hooks/houseHoldQueries";

import { convertBase64, addDays } from "../../helpers/helpers";
import { useProperty } from "../../context/PropertyProvider";

const SideBar = (permissionsObject) => {
  const queryClient = useQueryClient();
  const [userData] = useLocalState("", "userData");
  const [userProperties, setUserProperties] = useLocalState(
    "",
    "userProperties"
  );
  const [userContextPropertyData, setUserContextPropertyData] = useLocalState(
    "",
    "userContextPropertyData"
  );
  const [permitsBy, setPermitsBy] = useState(999);
  const [daysInARow, setDaysInARow] = useState(999);

  const [property] = useProperty();

  const [houseHolds, setHouseHolds] = useState([]);
  const [houseHold, setHouseHold] = useState("");
  const [houseHoldId, setHouseHoldId] = useState(0);

  const [vehicles, setVehicles] = useState([]);
  const [vehicle, setVehicle] = useState("");
  const [vehicleId, setVehicleId] = useState(0);

  const { VisibleFormContext, visibleLoadingModalContext } =
    useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleLoadingModal, setVisibleLoadingModal] =
    visibleLoadingModalContext;

  const [start_date, setStartDate] = useState(new Date());
  const [min_start_date, setMinStartDate] = useState(new Date());
  const [max_start_date, setMaxStartDate] = useState(addDays(new Date(), 365));
  const [end_date_disabled, setEndDateDisabled] = useState(true);
  const [end_date, setEndDate] = useState(new Date());
  const [maxEndDate, setMaxEndDate] = useState(new Date());

  const [details, setDetails] = useState("");

  const [admin_override, setAdminOverride] = useState(false);

  const [safelisting, setSafelisting] = useState([]);

  const schema = z.object({
    house_hold_id: z.any().optional(),
    vehicle_id: z.any(),
    start_date: z.date(),
    end_date: z.date(),
    details: z.string().nullish(),
    admin_override: z.boolean(),
  });

  const getPermitBy = () => {
    let filteredProperty = userProperties.filter(
      (p) => p.property_id == property
    );
    setUserContextPropertyData(filteredProperty);

    let permits = filteredProperty[0].property_rules.filter(
      (rule) => rule.rule_id == 1
    )[0]?.value;
    setPermitsBy(permits);

    let days = filteredProperty[0].property_rules.filter(
      (rule) => rule.rule_id == 4
    )[0]?.value;
    setDaysInARow(days);
  };

  useEffect(() => {
    getPermitBy();
  }, [property]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
    setError,
    setValue,
    form,
  } = useForm({
    values: {
      house_hold_id: houseHoldId,
      vehicle_id: vehicleId,
      details: details,
      start_date: start_date,
      end_date: end_date,
      admin_override: admin_override,
    },
    resolver: zodResolver(schema),
  });

  const {
    isPending: isPendingHouseHoldByPropertyId,
    isSuccess: isSuccessHouseHoldByPropertyId,
    isError: isErrorHouseHoldByPropertyId,
    data: fetchedHouseHoldByPropertyId,
    error: errorHouseHoldByPropertyId,
    refetch: refetchHouseHoldByPropertyId,
  } = useHouseHoldByPropertyId(property);

  useUpdateEffect(() => {
    if (isSuccessHouseHoldByPropertyId) {
      setHouseHolds(fetchedHouseHoldByPropertyId.data.households);
      if (fetchedHouseHoldByPropertyId.data.households.length == 1) {
        setHouseHoldId(
          getHousehold(
            fetchedHouseHoldByPropertyId.data.households[0].house_hold_id
          )
        );
      }
      //
    }
  }, [fetchedHouseHoldByPropertyId, isSuccessHouseHoldByPropertyId]);

  const {
    isPending: isPendingVehicleByPropertyId,
    isSuccess: isSuccessVehicleByPropertyId,
    isError: isErrorVehicleByPropertyId,
    data: fetchedVehicleByPropertyId,
    error: errorVehicleByPropertyId,
    refetch: refectVehicleByPropertyId,
  } = useVehicleByPropertyId(property);

  useUpdateEffect(() => {
    if (isSuccessVehicleByPropertyId) {
      setVehicles(fetchedVehicleByPropertyId.data.vehicles);
    }
  }, [fetchedVehicleByPropertyId]);

  const {
    mutateAsync: mutateInsertSafelisting,
    data: addedSafelisting,
    error: errorInsertSafelisting,
    isError: isErrorInsertSafelisting,
    isPending: isPendingInsertSafelisting,
    isSuccess: isSuccessInsertSafelisting,
  } = useInsertSafelisting();

  const {
    mutateAsync: mutateUpdateSafelisting,
    data: updatedSafelisting,
    error: errorUpdateSafelisting,
    isError: isErrorUpdateSafelisting,
    isPending: isPendingUpdateSafelisting,
    isSuccess: isSuccessUpdateSafelisting,
  } = useUpdateSafelisting();

  useUpdateEffect(() => {
    if (isSuccessInsertSafelisting || isSuccessUpdateSafelisting) {
      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      if (visibleForm.itemId === null) {
        toast.success(
          `You have created Safelisting ID: ${addedSafelisting?.data.data.safelisting_id}`,
          TOAST_CONFIG
        );
      } else {
        toast.success(
          `You have updaated Safelisting ID: ${updatedSafelisting?.data.data.safelisting_id}`,
          TOAST_CONFIG
        );
      }
    }
  }, [addedSafelisting, updatedSafelisting]);

  function getHousehold(house_hold_id) {
    try {
      return houseHolds.find(
        (element) => element.house_hold_id == house_hold_id
      );
    } catch (error) {
      console.log(error);
    }
  }

  function getVehicle(vehicle_id) {
    try {
      return vehicles.find((element) => element.vehicle_id == vehicle_id);
    } catch (error) {
      console.log(error);
    }
  }

  const {
    isPending,
    isSuccess,
    isError,
    data: fetchedSafelisting,
    error,
  } = useSafelisting(visibleForm.itemId);

  /*useUpdateEffect(() => {
    if(safelisting){
      setHouseHold(getHousehold(safelisting.house_hold_id));

        setVehicle(getVehicle(safelisting.vehicle_id));
      
      
      //handleStartDate(new Date(safelisting.start_date));
      //setEndDate(new Date(safelisting.end_date));
    }
    
  },[safelisting]); */

  useUpdateEffect(() => {
    if (isSuccess && fetchedSafelisting) {
      setSafelisting(fetchedSafelisting?.data.data);
      //console.log(getHousehold(fetchedSafelisting?.data.data.house_hold_id))
      //setHouseHold(getHousehold(fetchedSafelisting?.data.data.house_hold_id));
      setStartDate(dateToFormat(fetchedSafelisting?.data.data.start_date));
      let today = new Date();
      if (today > new Date(fetchedSafelisting?.data.data.start_date)) {
        setMinStartDate(new Date(fetchedSafelisting?.data.data.start_date));
        setMaxStartDate(new Date(fetchedSafelisting?.data.data.start_date));
        setMaxEndDate(
          addDays(fetchedSafelisting?.data.data.start_date, daysInARow)
        );
      } else {
        setMinStartDate(today);
        setMaxStartDate(addDays(today, 365));
      }
      setEndDate(dateToFormat(fetchedSafelisting?.data.data.end_date));
      setEndDateDisabled(false);

      setDetails(fetchedSafelisting?.data.data.details);

      setHouseHoldId(getHousehold(fetchedSafelisting?.data.data.house_hold_id));
      setVehicleId(getVehicle(fetchedSafelisting?.data.data.vehicle_id));

      setVisibleLoadingModal({
        visible: false,
        itemId: null,
        title: null,
      });
    }
  }, [fetchedSafelisting, isSuccess]);

  useUpdateEffect(() => {
    setDetails("");
    setStartDate(new Date());
    setEndDate(new Date());
    setEndDateDisabled(true);
    setVehicle("");
    //setVehicles([])
    setHouseHold("");
    setAdminOverride(false);
  }, [visibleForm.visible]);

  const handleHide = () => {
    setVisibleForm({
      visible: false,
      itemId: null,
      title: null,
    });
  };

  useUpdateEffect(() => {
    if (isPending) {
      setVisibleLoadingModal({
        visible: true,
        itemId: null,
        title: null,
      });
    } else {
      setVisibleLoadingModal({
        visible: false,
        itemId: null,
        title: null,
      });
    }
  }, [isPending]);

  useUpdateEffect(() => {
    if (isErrorInsertSafelisting) {
      console.log(isErrorInsertSafelisting);
      console.log(errorInsertSafelisting);

      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      toast.success(
        `You have created a Safelisting on this device.`,
        TOAST_CONFIG
      );
    }
  }, [isErrorInsertSafelisting]);

  const onSubmit = async (data) => {
    try {
      //await new Promise((resolve) => setTimeout(resolve, 1000));
      let json = {
        admin_override: data.admin_override,
        start_date: data.start_date,
        end_date: data.end_date,
        details: data.details,
        //house_hold_id: data.houseHoldId.house_hold_id,
        vehicle_id: data.vehicle_id.vehicle_id,
        property_id: property,
      };

      if (permitsBy == 1) {
        json["house_hold_id"] = data.house_hold_id.house_hold_id;
      }

      let reponse;

      if (visibleForm.itemId === null) {
        reponse = await mutateInsertSafelisting({
          json: json,
          persistOnStorage: true,
        });
      } else {
        json["safelisting_id"] = visibleForm.itemId;
        reponse = await mutateUpdateSafelisting(json);
      }

      //console.log(reponse);
    } catch (error) {
      console.log(error);
      //setError("root", {message: error,});
    }
  };

  return (
    <>
      <Sidebar
        visible={visibleForm.visible}
        position="right"
        onHide={() => handleHide()}
        className="w-full md:w-20rem lg:w-30rem"
      >
        {visibleLoadingModal.visible ? (
          <div className="flex flex-wrap justify-content-between gap-2 mb-1">
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ProgressSpinner
                aria-label="Loading"
                style={{ width: "50px", height: "100vm" }}
                strokeWidth="2"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />

              <p>Loading</p>
            </div>
          </div>
        ) : (
          <>
            <h2>{visibleForm.title}</h2>

            <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
              {permitsBy == 1 ? (
                <div className="field">
                  <span className="p-float-label">
                    <Controller
                      control={control}
                      name="house_hold_id"
                      rules={{
                        validate: ({ value } = {}) =>
                          value !== undefined || "You must select a household",
                        valueAsNumber: true,
                      }}
                      render={({ field }) => (
                        <Dropdown
                          id={field.name}
                          filter
                          placeholder="Select a household"
                          optionLabel="label"
                          options={houseHolds}
                          {...field}
                          onChange={(e) => {
                            setHouseHoldId(e.value);
                          }}
                          //optionValue={type}
                          value={field.value}
                        />
                      )}
                    />
                    <label htmlFor="house_hold_id">Household</label>
                  </span>
                  <small className="block pt-1">
                    {errors.house_hold_id && (
                      <div className="text-red-500">
                        {errors.house_hold_id.message}
                      </div>
                    )}
                  </small>
                </div>
              ) : null}

              <>
                <>
                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="vehicle_id"
                        rules={{
                          validate: ({ value } = {}) =>
                            value !== undefined || "You must select a vehicle",
                          valueAsNumber: true,
                        }}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            filter
                            placeholder="Select a vehicle"
                            optionLabel="label"
                            options={vehicles}
                            {...field}
                            onChange={(e) => {
                              setVehicleId(e.value);
                            }}
                            //optionValue={type}
                            value={field.value}
                          />
                        )}
                      />
                      <label htmlFor="vehicle_id">Vehicle</label>
                    </span>
                    <small className="block pt-1">
                      {errors.vehicle_id && (
                        <div className="text-red-500">
                          {errors.vehicle_id.message}
                        </div>
                      )}
                    </small>
                  </div>
                </>
                <div className="field">
                  <span className="p-float-label">
                    <Calendar
                      id="start_date"
                      name="start_date"
                      onChange={(e) => {
                        setStartDate(e.value);
                        var date = new Date(e.value);
                        date.setDate(date.getDate() + daysInARow);
                        setMaxEndDate(date);
                        setEndDate(e.value);
                        setEndDateDisabled(false);
                      }}
                      value={start_date}
                      dateFormat="mm/dd/yy"
                      //dateFormat="yyyy-MM-DD"
                      showButtonBar
                      hourFormat="24"
                      minDate={min_start_date}
                      maxDate={max_start_date}
                      //hideOnDateTimeSelect
                    />

                    <label htmlFor="start_date">Start Date</label>
                  </span>
                  <small className="block pt-1">
                    {errors.start_date && (
                      <div className="text-red-500">
                        {errors.start_date.message}
                      </div>
                    )}
                  </small>
                </div>

                <div className="field">
                  <span className="p-float-label">
                    <Calendar
                      id="end_date"
                      name="end_date"
                      onChange={(e) => {
                        setEndDate(e.value);
                      }}
                      value={end_date}
                      dateFormat="mm/dd/yy"
                      showButtonBar
                      disabled={end_date_disabled}
                      hourFormat="24"
                      minDate={start_date}
                      maxDate={maxEndDate}
                      //hideOnDateTimeSelect
                    />

                    <label htmlFor="end_date">
                      End Date <small>(max {daysInARow} days in a row)</small>{" "}
                    </label>
                  </span>
                  <small className="block pt-1">
                    {errors.end_date && (
                      <div className="text-red-500">
                        {errors.end_date.message}
                      </div>
                    )}
                  </small>
                </div>

                <div className="field">
                  <span className="p-float-label">
                    <Controller
                      name="details"
                      control={control}
                      rules={{ required: "Notes is required." }}
                      render={({ field, fieldState }) => (
                        <InputTextarea
                          id={field.name}
                          {...field}
                          value={field.value ?? ""}
                          maxLength={500}
                          rows={5}
                          cols={30}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />

                    <label htmlFor="details">
                      Notes <small>(max 500 characters)</small>
                    </label>
                  </span>
                  <small className="block pt-1">
                    {errors.details && (
                      <div className="text-red-500">
                        {errors.details.message}
                      </div>
                    )}
                  </small>
                </div>

                <div className="field">
                  <span className="p-float-label">
                    <Controller
                      control={control}
                      name="admin_override"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <InputSwitch
                          onBlur={onBlur} // notify when input is touched
                          onChange={onChange} // send value to hook form
                          checked={value}
                          inputRef={ref}
                        />
                      )}
                    />

                    <label htmlFor="admin_override" className="ml-2">
                      &nbsp; &nbsp; &nbsp; &nbsp; Override
                    </label>
                  </span>
                </div>

                <Button
                  disabled={isSubmitting}
                  type="submit"
                  label={isSubmitting ? "Loading..." : "Submit"}
                  className="mt-2"
                />
              </>

              {errors.root && (
                <div className="text-red-500">{errors.root.message}</div>
              )}
            </form>
          </>
        )}
      </Sidebar>
    </>
  );
};

export default SideBar;
