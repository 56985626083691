import axiosInstance from "./axios";
import * as moment from "moment";
import { useLocalState } from "../hooks/useLocalState";
import secureLocalStorage from "react-secure-storage";
import { DB_NAME, DB_VERSION } from "../utils/Constansts";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../utils/Constansts";

import { blobToArrayBuffer } from "../helpers/helpers";

//import { ACCESS_TOKEN } from "../utils/Constansts";

export async function fetchPoolAccessLogs(rows, page, property_id) {
  let url = `poolaccesslog/list/${rows}/created_at/desc?page=${page}&context_property_id=${property_id}`;
  return axiosInstance.get(url);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    throw error;
    //return error;
  }*/
}

export function fetchPoolAccessLog(id) {
  let url = `poolaccesslog/show?pool_access_log_id=${id}`;
  return axiosInstance.get(url);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    return error;
  }*/
}

export async function addPoolAccessLog(data) {
  let url = `poolaccesslog/store`;
  return axiosInstance.post(url, data.json);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axios.post(url, data.json, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response;
  } catch (error) {

    throw error
  }*/
}

export function updatePoolAccessLog(json) {
  let url = `poolaccesslog/update`;
  return axiosInstance.post(url, json);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  return axios.post(url, json, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });*/
}

export function deletePoolAccessLog(id) {
  let url = `poolaccesslog/delete`;
  return axiosInstance.delete(url, {data: { pool_access_log_id: id }});
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  return axios.delete(url, {
    data: { pool_access_log_id: id },
    headers: { Authorization: `Bearer ${accessToken}` },
  });*/
}


