import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchProperties,
  fetchProperty,
  addProperty,
  updateProperty,
  deleteProperty,
  fetchPropertyTypes
} from "../api/propertyApi";


export function usePropertyTypes(permissionCreate) {
  return useQuery({
    queryKey: ["propertytypes",permissionCreate],
    queryFn: () => fetchPropertyTypes() ,
    retry: false,   
    enabled: permissionCreate,
    onSuccess: () => {
      console.log("ALL GOOD")
    },

  });
}

export function useProperties(rows, page, propertyId) {
  return useQuery({
    queryKey: ["properties", { rows, page, propertyId }],
    queryFn: () => fetchProperties(rows, page, propertyId),
    retry: false,
    //staleTime: 10,
    refetchInterval:400000,
    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }

  });
}


export function useProperty(itemId) {
  return useQuery({
    queryKey: ["vehiclemaintenance", { itemId }],
    queryFn: () => itemId != null ? fetchProperty(itemId) : null,
    retry: false,

    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }
  });
}

export const useInsertProperty = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addProperty,
    networkMode: "offlineFirst",
    retry: false,
    throwOnError: false,
    onSuccess: () =>{
      queryClient.invalidateQueries({
        queryKey: ["properties"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: (error) => {
      console.log(error)
      
    },
  })
}

export function useUpdateProperty() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateProperty,
    //networkMode: offlineFirst,
    onSuccess: () =>{
      queryClient.invalidateQueries({
        queryKey: ["properties"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: () => {
      console.log("error occured")
      
    },
  })
}

export function useDeleteProperty(){
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteProperty,

    onSuccess(){
      queryClient.invalidateQueries({
        queryKey: ["properties"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    }
  })

} 
