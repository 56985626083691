import React from "react";
import { useReactToPrint } from "react-to-print";
import { useState, useEffect, useContext, useRef } from "react";

import { Dialog } from "primereact/dialog";
import { Galleria } from "primereact/galleria";
import { ScrollTop } from "primereact/scrolltop";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { OverlayPanel } from "primereact/overlaypanel";

import Map from "../map";

import VisibleContext from "../../context/VisibleContext";
import { useVehicle } from "../../hooks/vehicleQueries";

import * as moment from "moment";
import { utcToLocale } from "../../helpers/helpers";

export const ShowModal = () => {
  const printRef = useRef();
  const galleria = useRef(null);
  const mapOverlay = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const { visibleModalContext, allowedOfflineActionContext } = useContext(VisibleContext);

  const [visibleModal, setVisibleModal] = visibleModalContext;

  const {
    isPending: isPendingVehicle,
    isSuccess: isSuccessVehicle,
    isError: isErrorVehicle,
    data: fetchedVehicle,
    errorVehicle,
  } = useVehicle(visibleModal.itemId);

  const onHideModal = () => {
    setVisibleModal({
      visible: false,
      itemId: null,
    });
  };

  const itemTemplate = (item) => {
    return <img src={item} style={{ width: "100%", display: "block" }} />;
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item}
        style={{ maxWidth: "40%", width: "90px", display: "block" }}
      />
    );
  };

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  const durationDaysBodyTemplate = (vehicle) => {
    if(vehicle){
      const firstDate = new Date(vehicle.start_date);
      const secondDate = new Date(vehicle.end_date);

      const firstDateInMs = firstDate.getTime();
      const secondDateInMs = secondDate.getTime();

      const differenceBtwDates = secondDateInMs - firstDateInMs;

      const aDayInMs = 24 * 60 * 60 * 1000;

      const daysDiff = Math.round(differenceBtwDates / aDayInMs);
      //console.log(daysDiff)

      return `${daysDiff} days`;
    }
  };

  const statusBodyTemplate = (vehicle) => {
    if(vehicle){
      const firstDate = new Date(vehicle.end_date);
      const secondDate = new Date();
  
      if(secondDate >= firstDate ) {
        return (
          <Tag
            severity="danger"
            className="w-full text-base"
            text="white"
            style={{padding: "0.4rem",backgroundColor: "#d32f2fd1", textAlign: "center"}}
          >
            Expired
          </Tag>)
      }else{
        return (
          <Tag
            severity="success"
            className="w-full text-base"
            text="white"
            style={{padding: "0.4rem",backgroundColor: "#689f38d1", textAlign: "center"}}
          >
            Active
          </Tag>)
      }
    }
    

  };

  return (
    <Dialog
      //className="sm:w-30rem md:w-40rem lg:w-50rem"
      
      className="w-11 sm:w-8 md:w-7 lg:w-7 xl:w-6 h-auto"
      showHeader={false}
      contentStyle={{ borderTopLeftRadius: "4px", borderTopRightRadius: "4px"  }}
      dismissableMask
      closeOnEscape
      visible={visibleModal.visible}
      onHide={() => onHideModal()}
      //breakpoints={{ "960px": "75vw", "640px": "100vw" }}
      //style={{ position: "fixed", height: "100%", width: "100%", left: "0px", top: "0px", display: "flex", justifyContent: "flex-end", alignItems: "center", zIndex: "1101" }}
    >
      {isPendingVehicle ? (
        <div className="flex flex-wrap h-18rem justify-content-between gap-2 mb-1">
          <div
            className="h-6rem"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <ProgressSpinner
              aria-label="Loading"
              style={{ width: "50px", height: "100vm" }}
              strokeWidth="2"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />

            <p>Loading</p>
          </div>
        </div>
      ) : (
        <>
          <div ref={printRef} className="p-2 ">
            <div className="grid">
              <div className="col">
              <span className="align-items-center inline-flex ">
                <h2>
                  Vehicle #
                  {fetchedVehicle?.data.data.vehicle_id}
                </h2>
                
              </span>
            </div>
              
              <div className="col">      
                  <div className="flex align-items-end justify-content-end">
                      <span className="mt-3">
                        <Button
                            icon="pi pi-times"
                            rounded
                            link
                            text
                            onClick={() => onHideModal()}
                          />
                      </span>
                    </div>
              </div>
            </div>
            
            <div className="">
              <b>{statusBodyTemplate(fetchedVehicle?.data.data)}</b>
              
              <p>
                Start Date: <b>{moment(utcToLocale(fetchedVehicle?.data.data.start_date)).format("yyyy-MM-DD")}</b> | End Date: <b>{moment(utcToLocale(fetchedVehicle?.data.data.end_date)).format("yyyy-MM-DD")}</b>
              </p>

              <p>
                Duration: <b>{durationDaysBodyTemplate(fetchedVehicle?.data.data)}</b>
              </p>
              {fetchedVehicle?.data.data.household_name ?
              <p className="text-base">
                Household: <b>{fetchedVehicle?.data.data.household_name}</b>
              </p>
              :null
              }

              <p className="text-base">
                License plate: <b>{fetchedVehicle?.data.data.license_plate}</b>
              </p>

              <p className="text-base">
                Notes: <b>{fetchedVehicle?.data.data.details}</b>
              </p>

              <p>
                Created Date:{" "}
                <b>
                  {moment(
                    utcToLocale(fetchedVehicle?.data.data.created_at)
                  ).format("yyyy-MM-DD HH:mm")}
                </b>
              </p>
              <p>
                Created by:{" "}
                <b>{fetchedVehicle?.data.data.officer_name}</b>
              </p>

              <ScrollTop
                target="parent"
                threshold={100}
                className="w-2rem h-2rem border-round bg-primary"
                icon="pi pi-arrow-up text-base"
              />
            </div>
          </div>
          <Button onClick={handlePrint} label="Print" className="ml-2 hide-on-print" />
        </>
      )}
    </Dialog>
  );
};
