import "./App.css";
import { useEffect, useState } from "react";

import RequireAuth from "./components/RequireAuth";
import { Routes, Route, Navigate } from "react-router-dom";

import Layout from "./layouts/layout";
import Dar from "./components/dars/dar";
import IncidentReports from "./components/incident_reports/IncidentReports";
import Citations from "./components/citations/Citations";
import VehicleProfile from "./components/vehicle_profile/VehicleProfile";
import PoolAccessLogs from "./components/pool_access_logs/PoolAccessLogs";
import DispatchLogs from "./components/dispatch_logs/DispatchLogs";
import Safelistings from "./components/safelistings/Safelistings";
import Vehicles from "./components/vehicles/Vehicles";
import VehicleMaintenance from "./components/vehicle_maintenance/VehicleMaintenance";
import Properties from "./components/properties/properties";

import Users from "./components/users/Users";
import Login from "./components/login/Login";
import Forgot from "./components/forgot/forgot";
import Reset from "./components/reset/reset";
import Logout from "./components/Logout";
import Missing from "./components/Missing";
import Unauthorized from "./components/Unauthorized";

import { ToastContainer, toast } from "react-toastify";

import axios from "./api/axios";
import { UserProvider, useUser } from "./context/UserContext";
import VisibleFormContext, { variable } from "./context/VisibleContext";

import Loading from "./components/Loading";

import { useLocalState } from "./hooks/useLocalState";
import Dashboards from "./components/dashboard/Dashboards";

/*const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      if (error?.request?.status === 401) {
        toast(`Please authenticate ${error?.request?.status}`);
      } else {
        toast(`resto ${error?.request?.status}`);
      }
    },
  }),
});
*/

export default () => (
  <UserProvider>
    <App></App>
  </UserProvider>
);

function App() {
  const [visibleForm2] = useLocalState(
    {
      visible: false,
      itemId: null,
      title: null,
    },
    "visibleForm"
  );

  useEffect(() => {
    axios.defaults.baseURL = window.origin + "/API/public/api/v1/";
  }, []);

  const [visibleForm, setVisibleForm] = useState(variable.visibleForm);
  const [visibleModal, setVisibleModal] = useState(variable.visibleModal);
  const [visibleLoadingModal, setVisibleLoadingModal] = useState(
    variable.visibleLoadingModal
  );
  const [allowedOfflineAction, setAllowedOfflineAction] = useState(
    variable.allowedOfflineAction
  );
  const [visibleCitation, setVisibleCitation] = useState(
    variable.visibleCitation
  );

  return (
    <VisibleFormContext.Provider
      value={{
        VisibleFormContext: [visibleForm, setVisibleForm],
        visibleLoadingModalContext: [
          visibleLoadingModal,
          setVisibleLoadingModal,
        ],
        visibleModalContext: [visibleModal, setVisibleModal],
        allowedOfflineActionContext: [
          allowedOfflineAction,
          setAllowedOfflineAction,
        ],
        visibleCitationContext: [visibleCitation, setVisibleCitation],
      }}
    >
      <div
        className="App"
        style={{
          //margin: "0 1rem 0 1rem",
          paddingTop: "env(safe-area-inset-top)",
        }}
      >
        <ToastContainer />

        <Routes>
          {/*PUBLIC ROUTES*/}
          <Route path="login" element={<Login />} />

          <Route path="forgot" element={<Forgot />} />

          <Route path="reset" element={<Reset />} />

          <Route path="/" element={<Navigate to="login" />} />
          {/*PRIVATE ROUTES*/}

          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  "dar list",
                  "incidentreport list",
                  "citation list",
                  "user list",
                  "safelisting list",
                  "vehicle list",
                  "poolaccesslog list",
                ]}
              />
            }
          >
            <Route path="loading" element={<Loading />} />

            <Route path="/" element={<Layout />}>
              <Route path="unauthorized" element={<Unauthorized />} />

              <Route element={<RequireAuth allowedRoles={["dar list"]} />}>
                <Route path="dashboard" element={<Dashboards />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={["dar list"]} />}>
                <Route path="dar" element={<Dar />} />
              </Route>

              <Route
                element={<RequireAuth allowedRoles={["incidentreport list"]} />}
              >
                <Route path="incidents" element={<IncidentReports />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={["citation list"]} />}>
                <Route path="citations" element={<Citations />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={["citation list"]} />}>
                <Route path="vehicleprofile" element={<VehicleProfile />} />
              </Route>
              <Route
                element={<RequireAuth allowedRoles={["poolaccesslog list"]} />}
              >
                <Route path="poolaccesslogs" element={<PoolAccessLogs />} />
              </Route>

              <Route
                element={<RequireAuth allowedRoles={["dispatchlog list"]} />}
              >
                <Route path="dispatchlogs" element={<DispatchLogs />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={["user list"]} />}>
                <Route path="users" element={<Users />} />
              </Route>

              <Route
                element={<RequireAuth allowedRoles={["safelisting list"]} />}
              >
                <Route path="safelistings" element={<Safelistings />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={["vehicle list"]} />}>
                <Route path="vehicles" element={<Vehicles />} />
              </Route>

              <Route
                element={
                  <RequireAuth allowedRoles={["vehiclemaintenance list"]} />
                }
              >
                <Route
                  path="vehiclemaintenance"
                  element={<VehicleMaintenance />}
                />
              </Route>

              <Route element={<RequireAuth allowedRoles={["property list"]} />}>
                <Route path="properties" element={<Properties />} />
              </Route>

              <Route path="logout" element={<Logout />} />

              {/*CATCH ALL*/}
              <Route path="*" element={<Missing />} />
            </Route>
          </Route>
        </Routes>
      </div>
    </VisibleFormContext.Provider>
  );
}
