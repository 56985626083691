import React from "react";

export const variable = {
  visibleForm: {
    visible: false,
    itemId: null,
    title: null,
  },
  visibleLoadingModal: {
    visible: false,
    itemId: null,
    title: null,
  },
  visibleModal: {
    visible: false,
    itemId: null,
  },
  allowedOfflineAction: {
    allowed: true,
  },
  visibleCitation: {
    visible: false,
    itemId: null,
  },
};

const VisibleContext = React.createContext(variable);

export default VisibleContext;
