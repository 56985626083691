import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchLogin,fetchLogout, reset, forgot } from "../api/loginApi";


export function useLogin1(user_name, password, gps_coordinates) {
    return useQuery({
      queryKey: ["login"],
      queryFn: () => fetchLogin(user_name, password, gps_coordinates),
      retry: 0,
  
      onSuccess: () => {
        console.log("ALL GOOD")
      },
  
    
    });
  }


  export const useLogin = () => {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: fetchLogin,
      retry: false,
      onSuccess: (data) => {
        console.log("ALL GOOD")
        //console.log(`useLogin ${JSON.stringify(data.data.access_token)}`)
        //localStorage.setItem("accessToken",JSON.stringify(data.data.access_token));
      },
      onError:(error) => {
        console.log(error)
      }
    })
  }

  export const useReset = () => {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: reset,
      retry: false,
      onSuccess: (data) => {
        console.log("ALL GOOD")
        //console.log(`useLogin ${JSON.stringify(data.data.access_token)}`)
        //localStorage.setItem("accessToken",JSON.stringify(data.data.access_token));
      },
      onError:(error) => {
        console.log(error)
      }
    })
  }
  
  export const useForgot = () => {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: forgot,
      retry: false,
      onSuccess: (data) => {
        console.log("ALL GOOD")
        //console.log(`useLogin ${JSON.stringify(data.data.access_token)}`)
        //localStorage.setItem("accessToken",JSON.stringify(data.data.access_token));
      },
      onError:(error) => {
        console.log(error)
      }
    })
  }

  export const useLogout = () => {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: fetchLogout,
      retry: false,
      onSuccess: (data) => {
        console.log("ALL GOOD")
        queryClient.invalidateQueries({
          queryKey: ["login"],
          //exact: true,
          refetchActive: true,
          refetchInactive: false,
        });
        //console.log(`useLogin ${JSON.stringify(data.data.access_token)}`)
        //localStorage.setItem("accessToken",JSON.stringify(data.data.access_token));
      },
    })
  }

  