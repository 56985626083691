import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";

import { useLocalState } from "../../hooks/useLocalState";
import { useAuth } from "../../context/auth";
import { useConnStatus } from "../../context/network";

import { useUpdateEffect } from "primereact/hooks";

import { ShowModal } from "./ShowModal";

import {
  useDispatchLogs,
  useDispatchLog,
  useDeleteDispatchLog,
} from "../../hooks/dispatchLogQueries";

import VisibleContext from "../../context/VisibleContext";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import { toast } from "react-toastify";

import { TOAST_CONFIG } from "../../utils/Constansts";

import * as moment from "moment";
import { utcToLocale } from "../../helpers/helpers";
import secureLocalStorage from "react-secure-storage";
import { useProperty } from "../../context/PropertyProvider";

const Datatable = (permissionsObject) => {
  const navigate = useNavigate();
  const { removeStore, clearUser } = useAuth();
  const { connStatus } = useConnStatus();

  const [userData] = useLocalState("", "userData");

  const {
    VisibleFormContext,
    visibleLoadingModalContext,
    visibleModalContext,
    allowedOfflineActionContext,
  } = useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleLoadingModal, setVisibleLoadingModal] =
    visibleLoadingModalContext;
  const [visibleModal, setVisibleModal] = visibleModalContext;
  const [allowedOfflineAction, setAllowedOfflineAction] =
    allowedOfflineActionContext;

  //const [visibleForm,setVisibleForm] = useLocalState(null,"visibleForm");
  //console.log(visibleForm)

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [dars, setDispatchLogs] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);

  const [property] = useProperty();

  let idToDelete = 0;

  let [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "dispatch_log_id",
    sortOrder: 0,
    filters: {
      daily_activity_report_id_format: { value: "", matchMode: "contains" },
      details: { details: "", matchMode: "contains" },
    },
  });

  const {
    isPending,
    isSuccess,
    isError: isErrorfetchedDispatchLogs,
    data: fetchedDispatchLogs,
    error,
    refetch,
  } = useDispatchLogs(lazyState.rows, lazyState.page, property);

  const {
    mutate: mutateDeleteDispatchLog,
    data: deletedDispatchLog,
    error: errorDeleteDispatchLog,
    isPending: isPendingDeleteDispatchLog,
    isSuccess: isSuccessDeleteDispatchLog,
    p,
  } = useDeleteDispatchLog();

  const {
    isPending: isPendingDispatchLog,
    isSuccess: isSuccessDispatchLog,
    isError: isErrorDispatchLog,
    data: fetchedDispatchLog,
    errorDispatchLog,
  } = useDispatchLog(visibleModal.itemId);

  useUpdateEffect(() => {
    if (isSuccess) {
      setDispatchLogs(fetchedDispatchLogs?.data.dispatch_log.data);
      setTotalRecords(fetchedDispatchLogs?.data.dispatch_log.total);
      setFrom(fetchedDispatchLogs?.data.dispatch_log.from);
      setTo(fetchedDispatchLogs?.data.dispatch_log.to);

      setLoading(false);
    }
    if (isPending) {
      setLoading(true);
    }

    if (isErrorfetchedDispatchLogs) {
      if (error?.response.status == 401) {
        toast.error(`You need to authenticate`, TOAST_CONFIG);

        removeStore();
        clearUser();

        navigate("/login");
      }
    }
  }, [fetchedDispatchLogs, isErrorfetchedDispatchLogs]);

  useUpdateEffect(() => {
    if (isSuccessDeleteDispatchLog) {
      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      toast.success(`You have delete Dispatch Log`, TOAST_CONFIG);
    }
    if (isPendingDeleteDispatchLog) {
      setLoading(true);
      //console.log(isPendingDeleteDispatchLog);
    }
    if (errorDeleteDispatchLog) {
      console.log(errorDeleteDispatchLog);
    }
  }, [deletedDispatchLog]);

  useEffect(() => {
    setlazyState((old) => ({
      ...old,
      first: 0,
      page: 1,
      rows: 10,
    }));
  }, [property]);

  const dispatchDateBodyTemplate = (dispatchLog) => {
    return moment(utcToLocale(dispatchLog.dispatch_log_date)).format(
      "yyyy-MM-DD HH:mm"
    );
  };

  const createdDateBodyTemplate = (dispatchLog) => {
    return moment(utcToLocale(dispatchLog.created_at)).format(
      "yyyy-MM-DD HH:mm"
    );
  };

  const darDateDiffBodyTemplate = (dispatchLog) => {
    const firstDate = new Date(dispatchLog.dispatch_log_date);
    const secondDate = new Date(dispatchLog.created_at);

    const firstDateInMs = firstDate.getTime();
    const secondDateInMs = secondDate.getTime();

    const differenceBtwDates = secondDateInMs - firstDateInMs;

    const aDayInMs = 24 * 60 * 60 * 1000;

    const daysDiff = Math.round(differenceBtwDates / aDayInMs);
    //console.log(daysDiff)

    return `${daysDiff} days`;
  };

  const detailsTemplate = (dispatchLog) => {
    let details = dispatchLog.details;

    if (dispatchLog.details.length > 100) {
      details = dispatchLog.details.substr(0, 100) + "...";
    }
    return details;
  };

  const typeBodyTemplate = (dispatchLog) => {
    if (dispatchLog.code_type === "Red") {
      return (
        <Tag
          severity="danger"
          className=" w-10rem md:w-12rem lg:w-14rem "
          text="white"
          style={{
            padding: "0.4rem",
            backgroundColor: "#d32f2fd1",
            textAlign: "center",
          }}
        >
          {dispatchLog.dispatch_log_type}
        </Tag>
      );
    } else if (dispatchLog.code_type === "Yellow") {
      return (
        <Tag
          severity="warning"
          className=" w-10rem md:w-12rem lg:w-14rem "
          text="black"
          style={{
            padding: "0.4rem",
            backgroundColor: "#fbc02dd1",
            textAlign: "center",
          }}
        >
          {dispatchLog.dispatch_log_type}
        </Tag>
      );
    } else
      return (
        <Tag
          severity="success"
          className=" w-10rem md:w-12rem lg:w-14rem "
          text="white"
          style={{
            padding: "0.4rem",
            backgroundColor: "#689f38d1",
            textAlign: "center",
          }}
        >
          {dispatchLog.dispatch_log_type}
        </Tag>
      );
  };

  const mapBodyTemplate = (dispatchLog) => {
    return (
      <>
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${dispatchLog.gps_coordinates}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button icon="pi pi-map-marker" rounded link text />
        </a>
      </>
    );
  };

  const showBodyTemplate = (dispatchLog) => {
    return (
      <Button
        severity="secondary"
        icon="pi pi-eye"
        size="small"
        onClick={() =>
          connStatus
            ? openModal(dispatchLog.dispatch_log_id)
            : noConectionAlert()
        }
        style={{ backgroundColor: "#64748b" }}
      />
    );
  };

  const editBodyTemplate = (dispatchLog) => {
    if (permissionsObject.permissionsObject.update) {
      return (
        <Button
          severity="info"
          icon="pi pi-pencil"
          size="small"
          onClick={() =>
            connStatus
              ? openSidebarForm(
                  "Update Dispatch Log",
                  "Update",
                  dispatchLog.dispatch_log_id
                )
              : noConectionAlert()
          }
        />
      );
    }
  };

  const deleteBodyTemplate = (dispatchLog) => {
    if (permissionsObject.permissionsObject.delete) {
      //console.log(`allowedOfflineAction 'false' ${connStatus.isOnline}`)
      return (
        <>
          <Button
            icon="pi pi-trash"
            onClick={() =>
              connStatus
                ? confirmDelete(dispatchLog.dispatch_log_id)
                : noConectionAlert()
            }
            severity="danger"
            size="small"
          />
        </>
      );
    }
  };

  const accept = () => {
    mutateDeleteDispatchLog(idToDelete);
  };

  const noConectionAlert = () => {
    toast.warn(
      "Action not allowed while you don't have internet connection",
      TOAST_CONFIG
    );
  };

  const reject = () => {
    toast.warn("You have rejected", TOAST_CONFIG);
  };

  const confirmDelete = (id) => {
    idToDelete = id;

    confirmDialog({
      key: 1,
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const onSelectionChange = (event) => {
    console.log(6666);
    const value = event.value;

    setSelectedItem(value);
    //setSelectAll(value.length === totalRecords);
  };

  const isSelectable = (data) => true;

  const isRowSelectable = (event) =>
    event.data ? isSelectable(event.data) : true;

  const onPage = (event) => {
    event.page = event.page + 1;
    setlazyState(event);
  };

  const onSort = (event) => {
    setlazyState(event);
  };

  const onFilter = (event) => {
    console.log(event);
    event["first"] = 0;
    setlazyState(event);
  };

  const openSidebarForm = (modalTitle, modalButton, id) => {
    setVisibleForm({
      visible: true,
      itemId: id,
      title: modalTitle,
    });
    setVisibleLoadingModal({
      visible: true,
      itemId: null,
      title: null,
    });
  };

  const openModal = (id) => {
    setVisibleModal({
      visible: true,
      itemId: id,
    });
  };

  const footer = () => {
    if (totalRecords > 0) {
      return `Showing: ${from} - ${to} of ${totalRecords}`;
    }
    return null;
  };

  return (
    <>
      <DataTable
        //scrollHeight="500px"
        className={"h-full hide-on-print"}
        resizableColumns
        columnResizeMode="expand"
        value={dars}
        lazy
        /*filterDisplay="row"*/ dataKey="dispatch_log_id_format"
        paginator
        footer={footer}
        size="small"
        stripedRows
        scrollable
        /*onRowSelect={(e) =>
          permissionsObject.permissionsObject.update
            ? connStatus
              ? openSidebarForm(
                  "Update Incident Report",
                  "Update",
                  e.data.dispatch_log_id
                )
              : noConectionAlert()
            : null
        }*/
        onRowSelect={(e) =>
          connStatus ? openModal(e.data.dispatch_log_id) : noConectionAlert()
        }
        selectionMode="single"
        metaKeySelection={false}
        first={lazyState.first}
        rows={lazyState.rows}
        totalRecords={totalRecords}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
        rowsPerPageOptions={[5, 10, 25, 50]}
        onFilter={onFilter}
        filters={lazyState.filters}
        loading={loading}
        tableStyle={{ minWidth: "50rem" }}
        selection={selectedItem}
        isDataSelectable={isRowSelectable}
      >
        <Column
          field="daily_activity_report_id_format"
          hidden
          header="ID"
          filterPlaceholder="Search"
        />

        <Column
          field="dispatch_log_date"
          header="Dispatch Log Date"
          body={dispatchDateBodyTemplate}
          filterPlaceholder="Search"
          className=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          header="Type"
          className=" w-8rem md:w-12rem lg:w-14rem "
          body={typeBodyTemplate}
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="officer_name"
          header="User"
          filterPlaceholder="Search"
          className=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="house_hold"
          header="House Hold"
          style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
          filterPlaceholder="Search"
        />

        <Column
          field="name"
          header="Name"
          style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
          filterPlaceholder="Search"
        />

        <Column
          field="details"
          header="Details"
          body={detailsTemplate}
          style={{ minWidth: "100px", maxWidth: "250px", whiteSpace: "unset" }}
          filterPlaceholder="Search"
        />

        <Column header="" body={deleteBodyTemplate} className="w-1rem" />
      </DataTable>

      <ShowModal />
    </>
  );
};

export default Datatable;
