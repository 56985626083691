import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchHouseHoldByCriteria,fetchHouseHoldByPropertyId } from "../api/houseHoldApi";


export function useHouseHoldByCriteria(data,propertyId) {
  return useQuery({
    queryKey: ["households",{propertyId}],
    queryFn: () => fetchHouseHoldByCriteria(data,propertyId),
    retry: false,   
    enabled: data.length >= 3,
    //staleTime: 60 * 1000, //60 seconds
    //enabled: false,
    onSuccess: () => {
      console.log("ALL GOOD")
    },

  });
}

export function useHouseHoldByPropertyId(propertyId) {
  return useQuery({
    queryKey: ["households",{propertyId}],
    queryFn: () => fetchHouseHoldByPropertyId(propertyId),
    retry: false,   
    //staleTime: 60 * 10000, //600 seconds
    //enabled: permits_by === 1,
    //enabled: false,
    staleTime: 60000,
    onSuccess: () => {
      console.log("ALL GOOD")
    },

  });
}


