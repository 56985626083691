import { useState } from "react";
import { useUpdateEffect } from "primereact/hooks";
import { usePosition } from "../../context/position";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { z } from "zod";

import { useLocalState } from "../../hooks/useLocalState";
import secureLocalStorage from "react-secure-storage";

import { Sidebar } from "primereact/sidebar";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { ProgressSpinner } from "primereact/progressspinner";
import { Image } from "primereact/image";
import { Message } from "primereact/message";
import { toast } from "react-toastify";
import { useProperty } from "../../context/PropertyProvider";
import { TOAST_CONFIG } from "../../utils/Constansts";
import { v4 as uuidv4 } from "uuid";
import { utcToLocale } from "../../helpers/helpers";
import { useDar, useInsertDar, useUpdateDar } from "../../hooks/darQueries";
import { convertBase64 } from "../../helpers/helpers";

const MAX_FILES = 13;

const schema = z.object({
  dailyActivityReportTypeId: z.object({
    type: z.string(),
    daily_activity_report_type_id: z.number(),
  }),
  dailyActivityReportCodeId: z.object({
    type: z.string(),
    daily_activity_report_code_id: z.number(),
  }),
  dar_date: z.date(),
  details: z.string().min(1).max(500),
  send_notification: z.boolean(),
  include_gps_coordinates: z.boolean(),
  images: z.any(),
});

const minDate = (userData) => {
  if (userData.isAdmin || userData.isSupervisor) {
    return new Date("2022-01-01");
  } else {
    return new Date();
  }
};

const DarSheet = ({ isVisible, darId, onClose }) => {
  const [userData] = useLocalState("", "userData");

  const [property] = useProperty();

  const [dailyActivityReportTypes] = useLocalState(
    "",
    "dailyActivityReportTypes"
  );
  const [dailyActivityReportCodes] = useLocalState(
    "",
    "dailyActivityReportCodes"
  );

  const positionContext = usePosition();

  const [type, setType] = useState(dailyActivityReportTypes[0]);
  const [code, setCode] = useState(dailyActivityReportCodes[2]);
  const [dar_date, setDarDate] = useState(new Date());
  const [details, setDetails] = useState("");
  const [send_notification, setSendNotification] = useState(false);
  const [include_gps_coordinates, setIncludeGps] = useState(true);
  const [imagesUploaded, setImagesUploaded] = useState([]);
  const [images, setImages] = useState([]);
  const [imagesUrls, setImagesUrls] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);

  const { isPending, isSuccess, isError, data: fetchedDar } = useDar(darId);

  const {
    mutateAsync: mutateInsertDar,
    data: addedDar,
    error: errorInsertDar,
    isError: isErrorInsertDar,
    isSuccess: isSuccessInsertDar,
  } = useInsertDar();

  const {
    mutateAsync: mutateUpdateDar,
    data: updatedDar,
    isSuccess: isSuccessUpdateDar,
  } = useUpdateDar();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    values: {
      dailyActivityReportTypeId: type,
      dailyActivityReportCodeId: code,
      details: details,
      dar_date: dar_date,
      send_notification: send_notification,
      include_gps_coordinates: include_gps_coordinates,
    },

    resolver: zodResolver(schema),
  });

  useUpdateEffect(() => {
    setType(dailyActivityReportTypes[0]);
    setCode(dailyActivityReportCodes[0]);
    setDetails("");
    setDarDate(new Date());
    setIncludeGps(true);
    setSendNotification(false);
    setImages([]);
    setImagesUploaded([]);
    setImagesUrls([]);
    setDeletedImages([]);
  }, [isVisible]);

  useUpdateEffect(() => {
    if (isSuccessInsertDar || isSuccessUpdateDar) {
      onClose();

      if (darId === null) {
        toast.success(
          `You have created DAR ID: ${addedDar?.data.data.daily_activity_report_id}`,
          TOAST_CONFIG
        );
      } else {
        toast.success(
          `You have updaated DAR ID: ${updatedDar?.data.data.daily_activity_report_id}`,
          TOAST_CONFIG
        );
      }
    }
  }, [addedDar, updatedDar]);

  useUpdateEffect(() => {
    if (isSuccess) {
      setCode(getCode(fetchedDar?.data.data.daily_activity_report_code_id));
      setType(getType(fetchedDar?.data.data.daily_activity_report_type_id));
      setDetails(fetchedDar?.data.data.details);
      setDarDate(utcToLocale(fetchedDar?.data.data.dar_date));
      setSendNotification(
        fetchedDar?.data.data.send_notification == 1 ? true : false
      );
      setIncludeGps(
        fetchedDar?.data.data.include_gps_coordinates == 1 ? true : false
      );
      //setValue('images',fetchedDar?.data.data.thumb_images)
      setImagesUrls(fetchedDar?.data.data.thumb_images);
      setImagesUploaded(fetchedDar?.data.data.images);
    }
  }, [fetchedDar, isError]);

  useUpdateEffect(() => {
    if (isErrorInsertDar) {
      console.log(isErrorInsertDar);
      console.log(errorInsertDar);

      onClose();

      toast.success(`You have created a DAR on this device.`, TOAST_CONFIG);
    }
  }, [isErrorInsertDar]);

  function getCode(code_id) {
    return dailyActivityReportCodes.find(
      (element) => element.daily_activity_report_code_id == code_id
    );
  }

  function getType(type_id) {
    return dailyActivityReportTypes.find(
      (element) => element.daily_activity_report_type_id == type_id
    );
  }

  const onSubmit = async (data) => {
    try {
      const {
        position: { latitude, longitude },
      } = positionContext;

      const darData = {
        daily_activity_report_type_id:
          data.dailyActivityReportTypeId.daily_activity_report_type_id,
        daily_activity_report_code_id:
          data.dailyActivityReportCodeId.daily_activity_report_code_id,
        dar_date: new Date(data.dar_date).toISOString(),
        automatic_dar: 0,
        details: data.details,
        gps_coordinates: latitude + "," + longitude,
        include_gps_coordinates: data.include_gps_coordinates,
        map: null,
        property_id: property,
        send_notification: data.send_notification,
        images: images,
        images_urls: imagesUrls,
        images_del: deletedImages,
      };

      if (darId === null) {
        await mutateInsertDar({ json: darData, persistOnStorage: true });
      } else {
        darData["daily_activity_report_id"] = darId;
        await mutateUpdateDar(darData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadFiles = async (e) => {
    if (e.files.length >= MAX_FILES) return;

    const uploadedFiles = e.files;
    const files = [];
    const fileUrls = imagesUrls;

    Object.keys(uploadedFiles).forEach(async (key) => {
      if (uploadedFiles[key].objectURL.includes("blob")) {
        const base64 = await convertBase64(uploadedFiles[key]);
        files.push(base64);
      }
      // console.log(uploadedFiles[key]);
      fileUrls.push(uploadedFiles[key]);
    });
    setImages(files);
    setImagesUrls(fileUrls);
  };

  const removeFiles = async (e, file) => {
    e.preventDefault();

    if (file.includes("blob")) {
      const fileUrls = imagesUrls;
      for (let number = 0; number < imagesUrls.length; number++) {
        var index = imagesUrls[number].objectURL.indexOf(file.objectURL);
        if (index !== -1) {
          fileUrls.splice(number, 1);
        }
      }

      setImagesUrls(fileUrls);
    } else {
      const files = deletedImages;
      files.push(file);
      setDeletedImages(files);

      const fileUrls = imagesUploaded;
      for (let number = 0; number < fileUrls.length; number++) {
        setImagesUploaded((oldState) =>
          oldState.filter((item) => item !== file)
        );
      }
    }
  };

  return (
    <>
      <Sidebar
        visible={isVisible}
        position="right"
        onHide={onClose}
        className="w-full md:w-20rem lg:w-30rem"
      >
        {isPending ? (
          <div className="flex flex-wrap justify-content-between gap-2 mb-1">
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ProgressSpinner
                aria-label="Loading"
                style={{ width: "50px", height: "100vm" }}
                strokeWidth="2"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />

              <p>Loading</p>
            </div>
          </div>
        ) : (
          <>
            <h2>{darId ? "Update DAR" : "New DAR"}</h2>
            {!darId ? (
              <Message
                severity="info"
                text="Remember to explain WHO, WHAT, WHEN, WHERE, WHY, HOW and CONCLUSION"
                className="font-bold mb-3"
              />
            ) : null}
            <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <div className="formgrid grid">
                  <div className="field col">
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="dailyActivityReportTypeId"
                        rules={{
                          validate: ({ value } = {}) =>
                            value !== undefined || "You must select a DAR type",
                          valueAsNumber: true,
                        }}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            filter
                            placeholder="Select a type"
                            optionLabel="type"
                            options={dailyActivityReportTypes}
                            {...field}
                          />
                        )}
                      />
                      <label htmlFor="type">Type</label>
                    </span>
                    <small className="block pt-1">
                      {errors.dailyActivityReportTypeId && (
                        <div className="text-red-500">
                          {errors.dailyActivityReportTypeId.message}
                        </div>
                      )}
                    </small>
                  </div>
                  <div className="field col">
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="dailyActivityReportCodeId"
                        rules={{
                          validate: ({ value } = {}) =>
                            value !== undefined || "You must select a DAR code",
                          valueAsNumber: true,
                        }}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            filter
                            placeholder="Select a code"
                            optionLabel="type"
                            options={dailyActivityReportCodes}
                            {...field}
                          />
                        )}
                      />
                      <label htmlFor="type">Code</label>
                    </span>
                    <small className="block pt-1">
                      {errors.dailyActivityReportCodeId && (
                        <div className="text-red-500">
                          {errors.dailyActivityReportCodeId.message}
                        </div>
                      )}
                    </small>
                  </div>
                </div>
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Calendar
                    id="dar_date"
                    name="dar_date"
                    onChange={(e) => {
                      setDarDate(e.value);
                    }}
                    value={dar_date}
                    dateFormat="mm/dd/yy"
                    showButtonBar
                    showTime
                    hourFormat="24"
                    maxDate={new Date()}
                    minDate={minDate(userData[0])}
                    hideOnDateTimeSelect
                  />

                  <label htmlFor="dar_date">
                    Date <small>(mm/dd/yyyy)</small>
                  </label>
                </span>
                <small className="block pt-1">
                  {errors.dar_date && (
                    <div className="text-red-500">
                      {errors.dar_date.message}
                    </div>
                  )}
                </small>
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="details"
                    control={control}
                    rules={{ required: "Details is required." }}
                    render={({ field, fieldState }) => (
                      <InputTextarea
                        id={field.name}
                        {...field}
                        value={field.value ?? ""}
                        autoFocus
                        maxLength={500}
                        rows={5}
                        cols={30}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />

                  <label htmlFor="details">
                    Details <small>(max 500 characters)</small>
                  </label>
                </span>
                <small className="block pt-1">
                  {errors.details && (
                    <div className="text-red-500">{errors.details.message}</div>
                  )}
                </small>
              </div>

              <div className="field">
                <Controller
                  control={control}
                  name={"images"}
                  rules={{ required: "Image is required" }}
                  render={({ field: { value, onChange, ...field } }) => {
                    return (
                      <FileUpload
                        multiple
                        mode="advanced"
                        {...field}
                        value={value?.fileName}
                        onSelect={handleUploadFiles}
                        type="file"
                        id="images"
                        chooseLabel="Select Image/s"
                        uploadOptions={{ className: "hidden" }}
                      />
                    );
                  }}
                />

                <small className="block pt-1">
                  {errors.images && (
                    <div className="text-red-500">{errors.images.message}</div>
                  )}
                </small>
              </div>
              {imagesUploaded ? (
                imagesUploaded.length > 0 ? (
                  <div className="field">
                    <ul className="m-0 p-0 list-none border-1 surface-border border-round p-2 flex flex-column gap-1 w-full ">
                      {imagesUploaded.map((url, i) => (
                        <li
                          key={uuidv4()}
                          className={`hover:surface-hover border-round border-1 border-transparent transition-all transition-duration-200 flex align-items-center justify-content-between border-primary'}`}
                          //onContextMenu={(event) => onRightClick(event, user)}
                        >
                          <div className="flex align-items-center gap-1">
                            <Image
                              src={url}
                              zoomSrc={imagesUploaded[i]}
                              key={i}
                              width="80"
                              height="80"
                              preview
                            />
                            <Button
                              icon="pi pi-trash"
                              onClick={(e) => removeFiles(e, url)}
                              severity="danger"
                              size="small"
                              style={{ backgroundColor: "#d32f2f80" }}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null
              ) : null}
              <div className="field">
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="send_notification"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <InputSwitch
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange} // send value to hook form
                        checked={value}
                        inputRef={ref}
                      />
                    )}
                  />

                  <label htmlFor="send_notification" className="ml-2">
                    &nbsp; &nbsp; &nbsp; &nbsp; Send notification to prop.
                    manager
                  </label>
                </span>
              </div>

              {userData[0].isAdmin ? (
                <div className="field">
                  <span className="p-float-label">
                    <Controller
                      control={control}
                      name="include_gps_coordinates"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <InputSwitch
                          onBlur={onBlur} // notify when input is touched
                          onChange={onChange} // send value to hook form
                          checked={value}
                          inputRef={ref}
                          //disabled={userData[0].isOfficer || userData[0].isPostCommander || userData[0].isSupervisor ? true : false}
                        />
                      )}
                    />

                    <label htmlFor="include_gps_coordinates" className="ml-2">
                      &nbsp; &nbsp; &nbsp; &nbsp; Include GPS coordinates
                    </label>
                  </span>
                </div>
              ) : null}

              <Button
                disabled={isSubmitting}
                type="submit"
                label={isSubmitting ? "Loading..." : "Submit"}
                className="mt-2"
              />
              {errors.root && (
                <div className="text-red-500">{errors.root.message}</div>
              )}
            </form>
          </>
        )}
      </Sidebar>
    </>
  );
};

export default DarSheet;
