import { useNavigate } from "react-router-dom";
import { useContext,useState } from "react";
import VisibleContext from "../../context/VisibleContext";

import { useLocalState } from "../../hooks/useLocalState";

import Datatable from "./Datatable";
import SideBar from "./SideBar";

import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";

import { useQueryClient } from "@tanstack/react-query";

const Citations = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { VisibleFormContext, visibleLoadingModalContext } = useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;

  const [userData] = useLocalState("", "userData");
  const [roles] = userData[0].user.roles;
  const permissions = roles?.permissions || null;

  let permissionsObject = {}
  permissionsObject['create'] = permissions.find(({ name }) => name === "citation create") ? true : false
  permissionsObject['show'] = permissions.find(({ name }) => name === "citation show") ? true : false
  permissionsObject['update'] = permissions.find(({ name }) => name === "citation update") ? true : false
  permissionsObject['delete'] = permissions.find(({ name }) => name === "citation delete") ? true : false

  const refechCitations = (e) => {
    queryClient.invalidateQueries({
      queryKey: ["citations"],
      //exact: true,
      refetchActive: true,
      refetchInactive: false,
    });
  };

  const openSidebarForm = () => {
    setVisibleForm({
      visible: true,
      itemId: null,
      title: "New Citation",
    });

    queryClient.invalidateQueries({
      queryKey: ["vehicles_details"],
      refetchActive: true,
      refetchInactive: false,
    });
  };

  const vehicleSet = useState(0);

  return (
    <>
      <SideBar parameter={vehicleSet} />
      <div className="card ">
        <div className="flex flex-wrap justify-content-between gap-2 mb-1">
          <div className=" p-toolbar-group-start p-toolbar-group-left">
            <Avatar
              label="Citations"
              size="large"
              style={{ backgroundColor: "white", width: "7rem" }}
            />
            <Button
              icon="pi pi-refresh"
              rounded
              text
              aria-label="Refresh"
              tooltip="Refresh"
              onClick={(e) => refechCitations(e)}
            />
          </div>
          {permissionsObject.create ? 
            <Button
              onClick={() => openSidebarForm()}
              label="New Citation"
              id="newBtn"
              //style={{ backgroundColor: "#3F51B580" }}
            />
            : null }
        </div>
        <Datatable permissionsObject={permissionsObject}/>
      </div>

      
    </>
  );
};

export default Citations;
