import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { useUpdateEffect } from "primereact/hooks";
import VisibleContext from "../../context/VisibleContext";
import { useLocalState } from "../../hooks/useLocalState";

import { Chart } from "primereact/chart";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { useQueryClient } from "@tanstack/react-query";

import { useStats } from "../../hooks/dashboardQueries";
import secureLocalStorage from "react-secure-storage";
import { useProperty } from "../../context/PropertyProvider";

const Dashboards = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { VisibleFormContext, visibleLoadingModalContext } =
    useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleLoadingModal, setVisibleLoadingModal] =
    visibleLoadingModalContext;

  const [userData] = useLocalState("", "userData");
  const [roles] = userData[0].user.roles;
  const permissions = roles?.permissions || null;

  let permissionsObject = {};
  permissionsObject["create"] = permissions.find(
    ({ name }) => name === "dispatchlog create"
  )
    ? true
    : false;
  permissionsObject["show"] = permissions.find(
    ({ name }) => name === "dispatchlog show"
  )
    ? true
    : false;
  permissionsObject["update"] = permissions.find(
    ({ name }) => name === "dispatchlog update"
  )
    ? true
    : false;
  permissionsObject["delete"] = permissions.find(
    ({ name }) => name === "dispatchlog delete"
  )
    ? true
    : false;

  const refetchDashboards = (e) => {
    queryClient.invalidateQueries({
      queryKey: ["stats"],
      //exact: true,
      refetchActive: true,
      refetchInactive: false,
    });
  };

  const [property] = useProperty();

  const {
    isLoading,
    isFetching,
    isPending,
    isSuccess,
    isError: isErrorfetchedDars,
    data: fetchedStats,
    error,
    refetch,
  } = useStats(property);

  const [darsCurrent, setDarsCurrent] = useState(0);
  const [darsLast, setDarsLast] = useState(0);
  const [darsVariation, setDarsVariation] = useState(0);
  const [darsRed, setDarsRed] = useState(0);
  const [darsYellow, setDarsYellow] = useState(0);

  useUpdateEffect(() => {
    if (isSuccess) {
      setDarsCurrent(fetchedStats?.data.daily_activity_reports.current);
      setDarsLast(fetchedStats?.data.daily_activity_reports.last);
      setDarsVariation(fetchedStats?.data.daily_activity_reports.variation);
      setDarsRed(fetchedStats?.data.daily_activity_reports.red_count);
      setDarsYellow(fetchedStats?.data.daily_activity_reports.yellow_count);
    }
  }, [fetchedStats]);

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useUpdateEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "DARs",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: [65, 59, 80, 81, 56, 55, 40],
        },
        {
          label: "Incident Reports",
          backgroundColor: documentStyle.getPropertyValue("--pink-500"),
          borderColor: documentStyle.getPropertyValue("--pink-500"),
          data: [28, 48, 40, 19, 86, 27, 90],
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            fontColor: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <>
      <div className="card ">
        <div className="flex flex-wrap justify-content-between gap-2 mb-1">
          <div className=" p-toolbar-group-start p-toolbar-group-left">
            <Avatar
              label="Dashboard"
              size="large"
              style={{ backgroundColor: "white", width: "8rem" }}
            />
            <Button
              icon="pi pi-refresh"
              rounded
              text
              aria-label="Refresh"
              tooltip="Refresh"
              onClick={(e) => refetchDashboards(e)}
            />
          </div>
        </div>

        <div className="grid">
          <div className="col-12 md:col-6 lg:col-3">
            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <div className="flex justify-content-between mb-3">
                <div>
                  <span className="block text-500 font-medium mb-3">DARs</span>
                  <div className="text-900 font-medium text-xl">
                    {darsCurrent}
                    <span className="text-400 font-medium text-base">
                      {darsCurrent > darsLast ? "  + " : "  -"}
                      {darsVariation}{" "}
                    </span>
                    <br />
                    <span className="text-400 font-medium text-sm">
                      {darsLast} total last week{" "}
                    </span>
                  </div>
                </div>
                <div
                  className="flex align-items-center justify-content-center bg-blue-100 border-round"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <i className="pi pi-list text-blue-500 text-xl"></i>
                </div>
              </div>
              <span className="text-yellow-500 font-medium">
                {darsYellow} new{" "}
              </span>
              <span className="text-red-500 font-medium">{darsRed} new </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3">
            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <div className="flex justify-content-between mb-3">
                <div>
                  <span className="block text-500 font-medium mb-3">
                    Incident Reports
                  </span>
                  <div className="text-900 font-medium text-xl">$2.100</div>
                </div>
                <div
                  className="flex align-items-center justify-content-center bg-orange-100 border-round"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <i className="pi pi-map-marker text-orange-500 text-xl"></i>
                </div>
              </div>
              <span className="text-green-500 font-medium">%52+ </span>
              <span className="text-500">since last week</span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3">
            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <div className="flex justify-content-between mb-3">
                <div>
                  <span className="block text-500 font-medium mb-3">
                    Pool Access Logs
                  </span>
                  <div className="text-900 font-medium text-xl">28441</div>
                </div>
                <div
                  className="flex align-items-center justify-content-center bg-cyan-100 border-round"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <i className="pi pi-inbox text-cyan-500 text-xl"></i>
                </div>
              </div>
              <span className="text-green-500 font-medium">520 </span>
              <span className="text-500">newly registered</span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3">
            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <div className="flex justify-content-between mb-3">
                <div>
                  <span className="block text-500 font-medium mb-3">
                    Dispatch Logs{" "}
                  </span>
                  <div className="text-900 font-medium text-xl">152 Unread</div>
                </div>
                <div
                  className="flex align-items-center justify-content-center bg-purple-100 border-round"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <i className="pi pi-comment text-purple-500 text-xl"></i>
                </div>
              </div>
              <span className="text-green-500 font-medium">85 </span>
              <span className="text-500">responded</span>
            </div>
          </div>
        </div>

        <div className="card">
          <Chart type="bar" data={chartData} options={chartOptions} />
        </div>
      </div>
    </>
  );
};

export default Dashboards;
