import { useContext, useState, useEffect } from "react";
import { useUpdateEffect } from "primereact/hooks";
import { usePosition } from "../../context/position";
import VisibleContext from "../../context/VisibleContext";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";
import { Controller } from "react-hook-form";
import { z } from "zod";

import { useLocalState } from "../../hooks/useLocalState";
import secureLocalStorage from "react-secure-storage";

import { Sidebar } from "primereact/sidebar";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Card } from "primereact/card";
import { Message } from "primereact/message";
import { MultiSelect } from "primereact/multiselect";

import { InputSwitch } from "primereact/inputswitch";

import { Dropdown } from "primereact/dropdown";
import { AutoComplete } from "primereact/autocomplete";
import { classNames } from "primereact/utils";
import { ProgressSpinner } from "primereact/progressspinner";
import { Image } from "primereact/image";

import { CitationPrintable } from "./CitationPrintable";

import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";
import { v4 as uuidv4 } from "uuid";

import { utcToLocale } from "../../helpers/helpers";

import { useQueryClient } from "@tanstack/react-query";

import {
  useCreateCitations,
  useCitation,
  useInsertCitation,
  useUpdateCitation,
} from "../../hooks/citationQueries";

import { useVehicleByPropertyId } from "../../hooks/vehicleQueries";

import { convertBase64 } from "../../helpers/helpers";
import { useProperty } from "../../context/PropertyProvider";

const SideBar = (parameter) => {
  const queryClient = useQueryClient();
  const [userData] = useLocalState("", "userData");
  const [property] = useProperty();

  const [dailyActivityReportCodes] = useLocalState(
    "",
    "dailyActivityReportCodes"
  );

  const positionContext = usePosition();
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  const {
    VisibleFormContext,
    visibleLoadingModalContext,
    visibleModalContext,
    visibleCitationContext,
  } = useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleModal, setVisibleModal] = visibleModalContext;
  const [visibleCitation, setVisibleCitation] = visibleCitationContext;
  const [visibleLoadingModal, setVisibleLoadingModal] =
    visibleLoadingModalContext;

  const [vehicles, setVehicles] = useState([]);
  const [vehicleId, setVehicleId] = useState(0);

  const [vehicleCitationTypes, setVehicleCitationTypes] = useState([]);
  const [citationTypeId, setCitationTypeId] = useState(0);

  const [code, setCode] = useState(dailyActivityReportCodes[2]);
  const [citation_date, setCitationDate] = useState(new Date());
  //const [citation_date, setCitationDate] = useState("");
  const [details, setDetails] = useState("");
  const [send_notification, setSendNotification] = useState(false);
  const [imagesUploaded, setImagesUploaded] = useState([]);
  const [images, setImages] = useState([]);
  const [imagesUrls, setImagesUrls] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);

  const [dropdownEnabled, setDropdownEnabled] = useState(true);

  const [isCreatingCitation, setIsCreatingCitation] = useState(false);
  const [printCitation, setPrintCitation] = useState(false);
  const [citationId, setCitationId] = useState(null);

  const schema = z.object({
    /*vehicleCitationTypeId: z.object({
      type: z.string(),
      citation_type_id: z.number(),
      value: z.number(),
    }),*/
    citation_type_id: z.any(),
    /*dailyActivityReportCodeId: z.object({
      type: z.string(),
      daily_activity_report_code_id: z.number(),
    }),*/
    citation_date: z.date(),
    vehicle_id: z.any(),
    details: z.string().max(500),
    send_notification: z.boolean(),
    images: z.any(),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
    setError,
    setValue,
    form,
  } = useForm({
    values: {
      citation_type_id: citationTypeId,
      dailyActivityReportCodeId: code,
      details: details,
      citation_date: citation_date,
      send_notification: send_notification,
      vehicle_id: vehicleId,
    },

    resolver: zodResolver(schema),
  });

  function getCode(code_id) {
    try {
      return dailyActivityReportCodes.find(
        (element) => element.daily_activity_report_code_id == code_id
      );
    } catch (error) {
      console.log(error);
    }
  }

  function getCitationType(citation_type_id) {
    try {
      return vehicleCitationTypes.find(
        (element) => element.citation_type_id == citation_type_id
      );
    } catch (error) {
      console.log(error);
    }
  }

  function getVehicle(vehicle_id) {
    //console.log(vehicle_id)
    try {
      return vehicles.find((element) => element.vehicle_id == vehicle_id);
    } catch (error) {
      console.log(error);
    }
  }

  const {
    isPending: isPendingVehicleByPropertyId,
    isSuccess: isSuccessVehicleByPropertyId,
    isError: isErrorVehicleByPropertyId,
    data: fetchedVehicleByPropertyId,
    error: errorVehicleByPropertyId,
    refetch: refectVehicleByPropertyId,
  } = useVehicleByPropertyId(property);

  useUpdateEffect(() => {
    if (isSuccessVehicleByPropertyId) {
      setVehicles(fetchedVehicleByPropertyId.data.vehicles);
      //console.log(fetchedVehicleByPropertyId.data.vehicles)
    }
  }, [fetchedVehicleByPropertyId]);

  useUpdateEffect(() => {
    if (parameter.vehicles) {
      //console.log(parameter.vehicles)
      setVehicles(parameter.vehicles);
    }
  }, [parameter]);

  useUpdateEffect(() => {
    setCitationTypeId(0);
    setCode(dailyActivityReportCodes[0]);
    setDetails("");
    setCitationDate(new Date());

    setImages([]);
    setImagesUploaded([]);
    setImagesUrls([]);
    setDeletedImages([]);

    if (parameter.vehicle?.vehicle_id > 0) {
      console.log(parameter.vehicle?.vehicle_id);

      setVehicleId(getVehicle(parameter.vehicle?.vehicle_id));
      setDropdownEnabled(true);
    } else {
      setDropdownEnabled(false);
    }
  }, [visibleForm.visible]);

  const {
    isPending,
    isSuccess,
    isError,
    data: fetchedCitation,
    error,
  } = useCitation(visibleForm.itemId, property);

  useUpdateEffect(() => {
    if (isPending) {
      setVisibleLoadingModal({
        visible: true,
        itemId: null,
        title: null,
      });
    } else {
      setVisibleLoadingModal({
        visible: false,
        itemId: null,
        title: null,
      });
    }
  }, [isPending]);

  useUpdateEffect(() => {
    if (isSuccess) {
      setCode(
        getCode(fetchedCitation?.data.data.daily_activity_report_code_id)
      );

      setDetails(fetchedCitation?.data.data.details);

      setCitationDate(utcToLocale(fetchedCitation?.data.data.citation_date));
      setSendNotification(
        fetchedCitation?.data.data.send_notification == 1 ? true : false
      );
      setVehicleId(getVehicle(fetchedCitation?.data.data.vehicle.vehicle_id));
      setCitationTypeId(
        getCitationType(fetchedCitation?.data.data.citation_type_id)
      );
      //setValue('images',fetchedCitation?.data.data.thumb_images)
      setImagesUrls(fetchedCitation?.data.data.thumb_images);
      setImagesUploaded(fetchedCitation?.data.data.images);

      setVisibleLoadingModal({
        visible: false,
        itemId: null,
        title: null,
      });
    }
  }, [fetchedCitation]);

  const {
    isLoading,
    isFetching,
    isPending: isPendingCreateCitations,
    isSuccess: isSuccessCreateCitations,
    isError: isErrorCreateCitations,
    data: fetchedCreateCitations,
    error: CreateCitations,
    refetch,
  } = useCreateCitations(property);

  useUpdateEffect(() => {
    if (isSuccessCreateCitations) {
      //console.log(fetchedCreateCitations?.data.property_citation_types)
      setVehicleCitationTypes(
        fetchedCreateCitations?.data.property_citation_types
      );
    }
  }, [fetchedCreateCitations]);

  const {
    mutateAsync: mutateInsertCitation,
    data: addedCitation,
    error: errorInsertCitation,
    isError: isErrorInsertCitation,
    isPending: isPendingInsertCitation,
    isSuccess: isSuccessInsertCitation,
  } = useInsertCitation();

  const {
    mutateAsync: mutateUpdateCitation,
    data: updatedCitation,
    error: errorUpdateCitation,
    isError: isErrorUpdateCitation,
    isPending: isPendingUpdateCitation,
    isSuccess: isSuccessUpdateCitation,
  } = useUpdateCitation();

  useUpdateEffect(() => {
    if (isSuccessInsertCitation) {
      setIsCreatingCitation(false);
      setCitationId(addedCitation?.data.data.citation_id);

      if (visibleForm.itemId === null) {
        toast.success(
          `You have created Citation ID: ${addedCitation?.data.data.citation_id}`,
          TOAST_CONFIG
        );
      }
      queryClient.invalidateQueries({
        queryKey: ["vehicles_details"],
        refetchActive: true,
        refetchInactive: false,
      });
    }
  }, [addedCitation]);

  useUpdateEffect(() => {
    if (isSuccessUpdateCitation) {
      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });
      setIsCreatingCitation(false);
      setCitationId(addedCitation?.data.data.citation_id);

      console.log(updatedCitation);
      toast.success(
        `You have updaated Citation ID: ${updatedCitation?.data.data.citation_id}`,
        TOAST_CONFIG
      );
    }
  }, [updatedCitation]);

  useUpdateEffect(() => {
    if (citationId) {
      setPrintCitation(true);
      setVisibleForm({
        visible: true,
        itemId: citationId,
        title: "Citation Add Proof",
      });
    }
  }, [citationId]);

  useUpdateEffect(() => {
    if (isErrorInsertCitation) {
      console.log(isErrorInsertCitation);
      console.log(errorInsertCitation);

      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      toast.success(
        `You have created a vehicle citation on this device.`,
        TOAST_CONFIG
      );
    }
  }, [isErrorInsertCitation]);

  const minDate = () => {
    if (userData[0].isAdmin || userData[0].isSupervisor) {
      return new Date("2022-01-01");
    } else {
      return new Date();
    }
  };

  const handleHide = () => {
    setVisibleForm({
      visible: false,
      itemId: null,
      title: null,
    });
    setIsCreatingCitation(false);
    setPrintCitation(false);
    setVisibleCitation({ visible: false });
    setVehicleId(null);
    setCitationTypeId(null);
  };

  const onTemplateSelect = (e) => {
    if (e.files.length < 13) {
      uploadFiles(e.files);
    }
  };

  const uploadFiles = async (uploadedFiles) => {
    //let uploadedFiles = e.files;
    let files = [];

    let fileUrls = [];
    //console.log(imagesUrls);

    Object.keys(uploadedFiles).forEach(async (key) => {
      if (uploadedFiles[key].objectURL.includes("blob")) {
        const base64 = await convertBase64(uploadedFiles[key]);
        files.push(base64);
      }
      console.log(uploadedFiles[key]);
      console.log(fileUrls);
      fileUrls.push(uploadedFiles[key]);
    });
    setImages(files);
    setImagesUrls(fileUrls);
  };

  const removeFiles = async (e, file) => {
    e.preventDefault();

    //console.log(file);

    if (file.includes("blob")) {
      const fileUrls = imagesUrls;
      for (let number = 0; number < imagesUrls.length; number++) {
        var index = imagesUrls[number].objectURL.indexOf(file.objectURL);
        if (index !== -1) {
          fileUrls.splice(number, 1);
        }
      }

      setImagesUrls(fileUrls);
    } else {
      const files = deletedImages;
      files.push(file);
      setDeletedImages(files);

      const fileUrls = imagesUploaded;
      for (let number = 0; number < fileUrls.length; number++) {
        //console.log(file);
        setImagesUploaded((oldState) =>
          oldState.filter((item) => item !== file)
        );

        /*var index = fileUrls[number].indexOf(file);
        console.log(index);
        if (index !== -1) {
          fileUrls.splice(number, 1);
          console.log(fileUrls);
        }
        */
      }
      //setImagesUploaded(fileUrls)
    }

    //console.log(imagesUploaded);

    //callback();

    //const files = convertFiles(imagesUrls)
    //setImages(files);
  };

  const handleCreateCitation = () => {
    setIsCreatingCitation(true);
    //setSelectedVehicle(false)
  };

  const onSubmit = async (data) => {
    //console.log(data);

    try {
      let json = {
        citation_types: data.citation_type_id,
        //daily_activity_report_code_id: data.dailyActivityReportCodeId.daily_activity_report_code_id,
        daily_activity_report_code_id: 1,
        citation_date: new Date(data.citation_date).toISOString(),
        details: data.details,
        vehicle_id: data.vehicle_id.vehicle_id
          ? data.vehicle_id.vehicle_id
          : fetchedCitation?.data.data.vehicle.vehicle_id,
        //gps_coordinates: lat + "," + lng,
        gps_coordinates: fetchedCitation?.data.data.gps_coordinates
          ? fetchedCitation?.data.data.gps_coordinates
          : positionContext.position.latitude +
            "," +
            positionContext.position.longitude,
        property_id: property,
        //property_id: 1,
        send_notification: data.send_notification,
        //send_notification: 1,
        images: images,
        images_urls: imagesUrls,
        images_del: deletedImages,
      };

      //console.log(json);

      let reponse;

      if (visibleForm.itemId === null) {
        reponse = await mutateInsertCitation({
          json: json,
          persistOnStorage: true,
        });
      } else {
        json["citation_id"] = visibleForm.itemId;
        reponse = await mutateUpdateCitation(json);
      }

      //console.log(reponse);
    } catch (error) {
      console.log(error);
      //setError("root", {message: error,});
    }
  };

  return (
    <>
      <Sidebar
        visible={visibleForm.visible}
        position="right"
        onHide={() => handleHide()}
        className="w-full md:w-20rem lg:w-30rem"
      >
        {visibleLoadingModal.visible ? (
          <div className="flex flex-wrap justify-content-between gap-2 mb-1">
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ProgressSpinner
                aria-label="Loading"
                style={{ width: "50px", height: "100vm" }}
                strokeWidth="2"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />

              <p>Loading</p>
            </div>
          </div>
        ) : (
          <>
            <h2>{visibleForm.title}</h2>
            {!printCitation ? (
              <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                  <span className="p-float-label">
                    <Controller
                      control={control}
                      name="vehicle_id"
                      rules={{
                        validate: ({ value } = {}) =>
                          value !== undefined || "You must select a vehicle",
                        valueAsNumber: true,
                      }}
                      render={({ field }) => (
                        <Dropdown
                          id={field.name}
                          filter
                          disabled={dropdownEnabled}
                          placeholder="Select a vehicle"
                          optionLabel="label"
                          options={vehicles}
                          {...field}
                          onChange={(e) => {
                            setVehicleId(e.value);
                          }}
                          //optionValue={type}
                          value={field.value}
                        />
                      )}
                    />
                    <label htmlFor="vehicle_id">Vehicle</label>
                  </span>
                  <small className="block pt-1">
                    {errors.vehicle_id && (
                      <div className="text-red-500">
                        {errors.vehicle_id.message}
                      </div>
                    )}
                  </small>
                </div>
                {vehicleId ? (
                  <>
                    <div className="field">
                      <div className="formgrid grid">
                        <div className="field col">
                          {vehicleId?.safelistings?.length > 0 ? (
                            <Message
                              className="mb-2"
                              style={{ justifyContent: "left" }}
                              severity="success"
                              text="This plate has an available permit"
                            />
                          ) : null}

                          {vehicleId?.citations?.length > 0 ? (
                            <Card
                              title={
                                "Citations in this period: " +
                                vehicleId?.citations_count +
                                " / " +
                                vehicleId?.citations_before_towed
                              }
                              style={{ background: "#0ea5e985" }}
                              severity="info"
                            >
                              {vehicleId?.citations.map((citation) => (
                                <div
                                  id={citation.citation_id}
                                  className="m-0 pb-2"
                                >
                                  <small>
                                    {citation.citation_date +
                                      "    " +
                                      citation.citation_types}
                                  </small>
                                </div>
                              ))}
                            </Card>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                <>
                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="citation_type_id"
                        rules={{
                          validate: ({ value } = {}) =>
                            value !== undefined ||
                            "You must select a Citation type",
                          valueAsNumber: true,
                        }}
                        className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                        render={({ field }) => (
                          <MultiSelect
                            id={field.name}
                            placeholder="Select a type"
                            optionLabel="type"
                            options={vehicleCitationTypes}
                            {...field}
                            onChange={(e) => {
                              setCitationTypeId(e.value);
                            }}
                            className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                            value={field.value}
                          />
                        )}
                      />
                      <label htmlFor="type">Type</label>
                    </span>
                    <small className="block pt-1">
                      {errors.citation_type_id && (
                        <div className="text-red-500">
                          {errors.citation_type_id.message}
                        </div>
                      )}
                    </small>
                  </div>

                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        name="details"
                        control={control}
                        rules={{ required: "Details is required." }}
                        render={({ field, fieldState }) => (
                          <InputTextarea
                            id={field.name}
                            {...field}
                            value={field.value ?? ""}
                            autoFocus
                            maxLength={500}
                            rows={5}
                            cols={30}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      <label htmlFor="details">
                        Details <small>(max 500 characters)</small>
                      </label>
                    </span>
                    <small className="block pt-1">
                      {errors.details && (
                        <div className="text-red-500">
                          {errors.details.message}
                        </div>
                      )}
                    </small>
                  </div>

                  <div className="field">
                    <Controller
                      control={control}
                      name={"images"}
                      rules={{ required: "Image is required" }}
                      render={({ field: { value, onChange, ...field } }) => {
                        return (
                          <FileUpload
                            multiple
                            mode="advanced"
                            {...field}
                            value={value?.fileName}
                            onSelect={onTemplateSelect}
                            type="file"
                            id="images"
                            chooseLabel="Select Image/s"
                            uploadOptions={{ className: "hidden" }}
                          />
                        );
                      }}
                    />

                    <small className="block pt-1">
                      {errors.images && (
                        <div className="text-red-500">
                          {errors.images.message}
                        </div>
                      )}
                    </small>
                  </div>

                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="send_notification"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <InputSwitch
                            onBlur={onBlur} // notify when input is touched
                            onChange={onChange} // send value to hook form
                            checked={value}
                            inputRef={ref}
                          />
                        )}
                      />

                      <label htmlFor="include_gps_coordinates" className="ml-2">
                        &nbsp; &nbsp; &nbsp; &nbsp; Send notification to prop.
                        manager
                      </label>
                    </span>
                  </div>

                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    label={isSubmitting ? "Loading..." : "Submit"}
                    className="mt-2"
                  />
                  {errors.root && (
                    <div className="text-red-500">{errors.root.message}</div>
                  )}
                </>
              </form>
            ) : (
              <>
                <div className="formgrid grid">
                  <div className="field col-11 col-offset-1">
                    <Button
                      onClick={() =>
                        setVisibleCitation({
                          visible: true,
                          itemId: citationId,
                        })
                      }
                      label="Print"
                      className="hide-on-print"
                      icon="pi pi-print"
                    />
                  </div>
                  <div className="field col-11 col-offset-1">
                    <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
                      <div className="field">
                        <Controller
                          control={control}
                          name={"images"}
                          rules={{ required: "Image is required" }}
                          render={({
                            field: { value, onChange, ...field },
                          }) => {
                            return (
                              <FileUpload
                                multiple
                                mode="advanced"
                                {...field}
                                value={value?.fileName}
                                onSelect={onTemplateSelect}
                                type="file"
                                id="images"
                                chooseLabel="Select Image/s"
                                uploadOptions={{ className: "hidden" }}
                              />
                            );
                          }}
                        />
                        {errors?.images?.message}
                      </div>

                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        label={isSubmitting ? "Loading..." : "Submit"}
                        className="mt-2"
                      />
                      {errors.root && (
                        <div className="text-red-500">
                          {errors.root.message}
                        </div>
                      )}
                    </form>
                  </div>
                </div>
                <CitationPrintable />
              </>
            )}
          </>
        )}
      </Sidebar>
    </>
  );
};

export default SideBar;
