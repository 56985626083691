import { useContext, useState, useEffect } from "react";
import { useUpdateEffect } from "primereact/hooks";
import { useUnmountEffect } from "primereact/hooks";
import { usePosition } from "../../context/position";
import VisibleContext from "../../context/VisibleContext";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";
import { Controller } from "react-hook-form";
import { z } from "zod";

import { useLocalState } from "../../hooks/useLocalState";
import secureLocalStorage from "react-secure-storage";

import { Sidebar } from "primereact/sidebar";

import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from "primereact/button";
import { InputSwitch } from 'primereact/inputswitch'; 
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { ProgressSpinner } from "primereact/progressspinner";
import { AutoComplete } from "primereact/autocomplete";


import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";
import { v4 as uuidv4 } from "uuid";

import { utcToLocale } from "../../helpers/helpers";

import {
  usePoolAccessLog,
  useInsertPoolAccessLog,
  useUpdatePoolAccessLog,
} from "../../hooks/poolAccessLogQueries";

import { usePropertyPoolByPropertyId } from "../../hooks/propertyPoolQueries";

import { useHouseHoldByPropertyId } from "../../hooks/houseHoldQueries";

import { convertBase64 } from "../../helpers/helpers";

const SideBar = (permissionsObject) => {
  const [userData] = useLocalState("", "userData");

  let userContextProperty = secureLocalStorage.getItem("userContextProperty");
  const [poolAccessLogTypes] = useLocalState("", "poolAccessLogTypes");
  //const [propertyPools,setPropertyPools] = useLocalState("", "propertyPools");

  //const dailyActivityReportTypes = JSON.parse(secureLocalStorage.getItem("dailyActivityReportTypes"));

  const [dailyActivityReportCodes] = useLocalState("","dailyActivityReportCodes");
  //const dailyActivityReportCodes = JSON.parse(secureLocalStorage.getItem("dailyActivityReportCodes"));

  const positionContext = usePosition();
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  const { VisibleFormContext, visibleLoadingModalContext } = useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleLoadingModal, setVisibleLoadingModal] = visibleLoadingModalContext;

  const [propertyPools,setPropertyPools] = useState([]);
  const [propertyPool, setPropertyPool] = useState(propertyPools[0]);
  const [code, setCode] = useState(dailyActivityReportCodes[2]);
  const [pool_access_date, setPoolAccessogDate] = useState(new Date());
  //const [pool_access_date, setPoolAccessLogDate] = useState("");
  const [details, setDetails] = useState("");
  const [name, setName] = useState("");
  const [key_fob_number, setKeyFobNumber] = useState("");
  const [number_of_guests, setNumberOfGuests] = useState(null);
  const [send_notification, setSendNotification] = useState(false);
  const [imagesUploaded, setImagesUploaded] = useState([]);
  const [images, setImages] = useState([]);
  const [imagesUrls, setImagesUrls] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);

  const [selectedPool, setSelectedPool] = useState(null);
  //const [selectedHouseHold, setSelectedHouseHold] = useState(null);

  const [houseHolds, setHouseHolds] = useState([]);
  const [houseHold, setHouseHold] = useState("");

  const schema = z.object({
    houseHoldId: z.object({
      label: z.string(),
      house_hold_id: z.number(),
    }),
    propertyPoolId: z.object({
      pool_name: z.string(),
      property_pool_id: z.number(),
    }),
    /*dailyActivityReportCodeId: z.object({
      type: z.string(),
      daily_activity_report_code_id: z.number(),
    }),*/
    pool_access_date: z.date(),
    details: z.string().min(1),
    name: z.string().min(1),
    number_of_guests: z.number(),
    key_fob_number: z.string().min(1),
    send_notification: z.boolean(),
    images: z.any(),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
    setError,
    setValue,
    form,
  } = useForm({
    //shouldUnregister: true,

    /*defaultValues: {
      //dailyActivityReportTypeId: type,
      dailyActivityReportCodeId: dailyActivityReportCodes[2],

      details: "d",
      pool_access_date: new Date(),
      send_notification: false,
      include_gps_coordinates: false,
    },*/
    values: {
      houseHoldId: houseHold,
      propertyPoolId: propertyPool,
      dailyActivityReportCodeId: code,
      details: details,
      name: name,
      pool_access_date: pool_access_date,
      send_notification: send_notification,
      number_of_guests: number_of_guests,
      key_fob_number: key_fob_number,
    },

    resolver: zodResolver(schema),
  });

  const {
    isPending: isPendingPropertyPoolByPropertyId,
    isSuccess: isSuccessPropertyPoolByPropertyId,
    isError: isErrorPropertyPoolByPropertyId,
    data: fetchedPropertyPoolByPropertyId,
    error: errorPropertyPoolByPropertyId,
    refetch: refectPropertyPoolByPropertyId,
  } = usePropertyPoolByPropertyId(userContextProperty,permissionsObject.permissionsObject.create);

  useUpdateEffect(() => {
    if (isSuccessPropertyPoolByPropertyId) {
      setPropertyPools(fetchedPropertyPoolByPropertyId.data.property_pools);
      setPropertyPool(fetchedPropertyPoolByPropertyId.data.property_pools[0])
    }
  }, [fetchedPropertyPoolByPropertyId]);



  useUpdateEffect(() => {
    setCode(dailyActivityReportCodes[0]);
    setDetails("");
    setName("");
    setPoolAccessogDate(new Date());
    setNumberOfGuests(0);
    setKeyFobNumber("");
    setSelectedPool(null)
    //setSelectedHouseHold(null)
    //setHouseHolds([]);
    setHouseHold(houseHolds[0]);
  }, [visibleForm.visible]);

  const minDate = () => {
    if (userData[0].isAdmin || userData[0].isSupervisor) {
      return new Date("2022-01-01");
    } else {
      return new Date();
    }
  };

  const handleHide = () => {
    setVisibleForm({
      visible: false,
      itemId: null,
      title: null,
    });
  };

  const {
    mutateAsync: mutateInsertPoolAccessLog,
    data: addedPoolAccessLog,
    error: errorInsertPoolAccessLog,
    isError: isErrorInsertPoolAccessLog,
    isPending: isPendingInsertPoolAccessLog,
    isSuccess: isSuccessInsertPoolAccessLog,
  } = useInsertPoolAccessLog();

  const {
    mutateAsync: mutateUpdatePoolAccessLog,
    data: updatedPoolAccessLog,
    error: errorUpdatePoolAccessLog,
    isError: isErrorUpdatePoolAccessLog,
    isPending: isPendingUpdatePoolAccessLog,
    isSuccess: isSuccessUpdatePoolAccessLog,
  } = useUpdatePoolAccessLog();

  const {
    isPending: isPendingHouseHoldByPropertyId,
    isSuccess: isSuccessHouseHoldByPropertyId,
    isError: isErrorHouseHoldByPropertyId,
    data: fetchedHouseHoldByPropertyId,
    error: errorHouseHoldByPropertyId,
    refetch,
  } = useHouseHoldByPropertyId(userContextProperty,permissionsObject.permissionsObject.create);


  useUpdateEffect(() => {
    if (isSuccessHouseHoldByPropertyId) {
      setHouseHolds(fetchedHouseHoldByPropertyId.data.households);
      setHouseHold(fetchedHouseHoldByPropertyId.data.households[0]);
    }
  }, [fetchedHouseHoldByPropertyId]);

  useUpdateEffect(() => {
    if (isSuccessInsertPoolAccessLog || isSuccessUpdatePoolAccessLog) {
      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      if (visibleForm.itemId === null) {
        toast.success(
          `You have created DAR ID: ${addedPoolAccessLog?.data.data.incident_report_id}`,
          TOAST_CONFIG
        );
      } else {
        toast.success(
          `You have updaated DAR ID: ${updatedPoolAccessLog?.data.data.incident_report_id}`,
          TOAST_CONFIG
        );
      }
    }
  }, [addedPoolAccessLog, updatedPoolAccessLog]);

  function getCode(code_id) {
    try {
      return dailyActivityReportCodes.find(
        (element) => element.daily_activity_report_code_id == code_id
      );
    } catch (error) {
      console.log(error);
    }
  }

  function getType(type_id) {
    return poolAccessLogTypes.find(
      (element) => element.poolAccess_log_type_id == type_id
    );
  }

  const {
    isPending,
    isSuccess,
    isError,
    data: fetchedPoolAccessLog,
    error,
  } = usePoolAccessLog(visibleForm.itemId);

  useUpdateEffect(() => {
    if (isSuccess) {
      setCode(
        getCode(fetchedPoolAccessLog?.data.data.daily_activity_report_code_id)
      );

      setDetails(fetchedPoolAccessLog?.data.data.details);
      setNumberOfGuests(fetchedPoolAccessLog?.data.data.number_of_guests);
      setKeyFobNumber(fetchedPoolAccessLog?.data.data.key_fob_number);
      setName(fetchedPoolAccessLog?.data.data.name);
      setPoolAccessogDate(
        utcToLocale(fetchedPoolAccessLog?.data.data.pool_access_date)
      );
      setSendNotification(
        fetchedPoolAccessLog?.data.data.send_notification == 1 ? true : false
      );


      //setValue('images',fetchedPoolAccessLog?.data.data.thumb_images)
      setImagesUrls(fetchedPoolAccessLog?.data.data.thumb_images);
      setImagesUploaded(fetchedPoolAccessLog?.data.data.images);

      setVisibleLoadingModal({
        visible: false,
        itemId: null,
        title: null,
      });
    }
  }, [fetchedPoolAccessLog, isError]);

  useUpdateEffect(() => {
    if (isPending) {
      setVisibleLoadingModal({
        visible: true,
        itemId: null,
        title: null,
      });
    } else {
      setVisibleLoadingModal({
        visible: false,
        itemId: null,
        title: null,
      });
    }
  }, [isPending]);

  useUpdateEffect(() => {
    if (isErrorInsertPoolAccessLog) {
      console.log(isErrorInsertPoolAccessLog);
      console.log(errorInsertPoolAccessLog);

      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      toast.success(`You have created a DAR on this device.`, TOAST_CONFIG);
    }
  }, [isErrorInsertPoolAccessLog]);


  /*useUpdateEffect(() => {
    console.log(selectedHouseHold);
  }, [selectedHouseHold]);
*/
  const onSubmit = async (data) => {
    
    try {
      //await new Promise((resolve) => setTimeout(resolve, 1000));
      let json = {

        daily_activity_report_code_id: 1,
        pool_access_date: new Date().toISOString(),
        details: data.details,
        house_hold_id: data.houseHoldId.house_hold_id,
        property_pool_id: propertyPool.property_pool_id,
        number_of_guests: data.number_of_guests,
        key_fob_number: data.key_fob_number,
        name: data.name,
        property_id: userContextProperty,

        send_notification: false,
        images: images,
        images_urls: imagesUrls,
        images_del: deletedImages,
      };

      //console.log(data.pool_access_date);
      //return;

      let reponse;

      if (visibleForm.itemId === null) {
        reponse = await mutateInsertPoolAccessLog({
          json: json,
          persistOnStorage: true,
        });
      } else {
        json["pool_access_id"] = visibleForm.itemId;
        reponse = await mutateUpdatePoolAccessLog(json);
      }

      //console.log(reponse);
    } catch (error) {
      console.log(error);
      //setError("root", {message: error,});
    }
  };

  const searchOriginHouse = (e) => {
    const selectedOrigin = e.query;

    if (selectedOrigin && selectedOrigin.length >= 3) {
      setHouseHold(selectedOrigin);
    }
  };

  /*const handleSelectHouse = (e) => {
    console.log(e.value);
    setSelectedHouseHold(e.value);
  };*/

  return (
    <>
      <Sidebar
        visible={visibleForm.visible}
        position="right"
        onHide={() => handleHide()}
        className="w-full md:w-20rem lg:w-30rem"
      >
        {visibleLoadingModal.visible ? (
          <div className="flex flex-wrap justify-content-between gap-2 mb-1">
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ProgressSpinner
                aria-label="Loading"
                style={{ width: "50px", height: "100vm" }}
                strokeWidth="2"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />

              <p>Loading</p>
            </div>
          </div>
        ) : (
          <>
            <h2>{visibleForm.title}</h2>
            {propertyPools.length > 0 ? (
            <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
              <span className="p-float-label">
                <Controller
                  control={control}
                  name="propertyPoolId"
                  rules={{
                    validate: ({ value } = {}) =>
                      value !== undefined ||
                      "You must select a Pool",
                    valueAsNumber: true,
                  }}
                  render={({ field }) => (
                    <Dropdown
                      id={field.name}
                      filter
                      placeholder="Select a pool"
                      optionLabel="pool_name"
                      options={propertyPools}
                      {...field}
                      //optionValue={type}
                      //value = {field.value}
                    />
                  )}
                />
                <label htmlFor="type">Pool</label>
              </span>
              <small className="block pt-1">{errors.propertyPoolId && (
                <div className="text-red-500">{errors.propertyPoolId.message}</div>
              )}</small>

              </div>
         

              <div className="field">
    
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="houseHoldId"
                    rules={{
                      validate: ({ value } = {}) =>
                        value !== undefined ||
                        "You must select a household",
                      valueAsNumber: true,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        id={field.name}
                        filter
                        placeholder="Select a household"
                        optionLabel="label"
                        options={houseHolds}
                        {...field}
                        //optionValue={type}
                        //value = {field.value}
                      />
                    )}
                  />
                  <label htmlFor="type">Household</label>
                </span>
                <small className="block pt-1">{errors.houseHoldId && (
                  <div className="text-red-500">{errors.houseHoldId.message}</div>
                )}</small>

              </div>
          
                  

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: "Name is required." }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        value={field.value ?? ""}
                        autoFocus
                        maxLength={50}
      
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />

                  <label htmlFor="name">Name</label>
                  {errors.name && (
                    <div className="text-red-500">{errors.name.message}</div>
                  )}
                </span>
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name={"number_of_guests"}
                    control={control}
                    rules={{ required: "Number of guests is required.", min: 0, max: 100 }}
                    render={({ field, fieldState }) => (
                      <InputNumber id={field.name} ref={field.ref} 
                        value={field.value} onBlur={field.onBlur} 
                        onValueChange={(e) => field.onChange(e)} 
                        min={0} max={100} useGrouping={false} 
                        inputClassName={classNames({ 'p-invalid': fieldState.error })}/>
                    )}
                  />

                  <label htmlFor="number_of_guests">Number of guests</label>
                </span>
                <small className="block pt-1">{errors.number_of_guests && (
                  <div className="text-red-500">{errors.number_of_guests.message}</div>
                )}</small>
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="key_fob_number"
                    control={control}
                    rules={{ required: "Key fob number is required." }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        value={field.value ?? ""}
    
                        maxLength={50}
      
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />

                  <label htmlFor="key_fob_number">Key fob number</label>
                </span>
                <small className="block pt-1">{errors.key_fob_number && (
                  <div className="text-red-500">{errors.key_fob_number.message}</div>
                )}</small>
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="details"
                    control={control}
                    rules={{ required: "Details is required." }}
                    render={({ field, fieldState }) => (
                      <InputTextarea
                        id={field.name}
                        {...field}
                        value={field.value ?? ""}
                        
                        maxLength={500}
                        rows={5} cols={30}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />

                  <label htmlFor="details">Details <small>(max 500 characters)</small></label>
                </span>
                <small className="block pt-1">{errors.details && (
                  <div className="text-red-500">{errors.details.message}</div>
                )}</small>
              </div>      

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="create_automatic_dar"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <InputSwitch 
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange} // send value to hook form
                        checked={value}
                        inputRef={ref}
                      />
                    )}
                  />

                  <label htmlFor="create_automatic_dar" className="ml-2">
                    &nbsp; &nbsp; &nbsp; &nbsp; Create an automatic DAR
                  </label>
                </span>
              </div>    

              <Button
                disabled={isSubmitting}
                type="submit"
                label={isSubmitting ? "Loading..." : "Submit"}
                className="mt-2"
              />
               
              {errors.root && (
                <div className="text-red-500">{errors.root.message}</div>
              )}
            </form>
            ) : "There are no pools for this property"}
          </>
        )}
      </Sidebar>
    </>
  );
};

export default SideBar;
