export default function swDev(){

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          for (const registration of registrations) {
            // unregister service worker
            console.log('serviceWorker unregistered');
            registration.unregister();
          }
        });
        /*caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
            console.warn('cache deleted')
        });*/
    }

    let swURL = `${process.env.PUBLIC_URL}/sw.js`
    navigator.serviceWorker.register(swURL).then((Response) =>{
        console.warn("response",Response)
    })
}