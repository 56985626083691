import axios from "./axios";
import  secureLocalStorage  from  "react-secure-storage";

export async function fetchLogin(user_name, password, gps_coordinates) {
  let url = "auth/login";

  try {
    const response = await axios.post(
      url,
      JSON.stringify( user_name, password, gps_coordinates ),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    throw error;
  }
}

export async function reset(token, password,password_confirm) {
  let url = "auth/reset";

  try {
    const response = await axios.post(
      url,
      JSON.stringify( token, password,password_confirm ),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    throw error;
  }
}

export async function forgot(email) {
  let url = "auth/forgot";

  try {
    const response = await axios.post(
      url,
      JSON.stringify(email),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    throw error;
  }
}


export async function fetchLogout() {
  let url = "auth/logout";
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"))
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    throw error;
  }
}


