import React,{ useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import nProgress from "nprogress";
import { useAuth } from "../../context/auth";
import { usePosition } from "../../context/position";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { z } from "zod";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { classNames } from "primereact/utils";
import { Divider } from "primereact/divider";

import secureLocalStorage from "react-secure-storage";

import { useLogin } from "../../hooks/loginQueries";

import { LOGO } from "../../utils/Constansts";

const schema = z.object({
  userName: z.string().min(6),
  password: z.string().min(4),
  rememberMe: z.boolean(),
});

const Login = () => {
  const positionContext = usePosition();

    const {
      mutateAsync: mutateLogin,
      isPending: isPendingLogin,
      isSuccess: isSuccessLogin,
      isError: isErrorLogin,
      data: dataLogin,
      error: errorLogin,
    } = useLogin();


    useEffect(() => {
      if(isSuccessLogin){

        const finalUser = {
          user: dataLogin?.data.user,
          isAdmin: dataLogin?.data.user.roles[0].name === "Admin",
          isSupervisor: dataLogin?.data.user.roles[0].name === "Supervisor",
          isPostCommander:
            dataLogin?.data.user.roles[0].name === "Post Commander",
          isPropertyManager:
            dataLogin?.data.user.roles[0].name === "Property Manager",
          isCallCenter: dataLogin?.data.user.roles[0].name === "Call Center",
          isOfficer: dataLogin?.data.user.roles[0].name === "Officer",
          isResident: dataLogin?.data.user.roles[0].name === "Resident",
          isBoardOfDirectors:
            dataLogin?.data.user.roles[0].name === "Board of Directors",
        };

        secureLocalStorage.setItem(
          "accessToken",
          JSON.stringify(dataLogin?.data.access_token)
        );
        secureLocalStorage.setItem("userData", JSON.stringify([finalUser]));
        secureLocalStorage.setItem(
          "userProperties",
          JSON.stringify(dataLogin?.data.properties)
        );
        secureLocalStorage.setItem(
          "userContextProperty",
          dataLogin?.data.properties[0].property_id
        );
        secureLocalStorage.setItem(
          "userContextPropertyData",
          JSON.stringify(dataLogin?.data.properties[0])
        );

        secureLocalStorage.setItem(
          "userContextPropertyCitationTypes",
          JSON.stringify(
            dataLogin?.data.properties[0]["property_citation_types"]
          )
        );

        navigate('/loading');
      }
  
      
      
   
      //navigate('/login');
      
  
    }, [isSuccessLogin]);

    useEffect(() => {
    console.log(errorLogin)
  }, [errorLogin]);

  const { login } = useAuth();

  const navigate = useNavigate();

  const [loginError,setLoginError] = useState("");
  const [isLoginError,setIsLoginError] = useState(false);

  const passwordHeader = <h6>Pick a password</h6>;
  const passwordFooter = (
    <React.Fragment>
      <Divider />
      <p className="mt-2">Suggestions</p>
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>Minimum 8 characters</li>
      </ul>
    </React.Fragment>
  );

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setError,
  } = useForm({
    defaultValues: {
      userName: "",
      password: "",
      rememberMe: false,
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const {
        position: { latitude, longitude },
      } = positionContext;

      let user_name = data.userName;
      let password = data.password;
      let remember_me = data.rememberMe;
      let gps_coordinates = latitude + "," + longitude;

      const loginData = {
        user_name,
        password,
        remember_me,
        gps_coordinates
      };

      
      await mutateLogin(loginData);
      
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit_OLD = async (data) => {
    try {
      nProgress.start();
      console.log(positionContext.position.latitude);
      const { loginFailed, error } = await login(
        data.userName,
        data.password,
        data.rememberMe,
        positionContext.position.latitude,
        positionContext.position.longitude
      );

      if (!loginFailed) {
        navigate("/loading");
      } else {
        if(error.status === 401){
          setLoginError("Please check your login data")
        }else{
          setLoginError(error.response.data.message)
        }
        
        setIsLoginError(true)
        
        
      }
    } catch (error) {

      setError("root", {
        message: error,
      });
    }
  };

  const handleForgot = () => {
    window.location.href = "#/forgot"
  }

  return (
    <>
      <section className="">
        <div className="flex justify-content-center align-items-center flex-order-6 py-7  h-screen">
          <div className="card px-8 pb-7 pt-4 text-center mb-5">
            <img src={LOGO} alt="Image" height="100" />
            <h2 className="text-center">Login</h2>

            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="userName"
                    control={control}
                    rules={{ required: "Username is required." }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        autoFocus
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        {...field}
                      />
                    )}
                  />
                  <label
                    htmlFor="userName"
                    className={classNames({ "p-error": errors.userName })}
                  >
                    User Name *
                  </label>
                </span>
              </div>
              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: "Password is required." }}
                    render={({ field, fieldState }) => (
                      <Password
                        id={field.name}
                        {...field}
                        toggleMask
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        header={passwordHeader}
                        footer={passwordFooter}
                      />
                    )}
                  />
                  <label
                    htmlFor="password"
                    className={classNames({ "p-error": errors.password })}
                  >
                    Password *
                  </label>
                </span>
                {errors.password && (
                  <div className="text-red-500">{errors.password.message}</div>
                )}
              </div>

              <div className="field-checkbox">
                <Controller
                  name="rememberMe"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <Checkbox
                      inputId={field.name}
                      onChange={(e) => field.onChange(e.checked)}
                      checked={field.value}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="accept"
                  className={classNames({ "p-error": errors.accept })}
                >
                  Remember Me*
                </label>
              </div>
              <div className="field text-left">
                <a className="font-medium no-underline  text-blue-500  cursor-pointer" onClick={handleForgot}>Forgot your password?</a>
              </div>
              
              <Button
                disabled={isSubmitting}
                type="submit"
                label={isSubmitting ? "Loading..." : "Submit"}
                className="mt-2"
              />
            </form>
            <br />
            {errors.root && (
              <div className="text-red-500">{errors.root.message}</div>
            )}

            {isErrorLogin ? (
              <div className="text-red-500 text-left" style={{containerType:"inline-size"}}>{errorLogin.response.data.message}</div>
            ) : null}
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
