import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { fetchStats } from "../api/dashboardApi";
  
import { useLocalState } from "./useLocalState";

let accessToken = JSON.parse(localStorage.getItem("accessToken")) || null


export function useStats(propertyId) {
  return useQuery({
    queryKey: ["stats", { propertyId }],
    queryFn: () => fetchStats(propertyId),
    retry: false,
    //staleTime: 10,
    refetchInterval:400000,
    
    onSuccess: (data) => { return data},
    throwOnError: (error) => {
      console.log("ALL BAD")
      //return error
      //console.error(error.message)
    }

  });
}

