import { useContext, useState, useEffect } from "react";
import { useUpdateEffect } from "primereact/hooks";
import { useUnmountEffect } from "primereact/hooks";
import { usePosition } from "../../context/position";
import VisibleContext from "../../context/VisibleContext";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";
import { Controller } from "react-hook-form";
import { z } from "zod";

import { useLocalState } from "../../hooks/useLocalState";
import secureLocalStorage from "react-secure-storage";

import { Sidebar } from "primereact/sidebar";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from "primereact/button";
import { InputSwitch } from 'primereact/inputswitch'; 
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { ProgressSpinner } from "primereact/progressspinner";
import { AutoComplete } from "primereact/autocomplete";


import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";
import { v4 as uuidv4 } from "uuid";

import { utcToLocale } from "../../helpers/helpers";

import {
  useDispatchLog,
  useInsertDispatchLog,
  useUpdateDispatchLog,
} from "../../hooks/dispatchLogQueries";

import { useHouseHoldByPropertyId } from "../../hooks/houseHoldQueries";

import { convertBase64 } from "../../helpers/helpers";

const SideBar = (permissionsObject) => {
  const [userData] = useLocalState("", "userData");
  let userContextProperty = secureLocalStorage.getItem("userContextProperty");
  const [dispatchLogTypes] = useLocalState("", "dispatchLogTypes");
  //const dailyActivityReportTypes = JSON.parse(secureLocalStorage.getItem("dailyActivityReportTypes"));

  const [dailyActivityReportCodes] = useLocalState("","dailyActivityReportCodes");
  //const dailyActivityReportCodes = JSON.parse(secureLocalStorage.getItem("dailyActivityReportCodes"));

  const positionContext = usePosition();
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  const { VisibleFormContext, visibleLoadingModalContext } =
    useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleLoadingModal, setVisibleLoadingModal] =
    visibleLoadingModalContext;


  const [type, setType] = useState(dispatchLogTypes[0]);
  const [code, setCode] = useState(dailyActivityReportCodes[2]);
  const [dispatch_log_date, setDispatchLogDate] = useState(new Date());
  //const [dispatch_log_date, setDispatchLogDate] = useState("");
  const [details, setDetails] = useState("");
  const [name, setName] = useState("");
  const [send_notification, setSendNotification] = useState(false);
  const [create_automatic_dar, setCreateAutomaticDar] = useState(false);
  const [imagesUploaded, setImagesUploaded] = useState([]);
  const [images, setImages] = useState([]);
  const [imagesUrls, setImagesUrls] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);



  const [houseHolds, setHouseHolds] = useState([]);
  const [houseHold, setHouseHold] = useState("");

  const schema = z.object({
    houseHoldId: z.object({
      label: z.string(),
      house_hold_id: z.number(),
    }),
    dispatchLogTypeId: z.object({
      type: z.string(),
      dispatch_log_type_id: z.number(),
    }),
    /*dailyActivityReportCodeId: z.object({
      type: z.string(),
      daily_activity_report_code_id: z.number(),
    }),*/
    dispatch_log_date: z.date(),
    details: z.string().min(1).max(500),
    name: z.string().min(1).max(50),
    create_automatic_dar: z.boolean(),
    send_notification: z.boolean(),
    images: z.any(),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
    setError,
    setValue,
    form,
  } = useForm({
    //shouldUnregister: true,

    /*defaultValues: {
      //dailyActivityReportTypeId: type,
      dailyActivityReportCodeId: dailyActivityReportCodes[2],

      details: "d",
      dispatch_log_date: new Date(),
      send_notification: false,
      include_gps_coordinates: false,
    },*/
    values: {
      houseHoldId: houseHold,
      dispatchLogTypeId: type,  
      dailyActivityReportCodeId: code,
      details: details,
      name: name,
      dispatch_log_date: dispatch_log_date,
      send_notification: send_notification,
      create_automatic_dar: create_automatic_dar,
    },

    resolver: zodResolver(schema),
  });

  useUpdateEffect(() => {
    setType(dispatchLogTypes[0]);
    setCode(dailyActivityReportCodes[0]);
    setDetails("");
    setName("");
    setDispatchLogDate(new Date());

    setHouseHold(houseHolds[0]);
    //setHouseHolds([]);
  

  }, [visibleForm.visible]);

  const minDate = () => {
    if (userData[0].isAdmin || userData[0].isSupervisor) {
      return new Date("2022-01-01");
    } else {
      return new Date();
    }
  };

  const handleHide = () => {
    setVisibleForm({
      visible: false,
      itemId: null,
      title: null,
    });
  };

  const {
    mutateAsync: mutateInsertDispatchLog,
    data: addedDispatchLog,
    error: errorInsertDispatchLog,
    isError: isErrorInsertDispatchLog,
    isPending: isPendingInsertDispatchLog,
    isSuccess: isSuccessInsertDispatchLog,
  } = useInsertDispatchLog();

  const {
    mutateAsync: mutateUpdateDispatchLog,
    data: updatedDispatchLog,
    error: errorUpdateDispatchLog,
    isError: isErrorUpdateDispatchLog,
    isPending: isPendingUpdateDispatchLog,
    isSuccess: isSuccessUpdateDispatchLog,
  } = useUpdateDispatchLog();

  const {
    isPending: isPendingHouseHoldByPropertyId,
    isSuccess: isSuccessHouseHoldByPropertyId,
    isError: isErrorHouseHoldByPropertyId,
    data: fetchedHouseHoldByPropertyId,
    error: errorHouseHoldByPropertyId,
    refetch,
  } = useHouseHoldByPropertyId(userContextProperty,permissionsObject.permissionsObject.create);

  useUpdateEffect(() => {
    if (isSuccessHouseHoldByPropertyId) {
      setHouseHold(fetchedHouseHoldByPropertyId.data.households[0]);
      setHouseHolds(fetchedHouseHoldByPropertyId.data.households);
    }
  }, [fetchedHouseHoldByPropertyId]);

  useUpdateEffect(() => {
    if (isSuccessInsertDispatchLog || isSuccessUpdateDispatchLog) {
      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      if (visibleForm.itemId === null) {
        toast.success(
          `You have created DAR ID: ${addedDispatchLog?.data.data.incident_report_id}`,
          TOAST_CONFIG
        );
      } else {
        toast.success(
          `You have updaated DAR ID: ${updatedDispatchLog?.data.data.incident_report_id}`,
          TOAST_CONFIG
        );
      }
    }
  }, [addedDispatchLog, updatedDispatchLog]);

  function getCode(code_id) {
    try {
      return dailyActivityReportCodes.find(
        (element) => element.daily_activity_report_code_id == code_id
      );
    } catch (error) {
      console.log(error);
    }
  }

  function getType(type_id) {
    return dispatchLogTypes?.find(
      (element) => element.dispatch_log_type_id == type_id
    );
  }

  const {
    isPending,
    isSuccess,
    isError,
    data: fetchedDispatchLog,
    error,
  } = useDispatchLog(visibleForm.itemId);

  useUpdateEffect(() => {
    if (isSuccess) {
      setCode(
        fetchedDispatchLog?.data.data.daily_activity_report_code_id
      );
      setType(
        fetchedDispatchLog?.data.data.dispatch_log_type_id
      );
      setDetails(fetchedDispatchLog?.data.data.details);
      setName(fetchedDispatchLog?.data.data.name);
      setDispatchLogDate(
        utcToLocale(fetchedDispatchLog?.data.data.dispatch_log_date)
      );
      setSendNotification(
        fetchedDispatchLog?.data.data.send_notification == 1 ? true : false
      );

      setCreateAutomaticDar(
        fetchedDispatchLog?.data.data.create_automatic_dar == 1 ? true : false
      );

      //setValue('images',fetchedDispatchLog?.data.data.thumb_images)
      setImagesUrls(fetchedDispatchLog?.data.data.thumb_images);
      setImagesUploaded(fetchedDispatchLog?.data.data.images);

      setVisibleLoadingModal({
        visible: false,
        itemId: null,
        title: null,
      });
    }
  }, [fetchedDispatchLog, isError]);

  useUpdateEffect(() => {
    if (isPending) {
      setVisibleLoadingModal({
        visible: true,
        itemId: null,
        title: null,
      });
    } else {
      setVisibleLoadingModal({
        visible: false,
        itemId: null,
        title: null,
      });
    }
  }, [isPending]);

  useUpdateEffect(() => {
    if (isErrorInsertDispatchLog) {
      console.log(isErrorInsertDispatchLog);
      console.log(errorInsertDispatchLog);

      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      toast.success(`You have created a DAR on this device.`, TOAST_CONFIG);
    }
  }, [isErrorInsertDispatchLog]);


  const onSubmit = async (data) => {
    try {
      //await new Promise((resolve) => setTimeout(resolve, 1000));
      let json = {
        dispatch_log_type_id: data.dispatchLogTypeId.dispatch_log_type_id,
        daily_activity_report_code_id: 1,
        dispatch_log_date: new Date().toISOString(),
        details: data.details,
        create_automatic_dar: data.create_automatic_dar,
        house_hold_id: data.houseHoldId.house_hold_id,
        name: data.name,
        property_id: userContextProperty,

        send_notification: false,
        images: images,
        images_urls: imagesUrls,
        images_del: deletedImages,
      };

      //console.log(data.dispatch_log_date);
      //return;

      let reponse;

      if (visibleForm.itemId === null) {
        reponse = await mutateInsertDispatchLog({
          json: json,
          persistOnStorage: true,
        });
      } else {
        json["incident_report_id"] = visibleForm.itemId;
        reponse = await mutateUpdateDispatchLog(json);
      }

      //console.log(reponse);
    } catch (error) {
      console.log(error);
      //setError("root", {message: error,});
    }
  };

  const searchOrigin = (e) => {
    const selectedOrigin = e.query;

    if (selectedOrigin && selectedOrigin.length >= 3) {
      setHouseHold(selectedOrigin);
    }
  };


  return (
    <>
      <Sidebar
        visible={visibleForm.visible}
        position="right"
        onHide={() => handleHide()}
        className="w-full md:w-20rem lg:w-30rem"
      >
        {visibleLoadingModal.visible ? (
          <div className="flex flex-wrap justify-content-between gap-2 mb-1">
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ProgressSpinner
                aria-label="Loading"
                style={{ width: "50px", height: "100vm" }}
                strokeWidth="2"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />

              <p>Loading</p>
            </div>
          </div>
        ) : (
          <>
            <h2>{visibleForm.title}</h2>
            <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
    
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="houseHoldId"
                    rules={{
                      validate: ({ value } = {}) =>
                        value !== undefined ||
                        "You must select a household",
                      valueAsNumber: true,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        id={field.name}
                        filter
                        placeholder="Select a household"
                        optionLabel="label"
                        options={houseHolds}
                        {...field}
                        //optionValue={type}
                        //value = {field.value}
                      />
                    )}
                  />
                  <label htmlFor="type">Household</label>
                </span>
                <small className="block pt-1">{errors.houseHoldId && (
                  <div className="text-red-500">{errors.houseHoldId.message}</div>
                )}</small>

              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="dispatchLogTypeId"
                    rules={{
                      validate: ({ value } = {}) =>
                        value !== undefined ||
                        "You must select a Incident type",
                      valueAsNumber: true,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        id={field.name}
                        filter
                        placeholder="Select a type"
                        optionLabel="type"
                        options={dispatchLogTypes}
                        {...field}
                        //optionValue={type}
                        //value = {field.value}
                      />
                    )}
                  />
                  <label htmlFor="type">Type</label>
                </span>
                <small className="block pt-1">{errors.dailyActivityReportTypeId && (
                  <div className="text-red-500">{errors.dailyActivityReportTypeId.message}</div>
                )}</small>     
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: "Name is required." }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        value={field.value ?? ""}
                        autoFocus
                        maxLength={50}
      
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />

                  <label htmlFor="name">Name</label>
                </span>
                <small className="block pt-1">{errors.name && (
                  <div className="text-red-500">{errors.name.message}</div>
                )}</small>
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="details"
                    control={control}
                    rules={{ required: "Details is required." }}
                    render={({ field, fieldState }) => (
                      <InputTextarea
                        id={field.name}
                        {...field}
                        value={field.value ?? ""}
                        
                        maxLength={500}
                        rows={5} cols={30}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />

                  <label htmlFor="details">Details <small>(max 500 characters)</small></label>
                </span>
                <small className="block pt-1">{errors.details && (
                  <div className="text-red-500">{errors.details.message}</div>
                )}</small>
              </div>      

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="create_automatic_dar"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <InputSwitch 
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange} // send value to hook form
                        checked={value}
                        inputRef={ref}
                      />
                    )}
                  />

                  <label htmlFor="create_automatic_dar" className="ml-2">
                    &nbsp; &nbsp; &nbsp; &nbsp; Create an automatic DAR
                  </label>
                </span>
              </div>    

              <Button
                disabled={isSubmitting}
                type="submit"
                label={isSubmitting ? "Loading..." : "Submit"}
                className="mt-2"
              />
                
              {errors.root && (
                <div className="text-red-500">{errors.root.message}</div>
              )}
            </form>
          </>
        )}
      </Sidebar>
    </>
  );
};

export default SideBar;
