import React, { createContext } from "react";
import secureLocalStorage from "react-secure-storage";

const PropertyContext = createContext({});
PropertyContext.displayName = "PropertyContext";

export const PropertyProvider = ({ children }) => {
  const [property, setProperty] = React.useState(() => {
    const p = secureLocalStorage.getItem("userContextProperty");
    if (p) return Number(p);
    return null;
  });

  const handleChangedProperty = (propertyId) => {
    secureLocalStorage.setItem(
      "userContextProperty",
      JSON.stringify(propertyId)
    );
    setProperty(propertyId);
  };

  return (
    <PropertyContext.Provider value={[property, handleChangedProperty]}>
      {children}
    </PropertyContext.Provider>
  );
};

const useProperty = () => {
  const context = React.useContext(PropertyContext);
  if (context === undefined) {
    throw Error("useProperty must be used within an PropertyProvider");
  }
  return context;
};

export { useProperty };
export default PropertyProvider;
