import { useContext, useState, useEffect } from "react";
import { useUpdateEffect } from "primereact/hooks";
import { useUnmountEffect } from "primereact/hooks";
import { usePosition } from "../../context/position";
import VisibleContext from "../../context/VisibleContext";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";
import { Controller } from "react-hook-form";
import { z } from "zod";

import { useLocalState } from "../../hooks/useLocalState";
import secureLocalStorage from "react-secure-storage";

import { Sidebar } from "primereact/sidebar";

import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { ProgressSpinner } from "primereact/progressspinner";
import { TabView, TabPanel } from 'primereact/tabview';
        


import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";

import { utcToLocale } from "../../helpers/helpers";

import {
  useVehicleMaintenance,
  useInsertVehicleMaintenance,
  useUpdateVehicleMaintenance,
  useVehicleMaintenancTypes
} from "../../hooks/vehicleMaintenanceQueries";


const SideBar = (permissionsObject) => {

  const [userData] = useLocalState("", "userData");
  const [roles] = userData[0].user.roles;
  const permissions = roles?.permissions || null;

  let userContextProperty = secureLocalStorage.getItem("userContextProperty");

  const { VisibleFormContext, visibleLoadingModalContext } = useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleLoadingModal, setVisibleLoadingModal] = visibleLoadingModalContext;


  const [vehicle_maintenance_date, setVehicleMaintenanceDate] = useState(new Date());
  //const [vehicle_maintenance_date, setVehicleMaintenanceDate] = useState("");
  const [starting_details, setStartingDetails] = useState("");
  const [ending_details, setEndingDetails] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [starting_gas, setStatusGas] = useState("");

  const [statusGasTypes,setStatusGasTypes] = useState([]);
  const [startingStatusGasType, setStartingStatusGasType] = useState(statusGasTypes[0]);
  const [endingStatusGasType, setEndingStatusGasType] = useState(statusGasTypes[0]);

  const [starting_mileage, setStartingMileage] = useState(0);
  const [ending_mileage, setEndingMileage] = useState(0);
  const [issues_inside, setIssuesInside] = useState("");
  const [issues_outside, setIssuesOutside] = useState("");
  const [imagesUploaded, setImagesUploaded] = useState([]);
  const [images, setImages] = useState([]);
  const [imagesUrls, setImagesUrls] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);

  const [activeIndex, setActiveIndex] = useState(0);

  const [schema,setSchema] = useState()

  const schemaNew = z.object({
    StartingVehicleMaintenanceStatusGasTypeId: z.object({
      type: z.string(),
      vehicle_maintenance_status_gas_type_id: z.number(),
    }),
    vehicle_maintenance_date: z.date(),
    vehicle: z.string().min(1),
    starting_mileage: z.number(),
    issues_inside: z.string().max(500),
    issues_outside: z.string().max(500),
    starting_details: z.string().min(1).max(500),
    images: z.any(),
  });

  const schemaUpt = z.object({
    EndingVehicleMaintenanceStatusGasTypeId: z.object({
      type: z.string(),
      vehicle_maintenance_status_gas_type_id: z.number(),
    }),
    ending_mileage: z.number(),
    ending_details: z.string().max(500),
  });

  


  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
    setError,
    setValue,
    form,
  } = useForm({
    //shouldUnregister: true,

    /*defaultValues: {
      //dailyActivityReportTypeId: type,
      dailyActivityReportCodeId: dailyActivityReportCodes[2],

      details: "d",
      vehicle_maintenance_date: new Date(),
      send_notification: false,
      include_gps_coordinates: false,
    },*/
    values: {
      vehicle: vehicle,
      //starting_gas: starting_gas,
      StartingVehicleMaintenanceStatusGasTypeId: startingStatusGasType,
      EndingVehicleMaintenanceStatusGasTypeId: endingStatusGasType,
      starting_mileage: starting_mileage,
      ending_mileage: starting_mileage,
      issues_inside: issues_inside,
      issues_outside: issues_outside,
      starting_details: starting_details,
      ending_details: starting_details,
      vehicle_maintenance_date: vehicle_maintenance_date,
    },

    resolver: zodResolver(schema),
  }); 

  const {
    isPending: isPendingVehicleMaintenanceType,
    isSuccess: isSuccessVehicleMaintenanceType,
    isError: isErrorVehicleMaintenanceType,
    data: fetchedVehicleMaintenanceType,
    error: errorVehicleMaintenanceType,
    refetch: refectVehicleMaintenanceType,
  } = useVehicleMaintenancTypes(permissionsObject.permissionsObject.create);

  useUpdateEffect(() => {
    if (isSuccessVehicleMaintenanceType) {
      setStatusGasTypes(fetchedVehicleMaintenanceType.data.vehicle_maintenance_status_gas_types);
      setStartingStatusGasType(fetchedVehicleMaintenanceType.data.vehicle_maintenance_status_gas_types[0])
      setEndingStatusGasType(fetchedVehicleMaintenanceType.data.vehicle_maintenance_status_gas_types[0])
    }
  }, [fetchedVehicleMaintenanceType]);

  useUpdateEffect(() => {
    setVehicle("");
    setStartingMileage(0);
    setEndingMileage("");
    setIssuesInside("");
    setIssuesOutside("");
    setStartingDetails("");
    setEndingDetails("");
    setVehicleMaintenanceDate(new Date());   
    setActiveIndex(0)

    setSchema(schemaNew)
  }, [visibleForm.visible]);

  const minDate = () => {
    if (userData[0].isAdmin || userData[0].isSupervisor) {
      return new Date("2022-01-01");
    } else {
      return new Date();
    }
  };

  const handleHide = () => {
    setVisibleForm({
      visible: false,
      itemId: null,
      title: null,
    });
  };

  const {
    mutateAsync: mutateInsertVehicleMaintenance,
    data: addedVehicleMaintenance,
    error: errorInsertVehicleMaintenance,
    isError: isErrorInsertVehicleMaintenance,
    isPending: isPendingInsertVehicleMaintenance,
    isSuccess: isSuccessInsertVehicleMaintenance,
  } = useInsertVehicleMaintenance();

  const {
    mutateAsync: mutateUpdateVehicleMaintenance,
    data: updatedVehicleMaintenance,
    error: errorUpdateVehicleMaintenance,
    isError: isErrorUpdateVehicleMaintenance,
    isPending: isPendingUpdateVehicleMaintenance,
    isSuccess: isSuccessUpdateVehicleMaintenance,
  } = useUpdateVehicleMaintenance();


  useUpdateEffect(() => {
    if (isSuccessInsertVehicleMaintenance || isSuccessUpdateVehicleMaintenance) {
      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      if (visibleForm.itemId === null) {
        toast.success(
          `You have created Vehicle Maintenance ID: ${addedVehicleMaintenance?.data.data.vehicle_maintenance_id}`,
          TOAST_CONFIG
        );
      } else {
        toast.success(
          `You have updaated Vehicle Maintenance ID: ${updatedVehicleMaintenance?.data.data.vehicle_maintenance_id}`,
          TOAST_CONFIG
        );
      }
    }
  }, [addedVehicleMaintenance, updatedVehicleMaintenance]);


  const {
    isPending,
    isSuccess,
    isError,
    data: fetchedVehicleMaintenance,
    error,
  } = useVehicleMaintenance(visibleForm.itemId);

  function getType(type_id) {
    return statusGasTypes.find(
      (element) => element.vehicle_maintenance_status_gas_type_id == type_id
    );
  }

  useUpdateEffect(() => {
    if (isSuccess) {
      //setVehicle(fetchedVehicleMaintenance?.data.data.vehicle);
      //setStartingStatusGasType(getType(fetchedVehicleMaintenance?.data.data.starting_vehicle_maintenance_status_gas_type_id));
      //setEndingStatusGasType(getType(fetchedVehicleMaintenance?.data.data.ending_vehicle_maintenance_status_gas_type_id));
      setStartingMileage(fetchedVehicleMaintenance?.data.data.starting_mileage);
      setEndingMileage(fetchedVehicleMaintenance?.data.data.ending_mileage);
      //setIssuesInside(fetchedVehicleMaintenance?.data.data.issues_inside);
      //setIssuesOutside(fetchedVehicleMaintenance?.data.data.issues_outside);
      //setStartingDetails(fetchedVehicleMaintenance?.data.data.starting_details);
      setEndingDetails(fetchedVehicleMaintenance?.data.data.ending_details);
      //setVehicleMaintenanceDate(utcToLocale(fetchedVehicleMaintenance?.data.data.vehicle_maintenance_date));

      //setValue('images',fetchedVehicleMaintenance?.data.data.thumb_images)
      //setImagesUrls(fetchedVehicleMaintenance?.data.data.thumb_images);
      //setImagesUploaded(fetchedVehicleMaintenance?.data.data.images);

      setSchema(schemaUpt)
      setActiveIndex(1)

      setVisibleLoadingModal({
        visible: false,
        itemId: null,
        title: null,
      });
    }
  }, [fetchedVehicleMaintenance, isError]);

  useUpdateEffect(() => {
    if (isPending) {
      setVisibleLoadingModal({
        visible: true,
        itemId: null,
        title: null,
      });
    } else {
      setVisibleLoadingModal({
        visible: false,
        itemId: null,
        title: null,
      });
    }
  }, [isPending]);

  useUpdateEffect(() => {
    if (isErrorInsertVehicleMaintenance) {
      console.log(isErrorInsertVehicleMaintenance);
      console.log(errorInsertVehicleMaintenance);

      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      toast.success(`You have created a DAR on this device.`, TOAST_CONFIG);
    }
  }, [isErrorInsertVehicleMaintenance]);


  const onSubmit = async (data) => {
    
    try {

      

      let reponse;

      if (visibleForm.itemId === null) {
        let json = {
          vehicle_maintenance_date: new Date().toISOString(),
          vehicle: data.vehicle,
          //starting_gas: data.starting_gas,
          starting_vehicle_maintenance_status_gas_type_id: data.StartingVehicleMaintenanceStatusGasTypeId.vehicle_maintenance_status_gas_type_id,
          //ending_vehicle_maintenance_status_gas_type_id: data.EndingVehicleMaintenanceStatusGasTypeId.vehicle_maintenance_status_gas_type_id,
          starting_mileage: data.starting_mileage,
          issues_inside: data.issues_inside,
          issues_outside: data.issues_outside,
          starting_details: data.starting_details,
          
          property_id: userContextProperty,
          images: images,
          images_urls: imagesUrls,
          images_del: deletedImages,
        };

        reponse = await mutateInsertVehicleMaintenance({
          json: json,
          persistOnStorage: true,
        });
      } else {
        let json = {
          vehicle_maintenance_id: visibleForm.itemId,
          ending_vehicle_maintenance_status_gas_type_id: data.EndingVehicleMaintenanceStatusGasTypeId.vehicle_maintenance_status_gas_type_id,
          ending_details: data.ending_details,
          ending_mileage: data.ending_mileage,
          property_id: userContextProperty,
        };

        /*json["vehicle_maintenance_id"] = visibleForm.itemId;
        json["ending_vehicle_maintenance_status_gas_type_id"] = data.EndingVehicleMaintenanceStatusGasTypeId.vehicle_maintenance_status_gas_type_id;
        json["ending_details"] = data.ending_details;
        json["ending_mileage"] = data.ending_mileage;*/

        //console.log(json)
        //return

        reponse = await mutateUpdateVehicleMaintenance(json);
      }

      //console.log(reponse);
    } catch (error) {
      console.log(error);
      //setError("root", {message: error,});
    }
  };


  return (
    <>
      <Sidebar
        visible={visibleForm.visible}
        position="right"
        onHide={() => handleHide()}
        className="w-full md:w-20rem lg:w-30rem"
      >
        {visibleLoadingModal.visible ? (
          <div className="flex flex-wrap justify-content-between gap-2 mb-1">
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ProgressSpinner
                aria-label="Loading"
                style={{ width: "50px", height: "100vm" }}
                strokeWidth="2"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />

              <p>Loading</p>
            </div>
          </div>
        ) : (
          <>
            <h2>{visibleForm.title}</h2>
            
            <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Starting Shift" disabled={visibleForm.itemId ? true : false}>
                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        name="vehicle"
                        control={control}
                        rules={{ required: "Vehicle is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            value={field.value ?? ""}
                            autoFocus
                            maxLength={50}
          
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      <label htmlFor="vehicle">Vehicle</label>
                    </span>
                    <small className="block pt-1">{errors.vehicle && (
                        <div className="text-red-500">{errors.vehicle.message}</div>
                      )}</small>
                  </div>

                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="StartingVehicleMaintenanceStatusGasTypeId"
                        rules={{
                          validate: ({ value } = {}) =>
                            value !== undefined ||
                            "You must select a type",
                          valueAsNumber: true,
                        }}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            filter
                            placeholder="Select a type"
                            optionLabel="type"
                            options={statusGasTypes}
                            {...field}
                            //optionValue={type}
                            //value = {field.value}
                          />
                        )}
                      />
                      {errors.StartingVehicleMaintenanceStatusGasTypeId && (
                        <div className="text-red-500">
                          {errors.StartingVehicleMaintenanceStatusGasTypeId.message}
                        </div>
                      )}

                      <label htmlFor="type">Starting Gas Status</label>
                    </span>

                  </div>
                  

                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        name={"starting_mileage"}
                        control={control}
                        rules={{ required: "Starting mileage is required.", min: 0 }}
                        render={({ field, fieldState }) => (
                          
                          <InputNumber id={field.name} ref={field.ref} 
                            value={field.value} onBlur={field.onBlur} 
                            onValueChange={(e) => field.onChange(e)} 
                            min={0} useGrouping={false} 
                            inputClassName={classNames({ 'p-invalid': fieldState.error })} suffix=" mi"/>
                        )}
                      />

                      <label htmlFor="starting_mileage">Starting mileage</label>
                      
                    </span>
                    <small className="block pt-1">{errors.starting_mileage && (
                        <div className="text-red-500">{errors.starting_mileage.message}</div>
                      )}</small>
                  </div>

                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        name="issues_inside"
                        control={control}
                        rules={{ required: "Issues Inside is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            value={field.value ?? ""} 
                            maxLength={50}
          
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      <label htmlFor="issues_inside">Issues Inside</label>
                    </span>
                    <small className="block pt-1">{errors.issues_inside && (
                        <div className="text-red-500">{errors.issues_inside.message}</div>
                      )}</small>
                  </div>

                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        name="issues_outside"
                        control={control}
                        rules={{ required: "Issues Outside is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            value={field.value ?? ""}
                            maxLength={50}
          
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      <label htmlFor="name">Issues Outside</label>
                    </span>
                    <small className="block pt-1">{errors.issues_outside && (
                        <div className="text-red-500">{errors.issues_outside.message}</div>
                      )}</small>
                  </div>

                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        name="starting_details"
                        control={control}
                        rules={{ required: "Starting Details is required." }}
                        render={({ field, fieldState }) => (
                          <InputTextarea
                            id={field.name}
                            {...field}
                            value={field.value ?? ""}
                            
                            maxLength={500}
                            rows={5} cols={30}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      <label htmlFor="starting_details">Starting Details <small>(max 500 characters)</small></label>
                    </span>
                    <small className="block pt-1">{errors.starting_details && (
                        <div className="text-red-500">{errors.starting_details.message}</div>
                      )}</small>
                  </div>   
                </TabPanel>
                <TabPanel header="Ending Shift" disabled={visibleForm.itemId ? false : true}>
                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="EndingVehicleMaintenanceStatusGasTypeId"
                        rules={{
                          validate: ({ value } = {}) =>
                            value !== undefined ||
                            "You must select a type",
                          valueAsNumber: true,
                        }}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            filter
                            placeholder="Select a type"
                            optionLabel="type"
                            options={statusGasTypes}
                            {...field}
                            //optionValue={type}
                            //value = {field.value}
                          />
                        )}
                      />
                      {errors.EndingVehicleMaintenanceStatusGasTypeId && (
                        <div className="text-red-500">
                          {errors.EndingVehicleMaintenanceStatusGasTypeId.message}
                        </div>
                      )}

                      <label htmlFor="type">Ending Gas Status</label>
                    </span>

                  </div>
                
                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        name="ending_mileage"
                        control={control}
                        rules={{ required: "Ending mileage is required.", min: starting_mileage }}
                        render={({ field, fieldState }) => (
                          <InputNumber id={field.name} ref={field.ref} 
                            value={field.value} onBlur={field.onBlur} 
                            onValueChange={(e) => field.onChange(e)} 
                            min={starting_mileage} useGrouping={false} 
                            inputClassName={classNames({ 'p-invalid': fieldState.error })} suffix=" mi"/>
                        )}
                      />

                      <label htmlFor="ending_mileage">Ending mileage</label>
                      
                    </span>
                    <small className="block pt-1">{errors.ending_mileage && (
                        <div className="text-red-500">{errors.ending_mileage.message}</div>
                      )}</small>
                  </div>

                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        name="ending_details"
                        control={control}
                        rules={{ required: "Ending Details is required." }}
                        render={({ field, fieldState }) => (
                          <InputTextarea
                            id={field.name}
                            {...field}
                            value={field.value ?? ""}
                            
                            maxLength={500}
                            rows={5} cols={30}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      <label htmlFor="ending_details">Ending Details <small>(max 500 characters)</small></label>
                    </span>
                    <small className="block pt-1">{errors.ending_details && (
                        <div className="text-red-500">{errors.ending_details.message}</div>
                      )}</small>
                  </div>
                </TabPanel>
               
            </TabView>
                 

              <Button
                disabled={isSubmitting}
                type="submit"
                label={isSubmitting ? "Loading..." : "Submit"}
                className="mt-2"
              />
                
              {errors.root && (
                <div className="text-red-500">{errors.root.message}</div>
              )}
            </form>
            
          </>
        )}
      </Sidebar>
    </>
  );
};

export default SideBar;
