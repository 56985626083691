import React, { useState, useEffect, useMemo, useRef } from "react";
import axios from "../api/axios";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useLocalState } from "../hooks/useLocalState";
import  secureLocalStorage  from  "react-secure-storage";

const UserContext = React.createContext();
const LOGIN_URL = 'auth/login';

export function UserProvider(props) {
    const [user_name, setUserName] = useState('');
    const [accessToken, setToken] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [password, setPassword] = useState('');
    const [gps_coordinates, setGpsCoordinates] = useState('');

    const [userData, setUserData] = useState([]);
    const [userProperties, setUserProperties] = useState([]);
    const [userContextProperty, setContextProperty] = useState('');


    const [errMsg, setErrMsg] = useState('');
    const errRef = useRef();

    //const navigate = useNavigate();

    const login = async (e) => {
        e.preventDefault();


        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ user_name, password, gps_coordinates }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            setIsLoggedIn(true);
            setUserName(user_name);
            setToken(`Bearer ${response?.data.access_token}`);

            secureLocalStorage.setItem("accessToken", response?.data.access_token);
            //setPassword(password);
            setGpsCoordinates(gps_coordinates);

            setUserData(response?.data.user);
            secureLocalStorage.setItem("userData",JSON.stringify([response?.data.user]));
            setUserProperties(response?.data.properties);
            secureLocalStorage.setItem("userProperties", JSON.stringify(response?.data.properties));
            setContextProperty(response?.data.properties[0].property_id);
            secureLocalStorage.setItem("userContextProperty",response?.data.properties[0].property_id)

            dailyactivityreporttype(response?.data.properties[0].property_id, `Bearer ${response?.data.access_token}`);
            dailyactivityreportcode(response?.data.properties[0].property_id, `Bearer ${response?.data.access_token}`);

            //navigate('/dar');

        } catch (err) {
            if (!err?.response) {
                setErrMsg('No server response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing username or pwd');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
                //navigate('/login')
            } else {
                setErrMsg('Login failed');
            }
        }
    }

    const dailyactivityreporttype = async (userContextProperty, accessToken) => {
        try {
            const response = await axios.get(`dailyactivityreporttype?context_property_id=${userContextProperty}`,
                {
                    headers: { Authorization: accessToken }
                });
            secureLocalStorage.setItem("dailyActivityReportTypes", JSON.stringify(response?.data.daily_activity_report_types));

        } catch (err) {
            if (!err?.response) {
                setErrMsg('No server response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing username or pwd');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
                //navigate('/login')
            } else {
                setErrMsg('Login failed');
            }
        }
    }

    const dailyactivityreportcode = async (userContextProperty, accessToken) => {
        try {
            const response = await axios.get(`dailyactivityreportcode?context_property_id=${userContextProperty}`,
                {
                    headers: { Authorization: accessToken }
                });
            secureLocalStorage.setItem("dailyActivityReportCodes", JSON.stringify(response?.data.daily_activity_report_codes));

        } catch (err) {
            if (!err?.response) {
                setErrMsg('No server response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing username or pwd');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
                //navigate('/login')
            } else {
                setErrMsg('Login failed');
            }
        }
    }




    const value = useMemo(() => {
        return ({
            isLoggedIn,
            user_name,
            setToken,
            accessToken,
            setUserName,
            password,
            setPassword,
            gps_coordinates,
            setGpsCoordinates,
            userData,
            login,
            userProperties,
            userContextProperty,
            setContextProperty
        })
    }, [isLoggedIn, user_name, password, gps_coordinates, userData, userContextProperty])

    return <UserContext.Provider value={value} {...props} />
}

export function useUser() {
    const context = React.useContext(UserContext);
    if (!context) {
        throw new Error('useUser debe estar dentro del provider userContext');
    }
    return context;
}