import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchVehicles,
  fetchVehicle,
  addVehicle,
  updateVehicle,
  deleteVehicle,
  fetchVehicleByPlate,
  fetchVehicleByPropertyId,
  fetchVehicleByHouseHoldId,
  fetchVehicleByPropertyIdOrHouseHoldId
} from "../api/vehicleApi";

export function useVehicles(rows, page, propertyId) {
  return useQuery({
    queryKey: ["vehicles", { rows, page, propertyId }],
    queryFn: () => fetchVehicles(rows, page, propertyId),
    retry: false,
    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }

  });
}


export function useVehicleByPlate(licensePlate,propertyId) {
  return useQuery({
    queryKey: ["vehicles_details"],
    queryFn: () => fetchVehicleByPlate(licensePlate,propertyId),
    retry: false,   
    enabled: licensePlate.length >= 3,
    //enabled: false,
    onSuccess: () => {
      console.log("ALL GOOD")
    },

  });
}


export function useVehicleByPropertyId(propertyId) {
  return useQuery({
    queryKey: ["vehicles_details", { propertyId }],
    queryFn: () => fetchVehicleByPropertyId(propertyId) ,
    retry: false,
    //enabled: permits_by === 2,
    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }
  });
}

export function useVehicleByHouseHoldId(house_hold_id,propertyId) {
  return useQuery({
    queryKey: ["vehicles", { house_hold_id,propertyId }],
    queryFn: () => house_hold_id >= 0 ? fetchVehicleByHouseHoldId(house_hold_id,propertyId) : null,
    retry: false,
    //enabled: permits_by === 1,
    //enabled: house_hold_id >= 0 ,
    enabled: false,
    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }
  });
}

export function useVehicleByPropertyIdOrHouseHoldId(house_hold_id,propertyId) {
  return useQuery({
    queryKey: ["vehicles", { propertyId }],
    queryFn: () => fetchVehicleByPropertyIdOrHouseHoldId(house_hold_id,propertyId),
    retry: false,
    enabled: true,
    //enabled: permits_by === 1,
    //enabled: house_hold_id >= 0 ,
    //enabled: false,
    staleTime: 60000,
    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }
  });
}


export function useVehicle(darId) {
  return useQuery({
    queryKey: ["vehicle", { darId }],
    queryFn: () => darId != null ? fetchVehicle(darId) : null,
    retry: false,

    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }
  });
}

export const useInsertVehicle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addVehicle,
    networkMode: "offlineFirst",
    retry: false,
    throwOnError: false,
    onSuccess: () =>{
      queryClient.invalidateQueries({
        queryKey: ["vehicles"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
      queryClient.invalidateQueries({
        queryKey: ["vehicles_details"],
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: (error) => {
      console.log(error)
      
    },
  })
}

export function useUpdateVehicle() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateVehicle,
    //networkMode: offlineFirst,
    onSuccess: () =>{
      queryClient.invalidateQueries({
        queryKey: ["vehicles"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
      queryClient.invalidateQueries({
        queryKey: ["vehicles_details"],
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: () => {
      console.log("error occured")
      
    },
  })
}


export function useDeleteVehicle(){
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteVehicle,

    onSuccess(){
      queryClient.invalidateQueries({
        queryKey: ["vehicles"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
      queryClient.invalidateQueries({
        queryKey: ["vehicles_details"],
        refetchActive: true,
        refetchInactive: false,
      });
    }
  })

} 