import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import VisibleContext from "../../context/VisibleContext";
import { useLocalState } from "../../hooks/useLocalState";

import Datatable from "./Datatable";
import SideBar from "./SideBar";

import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { useQueryClient } from "@tanstack/react-query";


const DispatchLogs = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();


  const { VisibleFormContext, visibleLoadingModalContext } = useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleLoadingModal, setVisibleLoadingModal] = visibleLoadingModalContext;

  const [userData] = useLocalState("", "userData");
  const [roles] = userData[0].user.roles;
  const permissions = roles?.permissions || null;

  let permissionsObject = {}
  permissionsObject['create'] = permissions.find(({ name }) => name === "dispatchlog create") ? true : false
  permissionsObject['show'] = permissions.find(({ name }) => name === "dispatchlog show") ? true : false
  permissionsObject['update'] = permissions.find(({ name }) => name === "dispatchlog update") ? true : false
  permissionsObject['delete'] = permissions.find(({ name }) => name === "dispatchlog delete") ? true : false

  const refetchDispatchLogs = (e) => {
    queryClient.invalidateQueries({
      queryKey: ["dispatchLogs"],
      //exact: true,
      refetchActive: true,
      refetchInactive: false,
    });
  };

  const openSidebarForm = () => {
    setVisibleForm({
      visible: true,
      itemId: null,
      title: "New Dispatch Log",
    });
  };

  return (
    <>
      {permissionsObject.create ?
        <SideBar permissionsObject={permissionsObject}/>
      : null}
      <div className="card ">
        <div className="flex flex-wrap justify-content-between gap-2 mb-1">
          <div className=" p-toolbar-group-start p-toolbar-group-left">
            <Avatar
              label="Dispatch Logs"
              size="large"
              style={{ backgroundColor: "white", width: "11rem" }}
            />
            <Button
              icon="pi pi-refresh"
              rounded
              text
              aria-label="Refresh"
              tooltip="Refresh"
              onClick={(e) => refetchDispatchLogs(e)}
            />
          </div>
          {permissionsObject.delete ? 
            <Button
              onClick={() => openSidebarForm()}
              label="New Dispatch Log"
              id="newBtn"
              //style={{ backgroundColor: "#3F51B580" }}
            />
          : null }
        </div>
        <Datatable permissionsObject={permissionsObject}/>
      </div>

      
    </>
  );
};

export default DispatchLogs;
