import axiosInstance from "./axios";

export async function fetchProperties(rows, page, property_id, sort_field, sort_order) {
  let url = `property/list/${rows}/created_at/desc?page=${page}&context_property_id=${property_id}`;
  return axiosInstance.get(url);
 
}

export async function fetchPropertyTypes() {
  let url = "propertytype";
  return axiosInstance.get(url);
  
}

export function fetchProperty(id) {
  let url = `property/show?property_id=${id}`;
  return axiosInstance.get(url);

}

export function fetchPropertyByPropertyId(id) {
  let url = `property?context_property_id=${id}`;
  return axiosInstance.get(url);

}

export async function addProperty(data) {
  let url = `property/store`;
  return axiosInstance.post(url, data.json);

}

export function updateProperty(json) {
  let url = `property/update`;
  return axiosInstance.post(url, json);

}

export function deleteProperty(id) {
  let url = `property/delete`;
  return axiosInstance.delete(url, {data: { property_id: id }});

}


