import axiosInstance from "./axios";
import * as moment from "moment";
import { useLocalState } from "../hooks/useLocalState";
import secureLocalStorage from "react-secure-storage";
import { DB_NAME, DB_VERSION } from "../utils/Constansts";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../utils/Constansts";

import { blobToArrayBuffer } from "../helpers/helpers";

//import { ACCESS_TOKEN } from "../utils/Constansts";

export async function fetchIncidentReports(rows, page, property_id) {
  let url = `incidentreport/list/${rows}/created_at/desc?page=${page}&context_property_id=${property_id}`;
  return await axiosInstance.get(url);

/*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    throw error;
    //return error;
  }
    */
}


export async function fetchIncidentReportTypes() {
  let url = "incidentreporttype";
  return await axiosInstance.get(url);

/*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    throw error;
  }
    */
}

export function fetchIncidentReport(id) {
  let url = `incidentreport/show?incident_report_id=${id}`;
  return axiosInstance.get(url);
/*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    return error;
  }
    */
}

export async function addIncidentReport(data) {
  let url = `incidentreport/store`;
  return axiosInstance.post(url, data.json);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axiosInstance.post(url, data.json, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response;
  } catch (error) {
    //console.log(error)
    //console.log(data.persistOnStorage)

    //console.log(data)
    //return
    //throw new Error("I'm an error");
    throw error
  }*/
}

export function updateIncidentReport(json) {
  let url = `incidentreport/update`;
  return axiosInstance.post(url, json);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  return axiosInstance.post(url, json, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });*/
}

export function deleteIncidentReport(id) {
  let url = `incidentreport/delete`;
  return axiosInstance.delete(url, {data: { incident_report_id: id }});
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  return axiosInstance.delete(url, {
    data: { incident_report_id: id },
    headers: { Authorization: `Bearer ${accessToken}` },
  });*/
}


