import { useRef, useState, useEffect, useCallback } from "react";
//import { Link, useNavigate, useLocation } from "react-router-dom";
//import axios from "../api/axios";
//import { Toast } from 'primereact/toast';
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../utils/Constansts";
import { v4 as uuidv4 } from "uuid";
import { useInsertDar } from "../hooks/darQueries";
import { useConnStatus } from "../context/network";
import _forEach from 'lodash/forEach';

import { DB_NAME, DB_VERSION } from "../utils/Constansts";
import { convertBase64 } from "../helpers/helpers";

const StoreOfflineData = () => {
  //const toast = useRef(null);
  const { connStatus } = useConnStatus();
  const [status, setStatus] = useState(false);
  const [records, setRecords] = useState(null);

  useEffect(() => {

    const insertData = async () => {
      if(records){
        var len = records.length;
        console.log(len)
        for (var i = 0; i < len; i += 1) {
          if (records[i].object_type === "dar") {

            let json = records[i].json
            if(records[i].json.images_urls != null && records[i].json.images.length  === 0){
              let images_url = records[i].json.images_urls
              let images = []
  
              for (var b = 0; b < images_url.length; b += 1) {
                let blob = arrayBufferToBlob(images_url[b],images_url[b].type)
                let base64 = await convertBase64(blob);
                images.push(base64)
              }

              if(records[i].json.images.length  === 0){
                records[i].json.images = images
              }
              
            }

            let id = records[i].id
            
            insertDar(json,id);
            await delay(3000)
  
     
          }
        }
        setRecords(null)
      }
    }
    insertData()
    // make sure to catch any error
    .catch(console.error)
  }, [records])

  function arrayBufferToBlob(buffer, type) {
    return new Blob([buffer], { type: type });
  }

  const {
    mutateAsync: mutateInsertDar,
    data: addedDar,
    error: errorInsertDar,
    isError: isErrorInsertDar,
    isPending: isPendingInsertDar,
    isSuccess: isSuccessInsertDar,
  } = useInsertDar();


  async function delay(millisec) {
    return new Promise(resolve => { 
      setTimeout(() => { resolve('') }, millisec); 
  }) 
}


  async function insertDar(data, id) {
    try {
      const response = await mutateInsertDar({
        json: data,
        persistOnStorage: false,
      });

      if (response.status === 201) {
        toast.success(`You have created DAR ID: ${response?.data.data.daily_activity_report_id}`,TOAST_CONFIG);

        let requestDb = indexedDB.open(DB_NAME, DB_VERSION);
        requestDb.onsuccess = function () {
          let db = requestDb.result;
          let txn = db.transaction("offline_data", "readwrite");
          let dlt = txn.objectStore("offline_data");
          dlt.delete(id);
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    } 
  }


  const handler = useCallback(e => {
    const requestDb = indexedDB.open(DB_NAME, DB_VERSION);

    requestDb.onerror = function (event) {
      console.error("An error occurred with indexedDB");
      console.error(event);
    };

    requestDb.onsuccess = async function () {
      const db = requestDb.result;
      const transaction = db.transaction("offline_data", "readwrite");
      const store = transaction.objectStore("offline_data");      
      
      let result = store.getAll();
      result.onsuccess =  function (evt) {
        setRecords(evt.target.result)
      };
      
    };

  }, [])

  useEffect(() => {
    window.addEventListener('online', handler)
    //window.addEventListener('offline', handler)

    return () => {
      window.removeEventListener('online', handler)
      //window.removeEventListener('offline', handler)
    }
  }, [handler])

  

  /*
  useEffect(() => {
    if (isSuccessInsertDar) {
        toast.success(
            `You have created DAR ID: ${addedDar?.data.data.daily_activity_report_id}`,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              toastId: uuidv4()
            }
          );
    }
    if (isPendingInsertDar) {
     
    }
    if (isErrorInsertDar) {
      console.log(errorInsertDar);
    }
  }, [addedDar]);
*/
  //return <></>;
};
export default StoreOfflineData;
