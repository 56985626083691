import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDarCodes, useDarTypes } from "../hooks/darQueries";
import { useIncidentReportTypes } from "../hooks/incidentReportQueries";
import { useCitationTypes } from "../hooks/citationQueries";
import { useDispatchLogTypes } from "../hooks/dispatchLogQueries";
import { usePropertyTypes } from "../hooks/propertyQueries";

import { useLocalState } from "../hooks/useLocalState";
import { ProgressSpinner } from "primereact/progressspinner";
import { useConnStatus } from "../context/network";

import secureLocalStorage from "react-secure-storage";
import HandleFetchErrors from "../utils/HandleFetchErrors";

const Loading = () => {
  const { connStatus } = useConnStatus();

  const navigate = useNavigate();
  const [isBackToLogin, setIsBackToLogin] = useState(false);
  const [isNotResident, setIsNotResident] = useState(true);

  const [dispatchLogCreate, setDispatchLogCreate] = useState(false);
  const [darCreate, setDarCreate] = useState(false);
  const [incidentCreate, setIncidentCreate] = useState(false);
  const [citationCreate, setCitationCreate] = useState(false);
  const [propertyCreate, setPropertyCreate] = useState(false);

  //const userData = JSON.parse(secureLocalStorage.getItem("userData"));
  const [userData] = useLocalState("", "userData");

  useEffect(() => {
    let permissions = userData[0].user.roles[0].permissions;

    permissions.map((permission) => {
      if (permission.name.includes("create")) {
        if (permission.name.includes("dar create")) {
          setDarCreate(true);
        }
        if (permission.name.includes("incidentreport create")) {
          setIncidentCreate(true);
        }
        if (permission.name.includes("citation create")) {
          setCitationCreate(true);
        }
        if (permission.name.includes("dispatchlog create")) {
          setDispatchLogCreate(true);
        }
        if (permission.name.includes("property create")) {
          setPropertyCreate(true);
        }
      }
    });

    if (userData[0].isResident) {
      navigate("/safelistings");
    }
  }, [userData]);

  const {
    isPending: isPendingDarCodes,
    isSuccess: isSuccessDarCodes,
    isError: isErrorDarCodes,
    data: fetchedDarCodes,
    error: errorDarCodes,
  } = useDarCodes(darCreate);

  const {
    isPending: isPendingDarTypes,
    isSuccess: isSuccessDarTypes,
    isError: isErrorDarTypes,
    data: fetchedDarTypes,
    error: errorDarTypes,
  } = useDarTypes(darCreate);

  const {
    isPending: isPendingIncidentReportTypes,
    isSuccess: isSuccessIncidentReportTypes,
    isError: isErrorIncidentReportTypes,
    data: fetchedIncidentReportTypes,
    error: errorIncidentReportTypes,
  } = useIncidentReportTypes(incidentCreate);

  const {
    isPending: isPendingVehicleCitationTypes,
    isSuccess: isSuccessVehicleCitationTypes,
    isError: isErrorVehicleCitationTypes,
    data: fetchedVehicleCitationTypes,
    error: errorVehicleCitationTypes,
  } = useCitationTypes(citationCreate);

  const {
    isPending: isPendingDispatchLogTypes,
    isSuccess: isSuccessDispatchLogTypes,
    isError: isErrorDispatchLogTypes,
    data: fetchedDispatchLogTypes,
    error: errorDispatchLogTypes,
    refetch: refetchDispatchLogTypes,
  } = useDispatchLogTypes(dispatchLogCreate);

  const {
    isPending: isPendingPropertyTypes,
    isSuccess: isSuccessPropertyTypes,
    isError: isErrorPropertyTypes,
    data: fetchedPropertyTypes,
    error: errorPropertyTypes,
    refetch: refetchPropertyTypes,
  } = usePropertyTypes(propertyCreate);

  useEffect(() => {
    //console.log(dispatchLogCreate)
    if (
      isErrorDarCodes ||
      isErrorDarTypes ||
      isErrorIncidentReportTypes ||
      isErrorVehicleCitationTypes ||
      isErrorDispatchLogTypes
    ) {
      if (
        HandleFetchErrors(errorDarCodes) ||
        HandleFetchErrors(errorDarTypes) ||
        HandleFetchErrors(errorIncidentReportTypes) ||
        HandleFetchErrors(errorVehicleCitationTypes) ||
        HandleFetchErrors(errorDispatchLogTypes)
      ) {
        setIsBackToLogin(true);
      }
    }
  }, [
    isErrorDarCodes,
    isErrorDarTypes,
    isErrorIncidentReportTypes,
    isErrorVehicleCitationTypes,
    isErrorDispatchLogTypes,
  ]);

  useEffect(() => {
    if (
      isSuccessDarCodes &&
      isSuccessDarTypes &&
      isSuccessIncidentReportTypes &&
      isSuccessVehicleCitationTypes
    ) {
      secureLocalStorage.setItem(
        "dailyActivityReportCodes",
        JSON.stringify(fetchedDarCodes?.data.daily_activity_report_codes)
      );
      secureLocalStorage.setItem(
        "dailyActivityReportTypes",
        JSON.stringify(fetchedDarTypes?.data.daily_activity_report_types)
      );
      secureLocalStorage.setItem(
        "incidentReportTypes",
        JSON.stringify(fetchedIncidentReportTypes?.data.incident_report_types)
      );
      secureLocalStorage.setItem(
        "vehicleCitationTypes",
        JSON.stringify(fetchedVehicleCitationTypes?.data.citation_types)
      );
      secureLocalStorage.setItem(
        "dispatchLogTypes",
        JSON.stringify(fetchedDispatchLogTypes?.data.dispatch_log_types)
      );
      secureLocalStorage.setItem(
        "propertyTypes",
        JSON.stringify(fetchedPropertyTypes?.data.property_types)
      );

      navigate("/dar");
    }
  }, [
    isSuccessDarCodes,
    isSuccessDarTypes,
    isSuccessIncidentReportTypes,
    isSuccessVehicleCitationTypes,
    isSuccessDispatchLogTypes,
    isSuccessPropertyTypes,
  ]);

  useEffect(() => {
    if (isBackToLogin) {
      navigate("/login");
    }
  }, [isBackToLogin]);

  return (
    <>
      <div className="flex flex-wrap justify-content-center gap-2 mb-1">
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <ProgressSpinner
            aria-label="Loading"
            style={{ width: "10px", position: "static" }}
            strokeWidth="2"
            fill="var(--surface-ground)"
            animationDuration="1.8s"
          />
          <br />
          <span>Loading Data</span>
        </div>
      </div>
    </>
  );
};

export default Loading;
