import * as moment from "moment";

export const utcToLocale = (date) => {
  const momentObj = moment.utc(date);
  const userOffset = moment().utcOffset();
  const localMomentObj = momentObj.utcOffset(userOffset);
  const formattedDateTime = localMomentObj.format("MM/DD/yy HH:mm");

  return new Date(formattedDateTime);
};

export const dateToFormat = (date) => {
  const momentObj = moment(date);
  const formattedDateTime = momentObj.format("MM/DD/yy HH:mm");

  return new Date(formattedDateTime);
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    //console.log(file)
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export function blobToArrayBuffer(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("loadend", () => {
      resolve(reader.result);
    });
    reader.addEventListener("error", reject);
    reader.readAsArrayBuffer(blob);
  });
}

export function addDays(paramDate, days) {
  let date = new Date(paramDate);
  date.setDate(date.getDate() + days);

  return date;
}

export const getHasPermission = (permissions = [], name) => {
  return Boolean(permissions.find((p) => p.name === name));
};
