import { useState } from "react";
import secureLocalStorage from "react-secure-storage";

function useLocalState(defaultValue, key) {
  const [value, setValue] = useState(() => {
    const secureLocalStorageValue = secureLocalStorage.getItem(key);

    return secureLocalStorageValue !== null
      ? JSON.parse(secureLocalStorageValue)
      : defaultValue;
  });

  const handleUpdate = (value) => {
    secureLocalStorage.setItem(key, JSON.stringify(value));
    setValue(value);
  };

  return [value, handleUpdate];
}

export { useLocalState };
