import React, { useContext } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import VisibleContext from "../../context/VisibleContext";
import { useLocalState } from "../../hooks/useLocalState";

import Datatable from "./Datatable";
import SideBar from "./SideBar";


const Safelistings = () => {
  const queryClient = useQueryClient();


  const { VisibleFormContext } = useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;

  const [userData] = useLocalState("", "userData");
  
  const [roles] = userData[0].user.roles;
  const permissions = roles?.permissions || null;

  let permissionsObject = {
    create: permissions.find(({ name }) => name === "safelisting create") ? true : false,
    show: permissions.find(({ name }) => name === "safelisting show") ? true : false,
    update: permissions.find(({ name }) => name === "safelisting update") ? true : false,
    delete: permissions.find(({ name }) => name === "safelisting delete") ? true : false
  };

  const refetchSafelistings = () => {
    queryClient.invalidateQueries({
      queryKey: ['safelistings'],
      refetchActive: true,
      refetchInactive: false,
    });
  };

  const openSidebarForm = () => {
    setVisibleForm({
      visible: true,
      itemId: null,
      title: "New Safelisting",
    });

    queryClient.invalidateQueries({
      queryKey: ["vehicles_details"],
      refetchActive: true,
      refetchInactive: false,
    });
  };

  return (
    <>
      {permissionsObject.create && <SideBar permissionsObject={permissionsObject}/>}
      <div className="card">
        <div className="flex flex-wrap justify-content-between gap-2 mb-1">
          <div className="p-toolbar-group-start p-toolbar-group-left">
            <Avatar
              label="Safelistings"
              size="large"
              style={{ backgroundColor: "white", width: "8rem" }}
            />
            <Button
              icon="pi pi-refresh"
              rounded
              text
              aria-label="Refresh"
              tooltip="Refresh"
              onClick={refetchSafelistings}
            />
          </div>
          {permissionsObject.create && (
            <Button
              onClick={openSidebarForm}
              label="New Safelisting"
              id="newBtn"
            />
          )}
        </div>
      
        <Datatable permissionsObject={permissionsObject} />
      
      </div>
    </>
  );
};

export default Safelistings;
