import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import useClient from "../hooks/useClient";
import { getPromise } from "../utils";
import { usePosition } from "./position";
import secureLocalStorage from "react-secure-storage";

import { useLogout } from "../hooks/loginQueries";

import { fetchLogin } from "../api/loginApi";

const AuthContext = React.createContext();
AuthContext.displayName = "AuthContext";

const AUTH_STORAGE_KEY = "starproaccess_v2:auth_session";
const MOUNTED_KEY = "starproaccess_v2:application_mounted";

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [validateState, setValidateState] = React.useState("idle");
  const { client } = useClient();
  const { getPosition } = usePosition();

  const [user, setUser] = React.useState(() => {
    const userStorage = secureLocalStorage.getItem(AUTH_STORAGE_KEY);
    return userStorage ? JSON.parse(userStorage) : null;
  });

  const [loadUtils, setLoadUtils] = React.useState(false);

  const loginMutation = useMutation({
    mutationFn: fetchLogin,
    retry: 0,
    onSuccess: (data) => {
      //console.log("ALL GOOD")
      //console.log(`useLogin ${JSON.stringify(data.data.access_token)}`)
      //secureLocalStorage.setItem("accessToken",JSON.stringify(data.data.access_token));
    },
  });

  const {
    mutateAsync: logoutMutation,
    error: errorLogout,
    isError: isErrorLogout,
    isPending: isPendingLogout,
    isSuccess: isSuccessLogout,
  } = useLogout();

  const forgetMutation = useMutation((mutateData) =>
    getPromise("/auth/forgot", { body: mutateData })
  );

  const resetMutation = useMutation((mutateData) =>
    getPromise("/auth/reset", { body: mutateData })
  );

  const offlineDataMutation = useMutation((mutateData) =>
    getPromise("/officerlog/offline_data", { body: mutateData }, user)
  );

  const login = React.useCallback(
    async (userName, password, rememberMe, latitude, longitude) => {
      return loginMutation
        .mutateAsync({
          user_name: userName,
          password,
          rememberMe,
          gps_coordinates: `${latitude},${longitude}`,
        })
        .then((userData) => {
          //console.log(_user)
          const finalUser = {
            user: userData?.data.user,
            isAdmin: userData?.data.user.roles[0].name === "Admin",
            isSupervisor: userData?.data.user.roles[0].name === "Supervisor",
            isPostCommander:
              userData?.data.user.roles[0].name === "Post Commander",
            isPropertyManager:
              userData?.data.user.roles[0].name === "Property Manager",
            isCallCenter: userData?.data.user.roles[0].name === "Call Center",
            isOfficer: userData?.data.user.roles[0].name === "Officer",
            isResident: userData?.data.user.roles[0].name === "Resident",
            isBoardOfDirectors:
              userData?.data.user.roles[0].name === "Board of Directors",
          };

          secureLocalStorage.setItem(
            "accessToken",
            JSON.stringify(userData?.data.access_token)
          );
          secureLocalStorage.setItem("userData", JSON.stringify([finalUser]));
          secureLocalStorage.setItem(
            "userProperties",
            JSON.stringify(userData?.data.properties)
          );
          secureLocalStorage.setItem(
            "userContextProperty",
            userData?.data.properties[0].property_id
          );
          secureLocalStorage.setItem(
            "userContextPropertyData",
            JSON.stringify(userData?.data.properties[0])
          );

          secureLocalStorage.setItem(
            "userContextPropertyCitationTypes",
            JSON.stringify(
              userData?.data.properties[0]["property_citation_types"]
            )
          );

          if (finalUser.shifts) {
            offlineDataMutation.mutate({
              propertyId: finalUser.shifts.properties[0].propertyId,
            });
          }

          return { loginFailed: false };
        })
        .catch((error) => {
          return {
            loginFailed: true,
            error,
          };
        });
    },

    [loginMutation, offlineDataMutation]
  );

  const forgot = React.useCallback(
    (email) => {
      forgetMutation.mutate({ email });
    },
    [forgetMutation]
  );

  const resetPassword = React.useCallback(
    (resetData) => {
      resetMutation.mutate(resetData);
    },
    [resetMutation]
  );

  const clearUser = React.useCallback(() => {
    localStorage.setItem(AUTH_STORAGE_KEY, null);
    setUser(null);
  }, []);

  const clearStore = () => {
    Object.keys(localStorage).forEach((key) => {
      localStorage.setItem(key, null);
    });
  };

  const removeStore = () => {
    Object.keys(localStorage).forEach((key) => {
      localStorage.removeItem(key, null);
    });
  };

  const logout = React.useCallback(async () => {
    try {
      const response = await logoutMutation();

      if (response.status === 200) {
        clearStore();
        clearUser();
        removeStore();
        secureLocalStorage.clear();
        sessionStorage.clear();

        navigate("/login");
      }
    } catch (error) {
      throw error;
    }
  }, [isSuccessLogout]);

  const validate = React.useCallback(
    ({ officerScheduleId, propertyId } = {}) => {
      setValidateState("loading");
      getPosition((position) => {
        if (position.error) {
          return toast.error(position.error.message);
        }

        client("/auth/validateData", {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
            "Content-type": "application/json",
          },
          body: {
            gpsCoordinates: `${position.latitude},${position.longitude}`,
            officerScheduleId,
            propertyId,
          },
        })
          .then((userData) => {
            if (Object.keys(userData).length) {
              setUser((oldData) => {
                const newData = { ...oldData };
                if (propertyId) {
                  newData.properties = [...newData.properties, userData[0]];
                } else {
                  newData.shifts = userData.shifts;
                  if (!offlineDataMutation.isLoading) {
                    offlineDataMutation.mutate({
                      propertyId: newData.shifts.properties[0].propertyId,
                    });
                  }
                }

                secureLocalStorage.setItem(
                  AUTH_STORAGE_KEY,
                  JSON.stringify(newData)
                );
                return newData;
              });
            }
            setValidateState("success");
          })
          .catch((e) => {
            clearUser();
            setValidateState("idle");
          });
      });
    },
    [getPosition, client, user, offlineDataMutation, clearUser]
  );

  const value = React.useMemo(
    () => ({
      user,
      loadUtils,
      login,
      logout,
      forgot,
      resetPassword,
      clearUser,
      removeStore,
      validate,
      error: loginMutation.error,
      isLoading: loginMutation.isLoading,
      isSuccess: loginMutation.isSuccess,
      isError: loginMutation.isError,
      forgotState: {
        isLoading: forgetMutation.isLoading,
        isError: forgetMutation.isError,
        isSuccess: forgetMutation.isSuccess,
        error: forgetMutation.error,
      },
      resetState: {
        isLoading: resetMutation.isLoading,
        isError: resetMutation.isError,
        isSuccess: resetMutation.isSuccess,
        error: resetMutation.error,
      },
      validateState: {
        validateState,
        isLoading: validateState === "loading",
        isIdle: validateState === "idle",
        isSuccess: validateState === "success",
        isError: validateState === "error",
      },
    }),
    [
      user,
      loadUtils,
      login,
      logout,
      forgot,
      resetPassword,
      forgetMutation,
      clearUser,
      removeStore,
      validate,
      loginMutation,
      resetMutation,
      validateState,
    ]
  );

  React.useEffect(() => {
    const mounted = secureLocalStorage.getItem(MOUNTED_KEY);

    if (
      user && //isSuccessDarCodes && isSuccessDarTypes &&
      ((user.isOfficer && offlineDataMutation.isSuccess) ||
        (!user.isOfficer && offlineDataMutation.isIdle)) &&
      !mounted
    ) {
      /** so app can cache assests correctly */
      secureLocalStorage.setItem(MOUNTED_KEY, true);

      setLoadUtils(true);

      //window.location.reload()
      //secureLocalStorage.setItem("dailyActivityReportCodes",JSON.stringify(fetchedDarCodes?.data.daily_activity_report_codes));
      //secureLocalStorage.setItem("dailyActivityReportTypes",JSON.stringify(fetchedDarTypes?.data.daily_activity_report_types));
    }
  }, [offlineDataMutation, user]);

  /*
  React.useEffect(() => {
    if (offlineDataMutation.isSuccess) {
      addOfflineData(offlineDataMutation.data)
    }
  }, [addOfflineData, offlineDataMutation])
*/
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { useAuth };
export default AuthProvider;
