import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchSafelistings,
  fetchSafelisting,
  addSafelisting,
  updateSafelisting,
  deleteSafelisting,
} from "../api/safelistingApi";
import { useLocalState } from "./useLocalState";

let accessToken = JSON.parse(localStorage.getItem("accessToken")) || null


//import { ACCESS_TOKEN } from "../utils/Constansts";

//console.log(ACCESS_TOKEN)

export function useSafelistings(rows, page, propertyId) {
  return useQuery({
    queryKey: ["safelistings", { rows, page, propertyId }],
    queryFn: () => fetchSafelistings(rows, page, propertyId),
    retry: false,
    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }

  });
}


export function useSafelisting(darId) {
  return useQuery({
    queryKey: ["safelisting", { darId }],
    queryFn: () => darId != null ? fetchSafelisting(darId) : null,
    retry: false,

    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }
  });
}

export const useInsertSafelisting = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addSafelisting,
    networkMode: "offlineFirst",
    retry: false,
    throwOnError: false,
    onSuccess: () =>{
      queryClient.invalidateQueries({
        queryKey: ["safelistings"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: (error) => {
      console.log(error)
      
    },
  })
}

export function useUpdateSafelisting() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateSafelisting,
    //networkMode: offlineFirst,
    onSuccess: () =>{
      queryClient.invalidateQueries({
        queryKey: ["safelistings"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: () => {
      console.log("error occured")
      
    },
  })
}

export function useDeleteSafelisting(){
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteSafelisting,

    onSuccess(){
      queryClient.invalidateQueries({
        queryKey: ["safelistings"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    }
  })

} 
