import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import VisibleContext from "../../context/VisibleContext";
import { useLocalState } from "../../hooks/useLocalState";
import Datatable from "./Datatable";
import SideBar from "./SideBar";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { useQueryClient } from "@tanstack/react-query";


const IncidentReports = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { VisibleFormContext, visibleLoadingModalContext } = useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;

  const [userData] = useLocalState("", "userData");
  const [roles] = userData[0].user.roles;
  const permissions = roles?.permissions || null;

  let permissionsObject = {}
  permissionsObject['create'] = permissions.find(({ name }) => name === "incidentreport create") ? true : false
  permissionsObject['show'] = permissions.find(({ name }) => name === "incidentreport show") ? true : false
  permissionsObject['update'] = permissions.find(({ name }) => name === "incidentreport update") ? true : false
  permissionsObject['delete'] = permissions.find(({ name }) => name === "incidentreport delete") ? true : false
 

  const refetchIncidentReports = (e) => {
    queryClient.invalidateQueries({
      queryKey: ["incidentReports"],
      //exact: true,
      refetchActive: true,
      refetchInactive: false,
    });
  };

  const openSidebarForm = () => {
    setVisibleForm({
      visible: true,
      itemId: null,
      title: "New Incident Report",
    });
  };

  return (
    <>
      <SideBar permissionsObject={permissionsObject}/>
      <div className="card ">
        <div className="flex flex-wrap justify-content-between gap-2 mb-1">
          <div className=" p-toolbar-group-start p-toolbar-group-left">
            <Avatar
              label="Incident Reports"
              size="large"
              style={{ backgroundColor: "white", width: "11rem" }}
            />
            <Button
              icon="pi pi-refresh"
              rounded
              text
              aria-label="Refresh"
              tooltip="Refresh"
              onClick={(e) => refetchIncidentReports(e)}
            />
          </div>
          {permissionsObject.create ? 
            <Button
              onClick={() => openSidebarForm()}
              label="New Incident Report"
              id="newBtn"
              //style={{ backgroundColor: "#3F51B580" }}
            />
          : null }
        </div>
        <Datatable permissionsObject={permissionsObject}/>
      </div>

      
    </>
  );
};

export default IncidentReports;
