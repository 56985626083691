import React from "react";
import { DB_NAME,DB_VERSION } from "./Constansts";

export default function IndexedDB() {
  let db;
  const requestDb = indexedDB.open(DB_NAME, DB_VERSION);

  requestDb.addEventListener("error", () => console.error("Error opening DB"));

  requestDb.onupgradeneeded = event => {
    const db = event.target.result;
    const objectStore = db.createObjectStore("offline_data", { keyPath: 'id', autoIncrement: true });
};

  /*requestDb.onupgradeneeded = function (event) {
    let db = requestDb.result;
    console.log("onupgradeneeded");
    console.log(event.oldVersion);
    console.log(DB_VERSION);
    switch(event.oldVersion) { // versión de db existente
      case 0:
        console.log("onupgradeneeded 0");
        // version 0 significa que el cliente no tiene base de datos
        // ejecutar inicialización
        const store = db.createObjectStore("offline_data",{ keyPath: "id" },{ autoIncrement: true });
        store.createIndex("id", "id", { unique: true });
      case 1:
        // el cliente tiene la versión 1
        // actualizar
        console.log("onupgradeneeded 1");
        let deleteRequest = indexedDB.deleteDatabase(DB_NAME);
        deleteRequest.onsuccess = function () {
          //window.location.reload()
          db.createObjectStore("offline_data",{ keyPath: "id" },{ autoIncrement: true });
        };
    }


    
    //const store = db.createObjectStore("offline_data");
    //const store = db.createObjectStore("offline_data",{autoIncrement: true});
    //store.createIndex("id", "id", { unique: true });
    //store.createIndex("json", "json", { unique: false });
    //store.createIndex("date", "date", { unique: false });
    //store.createIndex("is_deleted", "is_deleted", { unique: false });
  };*/

  requestDb.onsuccess = function () {
    //console.log(records);
  };

  return <></>
}
