import axiosInstance from "./axios";
import * as moment from "moment";
import { useLocalState } from "../hooks/useLocalState";
import secureLocalStorage from "react-secure-storage";
import { DB_NAME, DB_VERSION } from "../utils/Constansts";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../utils/Constansts";

import { blobToArrayBuffer } from "../helpers/helpers";

//import { ACCESS_TOKEN } from "../utils/Constansts";

export async function fetchDars(rows, page, property_id) {
  let url = `dailyactivityreport/list/${rows}/dar_date/desc?page=${page}&context_property_id=${property_id}`;
  //let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));

  return await axiosInstance.get(url);
}

export async function fetchDarCodes() {
  let url = "dailyactivityreportcode";
  return await axiosInstance.get(url);
  
  /*let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));

  return await axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
 */   
}

export async function fetchDarTypes() {
  let url = "dailyactivityreporttype";
  return await axiosInstance.get(url);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    throw error;
  }
    */
}

export function fetchDar(id) {
  let url = `dailyactivityreport/show?daily_activity_report_id=${id}`;
  return axiosInstance.get(url);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = axiosInstance.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // Success 🎉
    //console.log(response);
    return response;
  } catch (error) {
    //console.error(error);
    return error;
  }
    */
}

export async function addDar(data) {
  let url = `dailyactivityreport/store`;

  return await axiosInstance.post(url, data.json);
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  try {
    const response = await axiosInstance.post(url, data.json, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response;
  } catch (error) {
   

    if (data && data.persistOnStorage) {
     

      if (data.json.images_urls.length > 0){
        //console.log("aaaca");
        let imagesArray = [];
        for (let number = 0; number < data.json.images_urls.length; number++) {
          //console.log("aaaca 2222");
          //console.log(data.json.images_urls[number]);
          imagesArray.push(
            await blobToArrayBuffer(data.json.images_urls[number])
          );
          //toast.success('step2',TOAST_CONFIG);
        }

        //console.log(imagesArray);
        //toast.success('step3',TOAST_CONFIG);
        
        if (imagesArray.length > 0){
          //console.log("aaaca 3333");
          data.json.images_urls = imagesArray
          //toast.success('step4',TOAST_CONFIG);
        }
      }
      //console.log(data.json);
      //return
      const requestDb = indexedDB.open(DB_NAME, DB_VERSION);

      requestDb.onerror = function () {
        console.log("error");
      };

      requestDb.onsuccess = function () {
        const db = requestDb.result;
        const transaction = db.transaction("offline_data", "readwrite");
        const store = transaction.objectStore("offline_data");

        let formattedDate = new Date();

        //console.log(store.autoIncrement);

        const stored = store.add({
          //id: randomId,
          object_type: "dar",
          url: url,
          json: data.json,
          //imagesArray: imagesArray,
          date: moment(new Date()).format("yyyy-MM-DD").toString(),
          is_deleted: 0,
        });

        stored.onsuccess = () => {
          console.log("Reporte agregado al almacén", stored.result);
        };

        stored.onerror = function () {
          console.log("Error", stored.error);
        };

        transaction.oncomplete = function () {
          console.log("Transacción completa");
          //return "Transacción completa"
        };
      };
    }
    //return
    //throw new Error("I'm an error");
    throw error
  }
  */
}

export function updateDar(json) {
  let url = `dailyactivityreport/update`;
  return axiosInstance.post(url, json);
  /*let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  return axiosInstance.post(url, json, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });*/
}

export function deleteDar(id) {
  let url = `dailyactivityreport/delete`;
  return axiosInstance.delete(url, {data: { daily_activity_report_id: id }});
  /*
  let accessToken = JSON.parse(secureLocalStorage.getItem("accessToken"));
  return axiosInstance.delete(url, {
    data: { daily_activity_report_id: id },
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  */
}
