import * as React from 'react'
import {objToSnakeCase, objToCamelCase} from '../utils'

const {REACT_APP_API_URL} = process.env

const useSafeDispatch = dispatch => {
  const mounted = React.useRef(false)
  React.useLayoutEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])
  return React.useCallback(
    (...args) => (mounted.current ? dispatch(...args) : null),
    [dispatch]
  )
}

const STATUS_PENDING = 'pending'
const STATUS_REJECTED = 'rejected'
const STATUS_RESOLVED = 'resolved'
const STATUS_IDLE = 'idle'

const initialState = {
  status: STATUS_IDLE,
  error: null,
  data: null,
}

const getReqOptions = ({body, headers, ...rest}) => {
  const options = {
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      ...headers,
    },
    method: body ? 'POST' : 'GET',
    ...rest,
  }

  if (body) {
    options.body = JSON.stringify(objToSnakeCase(body))
  }

  return options
}

const useClient = () => {
  const [state, dispatch] = React.useReducer(
    (s, a) => ({...s, ...a}),
    initialState
  )

  const safeSetState = useSafeDispatch(dispatch)

  const setData = React.useCallback(
    data => {
      safeSetState({data, status: STATUS_RESOLVED})
      setTimeout(() => {
        safeSetState({data, status: STATUS_IDLE})
      }, 200)
    },
    [safeSetState]
  )

  const setError = React.useCallback(
    error => {
      safeSetState({error, status: STATUS_REJECTED})
    },
    [safeSetState]
  )

  const client = React.useCallback(
    (url, options, cb) => {
      safeSetState({status: STATUS_PENDING})
      const reqOptions = options ? getReqOptions(options) : undefined
      const endpoint = `${REACT_APP_API_URL}${url}`
      return fetch(endpoint, reqOptions)
        .then(response => {
          if (!response.ok) {
            return new Promise((resolve, reject) => {
              response.json().then(message => {
                let err
                if (typeof message === 'string') {
                  err = message
                } else if (message.message) {
                  err = message.message
                } else if (Array.isArray(message)) {
                  err = 'Required fields missing in the request'
                }
                const error = `${response.status} - ${err}`
                reject(error)
              })
            })
          }
          return response.json()
        })
        .then(data => {
          const formatted = objToCamelCase(data)
          if (typeof cb === 'function') {
            cb(formatted)
          }
          setData(formatted)
          return formatted
        })
        .catch(error => {
          let err
          if (typeof error === 'string') {
            err = error
          } else if (error.message) {
            err = error.message
          } else if (Array.isArray(error)) {
            err = 'Required fields missing in the request'
          }
          setError(err)
          return Promise.reject(err)
        })
    },
    [safeSetState, setData, setError]
  )

  return {
    ...state,
    client,
    setData,
    setError,
    isPending: state.status === STATUS_PENDING,
    isSuccess: state.status === STATUS_REJECTED,
    isError: state.status === STATUS_RESOLVED,
  }
}

export default useClient