import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import VisibleContext from "../../context/VisibleContext";
import { usePosition } from "../../context/position";

import Datatable from "./Datatable";
import SideBar from "./SideBar";

import PositionError from "../PositionError/PositionError";

import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";

import { useQueryClient } from "@tanstack/react-query";


const Users = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const positionContext = usePosition();


  const { VisibleFormContext, visibleLoadingModalContext } = useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleLoadingModal, setVisibleLoadingModal] = visibleLoadingModalContext;

  //const [visibleForm,setVisibleForm] = useLocalState(null,"visibleForm");

  const refetchUsers = (e) => {
    queryClient.invalidateQueries({
      queryKey: ["users"],
      //exact: true,
      refetchActive: true,
      refetchInactive: false,
    });
  };



  const openSidebarForm = () => {
    /*positionContext?.getPosition((position) => {
      if (position.error) {
        return <PositionError />;
      }
    });*/

    setVisibleForm({
      visible: true,
      itemId: null,
      title: "New User",
    });
    //console.log(visibleForm);
    //Fconsole.log(visibleFormVariables.itemId);
  };

  return (
    <>
      <SideBar />
      <div className="card ">
        <div className="flex flex-wrap justify-content-between gap-2 mb-1">
          <div className=" p-toolbar-group-start p-toolbar-group-left">
            <Avatar
              label="Users"
              size="large"
              style={{ backgroundColor: "white" }}
            />
            <Button
              icon="pi pi-refresh"
              rounded
              text
              aria-label="Refresh"
              tooltip="Refresh"
              onClick={(e) => refetchUsers(e)}
            />
          </div>
          <Button
            onClick={() => openSidebarForm()}
            label="New User"
            id="newBtn"
            style={{ backgroundColor: "#3F51B580" }}
          />
        </div>
        <Datatable />
      </div>

      
    </>
  );
};

export default Users;
