import React,{ useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import nProgress from "nprogress";
import { useAuth } from "../../context/auth";
import { usePosition } from "../../context/position";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { z } from "zod";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { classNames } from "primereact/utils";
import { Divider } from "primereact/divider";

import { toast } from "react-toastify";

import secureLocalStorage from "react-secure-storage";

import { LOGO } from "../../utils/Constansts";

import { useForgot } from "../../hooks/loginQueries";

const schema = z.object({

  email: z.string().min(4)
})

const Forgot = () => {
  const positionContext = usePosition();

  const { forgotPassword } = useAuth();

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
 

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
    setError,
  } = useForm({
    values: {
      email: email,
    },
    resolver: zodResolver(schema),
  });


    const {
      mutateAsync: mutateForgot,
      data: dataForgot,
      error: errorForgot,
      isError: isErrorForgot,
      isSuccess: isSuccessForgot,
    } = useForgot();


 

    useEffect(() => {
      if (isSuccessForgot) {
        console.log(dataForgot)
        toast.success(dataForgot.data.message);
       
        navigate("/");
    
        
      }
    }, [isSuccessForgot]);

  const onSubmit = async (data) => {

    
    try {
      const {
        position: { latitude, longitude },
      } = positionContext;

      const forgotData = {
        email: data.email,
      }
    
      await mutateForgot(forgotData);
    
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="">
        <div className="flex justify-content-center align-items-center flex-order-6 py-7  h-screen">
          <div className="card px-8 pb-7 pt-4 text-center mb-5">
            <img src={LOGO} alt="Image" height="100" />
            <h2 className="text-center">Forgot</h2>

            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              
            <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="email"
                    control={control}
                    rules={{ required: "Email is required." }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        toggleMask
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    htmlFor="email"
                    className={classNames({ "p-error": errors.email })}
                  >
                    Email *
                  </label>
                </span>
                {errors.email && (
                  <div className="text-red-500">{errors.email.message}</div>
                )}
              </div>

              <Button
                disabled={isSubmitting}
                type="submit"
                label={isSubmitting ? "Loading..." : "Submit"}
                className="mt-2"
              />
            </form>
            <br />
            {errors.root && (
              <div className="text-red-500">{errors.root.message}</div>
            )}

            {isErrorForgot ? (
                <div className="text-red-500">{errorForgot.response.data.message}</div>
              ) : null}
          </div>
        </div>
      </section>
    </>
  );
};

export default Forgot;
