import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchPropertyPools,
  fetchPropertyPool,
  addPropertyPool,
  updatePropertyPool,
  deletePropertyPool,
  fetchPropertyPoolByPropertyId,
} from "../api/propertyPoolApi";
import { useLocalState } from "./useLocalState";

let accessToken = JSON.parse(localStorage.getItem("accessToken")) || null


//import { ACCESS_TOKEN } from "../utils/Constansts";

//console.log(ACCESS_TOKEN)

export function usePropertyPools(rows, page, propertyId) {
  return useQuery({
    queryKey: ["propertypools", { rows, page, propertyId }],
    queryFn: () => fetchPropertyPools(rows, page, propertyId),
    retry: false,
    //staleTime: 10,
    refetchInterval:400000,
    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }

  });
}


export function usePropertyPool(itemId) {
  return useQuery({
    queryKey: ["poolaccesslog", { itemId }],
    queryFn: () => itemId != null ? fetchPropertyPool(itemId) : null,
    retry: false,

    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }
  });
}

export function usePropertyPoolByPropertyId(itemId,createPermission) {
  return useQuery({
    queryKey: ["pool", { itemId,createPermission }],
    queryFn: () => itemId != null ? fetchPropertyPoolByPropertyId(itemId) : null,
    retry: false,
    enabled: createPermission,
    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }
  });
}

export const useInsertPropertyPool = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addPropertyPool,
    networkMode: "offlineFirst",
    retry: false,
    throwOnError: false,
    onSuccess: () =>{
      queryClient.invalidateQueries({
        queryKey: ["propertypools"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: (error) => {
      console.log(error)
      
    },
  })
}

export function useUpdatePropertyPool() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePropertyPool,
    //networkMode: offlineFirst,
    onSuccess: () =>{
      queryClient.invalidateQueries({
        queryKey: ["propertypools"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: () => {
      console.log("error occured")
      
    },
  })
}

export function useDeletePropertyPool(){
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePropertyPool,

    onSuccess(){
      queryClient.invalidateQueries({
        queryKey: ["propertypools"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    }
  })

} 
