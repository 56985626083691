import React from "react";
import { useContext, useState, useRef } from "react";
import { useUpdateEffect } from "primereact/hooks";
import { useCitation } from "../../hooks/citationQueries";

import { useReactToPrint } from "react-to-print";
import { useLocalState } from "../../hooks/useLocalState";
import secureLocalStorage from "react-secure-storage";

import VisibleContext from "../../context/VisibleContext";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import * as moment from "moment";
import { utcToLocale } from "../../helpers/helpers";
import { LOGO } from "../../utils/Constansts";
import { v4 as uuidv4 } from "uuid";


export const CitationPrintable = () => {
  const printRef = useRef();
  const { visibleCitationContext} =useContext(VisibleContext);
  const [visibleCitation, setVisibleCitation] = visibleCitationContext;

  const [visible, setVisible] = useState(true);

  const [types, setTypes] = useState([]);
  const [property, setProperty] = useState("");

  const [images, setImages] = useState([]);
  const [imagesUrls, setImagesUrls] = useState([]);

  let userContextProperty = secureLocalStorage.getItem("userContextProperty");
  const [vehicleCitationTypes] = useLocalState("", "vehicleCitationTypes");
  const [userProperties] = useLocalState("","userProperties");

  const {
    isPending,
    isSuccess: isSuccessCitation,
    isError,
    data: fetchedCitation,
    error,
  } = useCitation(visibleCitation.itemId,userContextProperty);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useUpdateEffect(() => {
    if (isSuccessCitation) {
   

   
      setTypes(fetchedCitation?.data.data.citation_types);
      userProperties.map((property) => {
        if (property.property_id == fetchedCitation?.data.data.property_id) {
          setProperty(property.name);
          return;
        }
      });

      //setValue('images',fetchedCitation?.data.data.thumb_images)
      setImagesUrls(fetchedCitation?.data.data.thumb_images);
    }
  }, [fetchedCitation, isSuccessCitation]);



  //console.log(props.citationId)
  //console.log(userProperties.find((property) => property.property_id == propertyId))

  return (
    <>
      {isSuccessCitation ? (
      <Dialog
        className="sm:w-5 md:w-32rem lg:w-32rem xl:w-5"
        closable
        closeOnEscape
        dismissableMask
        visible={visibleCitation.visible}
        //style={{ width: "50vw" }}
        onHide={() => setVisibleCitation({visible: false})}
      >
        <div ref={printRef} className="print_citation" id="print">
          <div className="field">
            <div className="formgrid grid">
              <div className="field col-3">
                <img
                  width="120"
                  height="120"
                  alt="logo"
                  src={LOGO}
                  className="chakra-image css-1i779ob"
                />
              </div>
              <div className="field col-9 ">
                <div className="text-center vertical-align-middle">
                  <span className="text-lg mb-2 block">NOTICE OF </span>
                  <span className="text-xl mb-2 block font-bold">
                    PARKING VIOLATION
                  </span>
                  <span className="text-sm mb-2 block">ISSUED BY</span>
                  <span className="text-base mb-2 block font-bold">
                    RUSSELL PRIVATE SECURITY
                  </span>
                  <span className="text-base mb-2 block">PPO 12800</span>
                  <span className="text-base mb-2 block">(909) 608-7233</span>
                  <span className="text-sm block">
                    WITH AUTHORITY OF THE HOME OWNERS ASSOCIATION
                  </span>
                
                </div>
              </div>
            </div>
          </div>

          <span className="block w-full border-top-1">&nbsp;</span>
          
          <div className="field">
            <span className="text-sm mb-4 block">
              Your vehicle is illegally parked for one or more of the following
              reasons under the authority of your Homeowners Association’s rules
              and regulations and/or CVC22658(a) and may be subject to being towed
              at the owner’s expense. If you believe this citation was issued in
              error, please contact Russell Private Security immediately;
              otherwise it will be deemed valid by omission.
            </span>

            <div className="formgrid grid">
              <div className="field col-2">
                <span className="text-sm  ">Contract:</span>
              </div>
              <div className="field col-10">
                <div className="text-base block w-full border-bottom-1 font-medium">
                  &nbsp;{property}
                </div>
              </div>
            </div>

            <div className="formgrid grid">
              <div className="field col-2">
                <span className="text-sm  ">Location: </span>
              </div>
              <div className="field col-10">
                <div className="text-base block w-full border-bottom-1 font-medium">
                &nbsp;{fetchedCitation?.data.data.gps_coordinates}
                </div>
              </div>
            </div>

            <div className="formgrid grid">
              <div className="field col-2">
                <span className="text-sm  ">Date: </span>
              </div>
              <div className="field col-4">
                <div className="text-base block w-full border-bottom-1 text-center font-medium">
                  {moment(utcToLocale(fetchedCitation?.data.data.violation_date)).format("yyyy-MM-DD")}
                </div>
              </div>
              <div className="field col-2">
                <span className="text-sm  ">Time: </span>
              </div>
              <div className="field col-4">
                <div className="text-base block w-full border-bottom-1 text-center font-medium">
                {moment(utcToLocale(fetchedCitation?.data.data.violation_date)).format("HH:mm A")}
                </div>
              </div>
            </div>

            <div className="formgrid grid border-top-1 border-left-1 border-right-1">
              <div className="p-2 col-6">
                <span className="text-sm  ">Make:</span>
                  <span className="text-base font-medium">  {fetchedCitation?.data.data.vehicle.make}</span>
              </div>
              <div className="border-left-1 p-2 col-6">
                <span className="text-sm  ">Model:</span>
                  <span className="text-base font-medium">  {fetchedCitation?.data.data.vehicle.model}</span>
              </div>
              
            </div>

            <div className="formgrid grid border-top-1 border-left-1 border-right-1">
              <div className="p-2 col-6">
                <span className="text-sm  ">Color:</span>
                  <span className="text-base font-medium">  {fetchedCitation?.data.data.vehicle.color}</span>
              </div>       
              <div className="border-left-1 p-2 col-6">              
                  <span className="text-sm ">License:</span>
                  <span className="text-base font-medium">  {fetchedCitation?.data.data.vehicle.license_plate}</span> 
                  <span className="text-sm ">&nbsp;&nbsp;&nbsp;&nbsp;ST:</span>
                  <span className="text-base font-medium">  {fetchedCitation?.data.data.vehicle.state}</span>
              </div>
            </div>

            <div className="formgrid grid border-top-1 border-left-1 border-right-1">    
              <div className="p-2 col-6">              
                  <span className="text-sm ">Reg. Month::</span>
                  <span className="text-base font-medium">  xx</span> 
                  <span className="text-sm ">&nbsp;&nbsp;&nbsp;&nbsp;YR:</span>
                  <span className="text-base font-medium">  xx</span>
              </div>
              <div className="border-left-1 p-2 col-6">
                <span className="text-sm  ">Vehicle towed:</span>
                  <span className="text-base font-medium">  xx</span>
              </div> 
            </div>

            <div className="formgrid grid border-top-1 border-left-1 border-right-1">
              <div className="p-2 col-6">
                <span className="text-sm  ">Tow Co. & PH#:</span>
                  <span className="text-base font-medium">  xx</span>
              </div>   
            </div>    

            <div className="formgrid grid border-1">
              <div className="p-2 col-6">
                <span className="text-sm  ">Officer:</span>
                  <span className="text-base font-medium">  {fetchedCitation?.data.data.officer_name}</span>
              </div>   
            </div>       

            <br />

            <div className="">
              {types?.map((type) => (
             
                <div key={uuidv4()} className="formgrid grid  pr-2">
                  <div className="p-2 col-1 border-1 text-center vertical-align-middle">
                    <span className="text-base  ">
                      {type.used == 1
                        ? "X"
                        : null}
                    </span>
                  </div>
                  <div className="p-2 col-11 ">
                    <div className="text-sm block w-full ">
                      {type.type}
                    </div>
                  </div>
                </div>
     
              ))}
            </div>

            <br />

            <div className="formgrid grid">
              <div className="p-2 col-4">
                <span className="text-sm  ">Prior Violations Applicable: </span>
              </div>
              <div className="p-2 col-2">
                <div className="text-base block w-full font-medium">{fetchedCitation?.data.data.prior_violations}</div>
              </div>
              <div className="p-2 col-1 border-2 align-content-center ">
                <div className="text-base block w-full ">{fetchedCitation?.data.data.citations_left == 1 ? "X": null}</div>
              </div>
              <div className="p-2 col-5">
                <span className="text-bold ">
                  FINAL WARNING 
                </span><br></br>
                <span className="text-bold ">
                  <small> {fetchedCitation?.data.data.citations_left == 1 ? "Vehicle may be towed on the next violation": null} </small>
                </span>
              </div>
            </div>

            <br />

            <div className="formgrid grid">
              <div className="p-2 col-2">
                <span className="text-sm  ">Notes </span>
              </div>
              <div className="col-12">
                <div className="text-base block w-full text-sm">{fetchedCitation?.data.data.details}</div>
              </div>
            </div>
          </div>
        </div>
        <Button onClick={handlePrint} label="Print" className="ml-2 hide-on-print" icon="pi pi-print" />
      </Dialog>
      ):null}
    </>
  );
}
