import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchCreateCitations,
  fetchCitations,
  fetchCitation,
  fetchCitationTypes,
  addCitation,
  updateCitation,
  deleteCitation,
} from "../api/citationApi";
import { useLocalState } from "./useLocalState";

let accessToken = JSON.parse(localStorage.getItem("accessToken")) || null


//import { ACCESS_TOKEN } from "../utils/Constansts";

//console.log(ACCESS_TOKEN)


export function useCreateCitations(propertyId) {
  return useQuery({
    queryKey: ["citationCreate",{propertyId}],
    queryFn: () => fetchCreateCitations(propertyId),
    retry: false,   
    onSuccess: () => {
      console.log("ALL GOOD")
    },

  });
}

export function useCitationTypes(violationCreate) {
  return useQuery({
    queryKey: ["citationTypes",violationCreate],
    queryFn: () => fetchCitationTypes(),
    retry: false,   
    enabled: violationCreate,
    onSuccess: () => {
      console.log("ALL GOOD")
    },

  });
}

export function useCitations(rows, page, propertyId, sortField, sortOrder, filters) {
  if(sortOrder == 1){
    sortOrder = "asc"
  }else{
    sortOrder = "desc"
  }

  let queryFilters = filters ? Object.keys(filters).map(
    k => 
        (filters[k].value) ?
          '&' + encodeURIComponent(k) + '=' + encodeURIComponent(filters[k].value)
        : null
        
  ) : '';


  queryFilters = queryFilters.filter(Boolean).join('')

  //console.log(filters)
  //console.log(queryFilters)
  

  return useQuery({
    queryKey: ["citations", { rows, page, propertyId, sortField, sortOrder, queryFilters }],
    queryFn: () => fetchCitations(rows, page, propertyId, sortField, sortOrder, queryFilters),
    retry: false,
    //staleTime: 10,
    refetchInterval:400000,
    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }

  });
}


export function useCitation(itemId, propertyId) {
  return useQuery({
    queryKey: ["citation", { itemId, propertyId }],
    queryFn: () => itemId != null ? fetchCitation(itemId, propertyId) : null,
    retry: false,

    onSuccess: () => {
      console.log("ALL GOOD")
    },
    onError: (error) => {
      console.log("ALL BAD")
      console.error(error.message)
      
    }
  });
}

export const useInsertCitation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addCitation,
    networkMode: "offlineFirst",
    retry: false,
    throwOnError: false,
    onSuccess: () =>{
      queryClient.invalidateQueries({
        queryKey: ["citations"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
      queryClient.invalidateQueries({
        queryKey: ["vehicles_details"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: (error) => {
      console.log(error)
      
    },
  })
}

export function useUpdateCitation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCitation,
    //networkMode: offlineFirst,
    onSuccess: () =>{
      queryClient.invalidateQueries({
        queryKey: ["citations"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
      queryClient.invalidateQueries({
        queryKey: ["vehicles"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: () => {
      console.log("error occured")
      
    },
  })
}

export function useDeleteCitation(){
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteCitation,

    onSuccess(){
      queryClient.invalidateQueries({
        queryKey: ["citations"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
      queryClient.invalidateQueries({
        queryKey: ["vehicles"]
        
      });
    }
  })

} 
