import React,{ useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import nProgress from "nprogress";
import { useAuth } from "../../context/auth";
import { usePosition } from "../../context/position";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { z } from "zod";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { classNames } from "primereact/utils";
import { Divider } from "primereact/divider";

import { toast } from "react-toastify";

import secureLocalStorage from "react-secure-storage";

import { LOGO } from "../../utils/Constansts";

import { useReset } from "../../hooks/loginQueries";

const schema = z.object({
  token: z.string().min(20).max(20),
  password: z.string().min(4),
  password_confirm: z.string().min(4),
}).refine((data) => data.password === data.password_confirm, {
  path: ['password_confirm'],
  message: 'Passwords does not match'
})

const Reset = () => {
  const positionContext = usePosition();

  const { resetPassword } = useAuth();

  const navigate = useNavigate();

  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirm, setPasswordConfirm] = useState("");
  //const [searchParams, setSearchParams] = useSearchParams();
  //const token = searchParams.get('code')
 

  const passwordHeader = <h6>Pick a password</h6>;
  const passwordFooter = (
    <React.Fragment>
      <Divider />
      <p className="mt-2">Suggestions</p>
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>Minimum 8 characters</li>
      </ul>
    </React.Fragment>
  );

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
    setError,
  } = useForm({
    values: {
      token: token,
      password: password,
      password_confirm: password_confirm
    },
    resolver: zodResolver(schema),
  });


    const {
      mutateAsync: mutateReset,
      data: dataReset,
      error: errorReset,
      isError: isErrorReset,
      isSuccess: isSuccessReset,
    } = useReset();


 

    useEffect(() => {
      if (isSuccessReset) {
        console.log(dataReset)
        toast.success(dataReset.data.message);
       
        navigate("/");
    
        
      }
    }, [isSuccessReset]);

  const onSubmit = async (data) => {

    
    try {
      const {
        position: { latitude, longitude },
      } = positionContext;

      const resetData = {
        token: data.token,
        password: data.password,
        password_confirm: data.password_confirm
      }
    
      await mutateReset(resetData);
    
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="">
        <div className="flex justify-content-center align-items-center flex-order-6 py-7  h-screen">
          <div className="card px-8 pb-7 pt-4 text-center mb-5">
            <img src={LOGO} alt="Image" height="100" />
            <h2 className="text-center">Reset</h2>

            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              
            <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="token"
                    control={control}
                    rules={{ required: "Token is required." }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        toggleMask
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    htmlFor="token"
                    className={classNames({ "p-error": errors.token })}
                  >
                    Token *
                  </label>
                </span>
                {errors.token && (
                  <div className="text-red-500">{errors.token.message}</div>
                )}
              </div>


              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: "Password is required." }}
                    render={({ field, fieldState }) => (
                      <Password
                        id={field.name}
                        {...field}
                        toggleMask
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        header={passwordHeader}
                        footer={passwordFooter}
                      />
                    )}
                  />
                  <label
                    htmlFor="password"
                    className={classNames({ "p-error": errors.password })}
                  >
                    Password *
                  </label>
                </span>
                {errors.password && (
                  <div className="text-red-500">{errors.password.message}</div>
                )}
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="password_confirm"
                    control={control}
                    rules={{ 
                      required: "Confirmation password is required."
                    }}
                    render={({ field, fieldState }) => (
                      <Password
                        id={field.name}
                        {...field}
                        toggleMask
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        header={passwordHeader}
                        footer={passwordFooter}
                      />
                    )}
                  />
                  <label
                    htmlFor="password_confirm"
                    className={classNames({ "p-error": errors.password_confirm })}
                  >
                    Confirm Password *
                  </label>
                </span>
                {errors.password_confirm && (
                  <div className="text-red-500">{errors.password_confirm.message}</div>
                )}
              </div>

              <Button
                disabled={isSubmitting}
                type="submit"
                label={isSubmitting ? "Loading..." : "Submit"}
                className="mt-2"
              />
            </form>
            <br />
            {errors.root && (
              <div className="text-red-500">{errors.root.message}</div>
            )}

            {isErrorReset ? (
                <div className="text-red-500 text-left">{errorReset.response.data.message}</div>
              ) : null}
          </div>
        </div>
      </section>
    </>
  );
};

export default Reset;
