import { useContext, useState, useEffect } from "react";
import { useUpdateEffect } from "primereact/hooks";

import VisibleContext from "../../context/VisibleContext";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { z } from "zod";

import { useLocalState } from "../../hooks/useLocalState";
import secureLocalStorage from "react-secure-storage";

import { Sidebar } from "primereact/sidebar";

import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { ProgressSpinner } from "primereact/progressspinner";
import { TabView, TabPanel } from "primereact/tabview";
import { InputSwitch } from "primereact/inputswitch";
import { Message } from "primereact/message";
import { SelectButton } from "primereact/selectbutton";

import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";

import {
  useVehicleMaintenance,
  useInsertVehicleMaintenance,
  useUpdateVehicleMaintenance,
  useVehicleMaintenancTypes,
} from "../../hooks/vehicleMaintenanceQueries";

const schema = z.object({
  name: z.string().min(1),
  propertyTypeId: z.object({
    type: z.string(),
    property_type_id: z.number(),
  }),
  address: z.string().min(1),
  gps_coordinates: z.string().min(1),
  contact_name: z.string().min(1),
  telephone: z.string().min(1),
  email: z.string().min(1),
  contact_is_property_manager: z.boolean(),
  maintenance_email: z.string().min(1),
  emergency_contact: z.string().min(1),
  emergency_telephone: z.string().min(1),
  details: z.string().min(1),
  images: z.any(),

  safelistings: z.boolean(),
  selected_safelisting_option: z.number(),
  selected_safelisting_period_option: z.number(),
  number_of_days: z.number(),
  number_of_parkings: z.number(),

  days_in_a_row: z.number(),

  households: z.boolean(),
  pools: z.boolean(),
  vehicles: z.boolean(),

  users: z.boolean(),
  schedules: z.boolean(),
  shifts: z.boolean(),
  vehicle_maintenance: z.boolean(),

  launch_duration: z.number(),
  break_duration: z.number(),
  allowed_distance: z.number(),

  dars: z.boolean(),
  incident_reports: z.boolean(),
  citations: z.boolean(),
  pool_access_log: z.boolean(),
});

const PropertySheet = ({ isVisible, propertyId, onClose }) => {
  const [userData] = useLocalState("", "userData");

  const [roles] = userData[0].user.roles;
  const permissions = roles?.permissions || null;

  let userContextProperty = secureLocalStorage.getItem("userContextProperty");
  const [propertyTypes] = useLocalState("", "propertyTypes");

  const { VisibleFormContext, visibleLoadingModalContext } =
    useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleLoadingModal, setVisibleLoadingModal] =
    visibleLoadingModalContext;

  const [name, setName] = useState("");
  const [propertyType, setType] = useState(propertyTypes[0]);
  const [address, setAddress] = useState("");
  const [gps_coordinates, setGpsCoordinates] = useState("");
  const [contact_name, setContactName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [contact_is_property_manager, setContactIsPropertyManager] =
    useState(true);

  const [safelistings, setSafelistings] = useState(false);

  const safelistings_options = [
    { name: "Household", value: 1, className: "text-xs p-2 pr-3" },
    { name: "Plate", value: 2, className: "text-xs p-2" },
  ];
  const [selected_safelisting_option, setSelectedSafelistingOption] = useState(
    safelistings_options[0].value
  );

  const safelisting_period_options = [
    {
      name: "Rolling window",
      value: 1,
      className: "text-xs p-2 white-space-nowrap inline-flex",
    },
    {
      name: "Calendar month",
      value: 2,
      className: "text-xs p-2 white-space-nowrap inline-flex",
    },
    {
      name: "Calendar year",
      value: 3,
      className: "text-xs p-2 white-space-nowrap inline-flex",
    },
  ];
  const [
    selected_safelisting_period_option,
    setSelectedSafelistingPeriodOption,
  ] = useState(safelisting_period_options[0].value);
  const [number_of_parkings, setNumberOfParkings] = useState(0);
  const [number_of_days, setNumberOfDays] = useState(0);
  const [days_in_a_row, setDaysInARow] = useState(0);

  const [households, setHouseholds] = useState(false);
  const [pools, setPools] = useState(false);
  const [vehicles, setVehicles] = useState(false);

  const [users, setUsers] = useState(false);
  const [schedules, setSchedules] = useState(false);
  const [shifts, setShifts] = useState(false);
  const [vehicle_maintenance, setVehicleMaintenance] = useState(false);

  const launch_deductible_options = [
    { name: "Yes", value: 1, className: "text-xs p-2 pr-3" },
    { name: "No", value: 0, className: "text-xs p-2" },
  ];
  const [selected_launch_deductible_option, setSelectedLaunchDeductibleOption] =
    useState(launch_deductible_options[0].value);

  const [launch_duration, setLaunchkDuration] = useState(0);
  const [break_duration, setBreakDuration] = useState(0);
  const [allowed_distance, setAllowedDistance] = useState(0);

  const [dars, setDars] = useState(false);
  const [incident_reports, setIncidentReports] = useState(false);
  const [citations, setCitations] = useState(false);
  const [pool_access_log, setPoolAccessLogs] = useState(false);

  const permits_by_options = [
    { name: "Household", value: 1, className: "text-xs p-2 pr-3" },
    { name: "Plate", value: 0, className: "text-xs p-2" },
  ];
  const [permits_by, setPermitsBy] = useState(permits_by_options[0].value);

  const citations_apply_by_options = [
    { name: "Individual rules", value: 1, className: "text-xs p-2 pr-3" },
    { name: "All the same", value: 0, className: "text-xs p-2" },
  ];
  const [citations_apply, setCitationsApplyBy] = useState(
    citations_apply_by_options[0].value
  );

  const period_by_options = [
    { name: "Rolling window", value: 1, className: "text-xs p-2 pr-3" },
    { name: "Calendar month", value: 0, className: "text-xs p-2" },
    { name: "Calendar year", value: 0, className: "text-xs p-2" },
  ];
  const [period_by, setPeriodBy] = useState(period_by_options[0].value);

  const [maintenance_email, setMaintenanceEmail] = useState("");
  const [emergency_contact, setEmergencyContact] = useState("");
  const [emergency_telephone, setEmergencyTelephone] = useState("");
  const [details, setDetails] = useState("");

  const [imagesUploaded, setImagesUploaded] = useState([]);
  const [images, setImages] = useState([]);
  const [imagesUrls, setImagesUrls] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);

  const [activeIndex, setActiveIndex] = useState(0);

  console.log(">>>propertyTypes", propertyTypes);
  const sections = [];
  // const [sections, setSections] = useState(propertyTypes[0].sections);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting, isValid },
    setError,
    setValue,
    form,
    trigger,
  } = useForm({
    values: {
      name: name,
      propertyTypeId: propertyType,
      address: address,
      gps_coordinates: gps_coordinates,
      contact_name: contact_name,
      telephone: telephone,
      email: email,
      contact_is_property_manager: contact_is_property_manager,
      maintenance_email: maintenance_email,
      emergency_contact: emergency_contact,
      emergency_telephone: emergency_telephone,
      details: details,

      safelistings: safelistings,
      selected_safelisting_option: selected_safelisting_option,
      selected_safelisting_period_option: selected_safelisting_period_option,
      number_of_days: number_of_days,
      number_of_parkings: number_of_parkings,

      households: households,
      pools: pools,
      vehicles: vehicles,

      users: users,
      schedules: schedules,
      shifts: shifts,
      vehicle_maintenance: vehicle_maintenance,

      launch_duration: launch_duration,
      break_duration: break_duration,
      allowed_distance: allowed_distance,

      dars: dars,
      incident_reports: incident_reports,
      citations: citations,
      pool_access_log: pool_access_log,
    },

    resolver: zodResolver(schema),
  });

  useUpdateEffect(() => {}, [propertyType]);

  useUpdateEffect(() => {
    setName("");
    setAddress("");
    setGpsCoordinates("");
    setContactName("");
    setContactIsPropertyManager(true);
    setActiveIndex(0);
  }, [isVisible]);

  const minDate = () => {
    if (userData[0].isAdmin || userData[0].isSupervisor) {
      return new Date("2022-01-01");
    } else {
      return new Date();
    }
  };

  const handleHide = () => {
    setVisibleForm({
      visible: false,
      itemId: null,
      title: null,
    });
  };

  const {
    mutateAsync: mutateInsertVehicleMaintenance,
    data: addedVehicleMaintenance,
    error: errorInsertVehicleMaintenance,
    isError: isErrorInsertVehicleMaintenance,
    isPending: isPendingInsertVehicleMaintenance,
    isSuccess: isSuccessInsertVehicleMaintenance,
  } = useInsertVehicleMaintenance();

  const {
    mutateAsync: mutateUpdateVehicleMaintenance,
    data: updatedVehicleMaintenance,
    error: errorUpdateVehicleMaintenance,
    isError: isErrorUpdateVehicleMaintenance,
    isPending: isPendingUpdateVehicleMaintenance,
    isSuccess: isSuccessUpdateVehicleMaintenance,
  } = useUpdateVehicleMaintenance();

  useUpdateEffect(() => {
    if (
      isSuccessInsertVehicleMaintenance ||
      isSuccessUpdateVehicleMaintenance
    ) {
      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      if (propertyId === null) {
        toast.success(
          `You have created Property Name Maintenance ID: ${addedVehicleMaintenance?.data.data.vehicle_maintenance_id}`,
          TOAST_CONFIG
        );
      } else {
        toast.success(
          `You have updaated Property Name Maintenance ID: ${updatedVehicleMaintenance?.data.data.vehicle_maintenance_id}`,
          TOAST_CONFIG
        );
      }
    }
  }, [addedVehicleMaintenance, updatedVehicleMaintenance]);

  function getType(type_id) {
    return propertyTypes.find((element) => element.property_type_id == type_id);
  }

  useUpdateEffect(() => {
    if (isErrorInsertVehicleMaintenance) {
      console.log(isErrorInsertVehicleMaintenance);
      console.log(errorInsertVehicleMaintenance);

      setVisibleForm({
        visible: false,
        itemId: null,
        title: null,
      });

      toast.success(`You have created a DAR on this device.`, TOAST_CONFIG);
    }
  }, [isErrorInsertVehicleMaintenance]);

  useUpdateEffect(() => {
    if (selected_safelisting_period_option != 1) {
      setNumberOfDays(0);
    }
  }, [selected_safelisting_period_option]);

  const onSubmit = async (data) => {
    console.log(data);
    return;
    try {
      let reponse;

      if (propertyId === null) {
        let json = {
          vehicle_maintenance_date: new Date().toISOString(),
          name: data.name,
          //starting_gas: data.starting_gas,
          starting_vehicle_maintenance_status_gas_type_id:
            data.propertyTypeId.vehicle_maintenance_status_gas_type_id,
          //ending_vehicle_maintenance_status_gas_type_id: data.EndingVehicleMaintenanceStatusGasTypeId.vehicle_maintenance_status_gas_type_id,
          address: data.address,
          gps_coordinates: data.gps_coordinates,
          contact_name: data.contact_name,
          starting_details: data.starting_details,

          property_id: userContextProperty,
          images: images,
          images_urls: imagesUrls,
          images_del: deletedImages,
        };

        reponse = await mutateInsertVehicleMaintenance({
          json: json,
          persistOnStorage: true,
        });
      } else {
        let json = {
          vehicle_maintenance_id: visibleForm.itemId,
          ending_vehicle_maintenance_status_gas_type_id:
            data.EndingVehicleMaintenanceStatusGasTypeId
              .vehicle_maintenance_status_gas_type_id,
          ending_details: data.ending_details,
          ending_mileage: data.ending_mileage,
          property_id: userContextProperty,
        };

        /*json["vehicle_maintenance_id"] = visibleForm.itemId;
        json["ending_vehicle_maintenance_status_gas_type_id"] = data.EndingVehicleMaintenanceStatusGasTypeId.vehicle_maintenance_status_gas_type_id;
        json["ending_details"] = data.ending_details;
        json["ending_mileage"] = data.ending_mileage;*/

        //console.log(json)
        //return

        reponse = await mutateUpdateVehicleMaintenance(json);
      }

      //console.log(reponse);
    } catch (error) {
      console.log(error);
      //setError("root", {message: error,});
    }
  };

  const handleTab1Validation = () => {
    trigger();
    if (isValid) setActiveIndex(1);
  };

  return (
    <>
      <Sidebar
        visible={isVisible}
        position="right"
        onHide={onClose}
        className="w-full md:w-7 lg:w-7"
      >
        {visibleLoadingModal.visible ? (
          <div className="flex flex-wrap justify-content-between gap-2 mb-1">
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ProgressSpinner
                aria-label="Loading"
                style={{ width: "50px", height: "100vm" }}
                strokeWidth="2"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />

              <p>Loading</p>
            </div>
          </div>
        ) : (
          <>
            <h2>{propertyId ? "Create property" : "Update property"}</h2>

            <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
              <TabView
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
              >
                <TabPanel header="Property Information">
                  <div className="field">
                    <div className="formgrid grid">
                      <div className="field col">
                        <span className="p-float-label">
                          <Controller
                            name="name"
                            control={control}
                            rules={{ required: "Property Name is required." }}
                            render={({ field, fieldState }) => (
                              <InputText
                                id={field.name}
                                {...field}
                                value={field.value ?? ""}
                                autoFocus
                                maxLength={50}
                                className={classNames({
                                  "p-invalid": fieldState.invalid,
                                })}
                              />
                            )}
                          />

                          <label htmlFor="name">Property Name</label>
                        </span>
                        <small className="block pt-1">
                          {errors.name && (
                            <div className="text-red-500">
                              {errors.name.message}
                            </div>
                          )}
                        </small>
                      </div>

                      <div className="field col">
                        <span className="p-float-label">
                          <Controller
                            control={control}
                            name="PropertyTypeId"
                            rules={{
                              validate: ({ value } = {}) =>
                                value !== undefined || "You must select a type",
                              valueAsNumber: true,
                            }}
                            render={({ field }) => (
                              <Dropdown
                                id={field.name}
                                filter
                                placeholder="Select a type"
                                optionLabel="type"
                                options={propertyTypes}
                                {...field}
                                //optionValue={type}
                                //value = {field.value}
                              />
                            )}
                          />
                          {errors.PropertyTypeId && (
                            <div className="text-red-500">
                              {errors.PropertyTypeId.message}
                            </div>
                          )}

                          <label htmlFor="type">Type</label>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <div className="formgrid grid">
                      <div className="field col">
                        <span className="p-float-label">
                          <Controller
                            name={"address"}
                            control={control}
                            rules={{ required: "Address is required.", min: 0 }}
                            render={({ field, fieldState }) => (
                              <InputText
                                id={field.name}
                                {...field}
                                value={field.value ?? ""}
                                maxLength={50}
                                className={classNames({
                                  "p-invalid": fieldState.invalid,
                                })}
                              />
                            )}
                          />

                          <label htmlFor="address">Address</label>
                        </span>
                        <small className="block pt-1">
                          {errors.address && (
                            <div className="text-red-500">
                              {errors.address.message}
                            </div>
                          )}
                        </small>
                      </div>

                      <div className="field col">
                        <span className="p-float-label">
                          <Controller
                            name="gps_coordinates"
                            control={control}
                            rules={{ required: "GPS Coordinates is required." }}
                            render={({ field, fieldState }) => (
                              <InputText
                                id={field.name}
                                {...field}
                                value={field.value ?? ""}
                                maxLength={50}
                                className={classNames({
                                  "p-invalid": fieldState.invalid,
                                })}
                              />
                            )}
                          />

                          <label htmlFor="gps_coordinates">
                            GPS Coordinates
                          </label>
                        </span>
                        <small className="block pt-1">
                          {errors.gps_coordinates && (
                            <div className="text-red-500">
                              {errors.gps_coordinates.message}
                            </div>
                          )}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        name="contact_name"
                        control={control}
                        rules={{ required: "Contact Name is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            value={field.value ?? ""}
                            maxLength={50}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      <label htmlFor="name">Contact Name</label>
                    </span>
                    <small className="block pt-1">
                      {errors.contact_name && (
                        <div className="text-red-500">
                          {errors.contact_name.message}
                        </div>
                      )}
                    </small>
                  </div>

                  <div className="field">
                    <div className="formgrid grid">
                      <div className="field col">
                        <span className="p-float-label">
                          <Controller
                            name={"telephone"}
                            control={control}
                            rules={{
                              required: "Contact Telephone is required.",
                              min: 0,
                            }}
                            render={({ field, fieldState }) => (
                              <InputText
                                id={field.name}
                                {...field}
                                value={field.value ?? ""}
                                maxLength={50}
                                className={classNames({
                                  "p-invalid": fieldState.invalid,
                                })}
                              />
                            )}
                          />

                          <label htmlFor="telephone">Contact Telephone</label>
                        </span>
                        <small className="block pt-1">
                          {errors.telephone && (
                            <div className="text-red-500">
                              {errors.telephone.message}
                            </div>
                          )}
                        </small>
                      </div>

                      <div className="field col">
                        <span className="p-float-label">
                          <Controller
                            name="email"
                            control={control}
                            rules={{ required: "Contact Email is required." }}
                            render={({ field, fieldState }) => (
                              <InputText
                                id={field.name}
                                {...field}
                                value={field.value ?? ""}
                                maxLength={50}
                                className={classNames({
                                  "p-invalid": fieldState.invalid,
                                })}
                              />
                            )}
                          />

                          <label htmlFor="email">Contact Email</label>
                        </span>
                        <small className="block pt-1">
                          {errors.email && (
                            <div className="text-red-500">
                              {errors.email.message}
                            </div>
                          )}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="contact_is_property_manager"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <InputSwitch
                            onBlur={onBlur} // notify when input is touched
                            onChange={(e) =>
                              setContactIsPropertyManager(e.value)
                            } // send value to hook form
                            checked={value}
                            inputRef={ref}
                          />
                        )}
                      />

                      <label
                        htmlFor="contact_is_property_manager"
                        className="ml-2"
                      >
                        &nbsp; &nbsp; &nbsp; &nbsp; The contact is the Property
                        Manager
                      </label>
                    </span>
                    <small className="block pt-1">
                      {errors.contact_is_property_manager && (
                        <div className="text-red-500">
                          {errors.contact_is_property_manager.message}
                        </div>
                      )}
                    </small>
                  </div>

                  <div className="field">
                    <div className="formgrid grid">
                      <div className="field col">
                        <span className="p-float-label">
                          <Controller
                            name={"maintenance_email"}
                            control={control}
                            rules={{
                              required: "Maintenance email is required.",
                              min: 0,
                            }}
                            render={({ field, fieldState }) => (
                              <InputText
                                id={field.name}
                                {...field}
                                value={field.value ?? ""}
                                maxLength={50}
                                className={classNames({
                                  "p-invalid": fieldState.invalid,
                                })}
                              />
                            )}
                          />

                          <label htmlFor="maintenance_email">
                            Maintenance email
                          </label>
                        </span>
                        <small className="block pt-1">
                          {errors.maintenance_email && (
                            <div className="text-red-500">
                              {errors.maintenance_email.message}
                            </div>
                          )}
                        </small>
                      </div>

                      <div className="field col">
                        <span className="p-float-label">
                          <Controller
                            name="emergency_contact"
                            control={control}
                            rules={{
                              required: "Emergency Contact is required.",
                            }}
                            render={({ field, fieldState }) => (
                              <InputText
                                id={field.name}
                                {...field}
                                value={field.value ?? ""}
                                maxLength={50}
                                className={classNames({
                                  "p-invalid": fieldState.invalid,
                                })}
                              />
                            )}
                          />

                          <label htmlFor="emergency_contact">
                            Emergency Contact
                          </label>
                        </span>
                        <small className="block pt-1">
                          {errors.emergency_contact && (
                            <div className="text-red-500">
                              {errors.emergency_contact.message}
                            </div>
                          )}
                        </small>
                      </div>

                      <div className="field col">
                        <span className="p-float-label">
                          <Controller
                            name="emergency_telephone"
                            control={control}
                            rules={{
                              required: "Emergency Telephone is required.",
                            }}
                            render={({ field, fieldState }) => (
                              <InputText
                                id={field.name}
                                {...field}
                                value={field.value ?? ""}
                                maxLength={50}
                                className={classNames({
                                  "p-invalid": fieldState.invalid,
                                })}
                              />
                            )}
                          />

                          <label htmlFor="emergency_telephone">
                            Emergency Telephone
                          </label>
                        </span>
                        <small className="block pt-1">
                          {errors.emergency_telephone && (
                            <div className="text-red-500">
                              {errors.emergency_telephone.message}
                            </div>
                          )}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        name="details"
                        control={control}
                        rules={{ required: "Notes is required." }}
                        render={({ field, fieldState }) => (
                          <InputTextarea
                            id={field.name}
                            {...field}
                            value={field.value ?? ""}
                            autoFocus
                            maxLength={500}
                            rows={5}
                            cols={30}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      <label htmlFor="details">
                        Notes <small>(max 500 characters)</small>
                      </label>
                    </span>
                    <small className="block pt-1">
                      {errors.details && (
                        <div className="text-red-500">
                          {errors.details.message}
                        </div>
                      )}
                    </small>
                  </div>

                  {!contact_is_property_manager ? (
                    <Message
                      severity="warn"
                      className=" w-full justify-content-start"
                      text="Remember that you have to assign a Property Manager to the property from the user section"
                    />
                  ) : null}
                </TabPanel>
                <TabPanel
                  header="Property set up"
                  disabled={isValid ? false : false}
                >
                  <div className="field text-xs">
                    <div className="field">
                      <span className="align-items-center inline-flex ">
                        <h3>Property Management Sections</h3>
                      </span>
                    </div>

                    <div className="field">
                      <div className="formgrid grid">
                        <div className="field col">
                          <span className="p-float-label">
                            <Controller
                              control={control}
                              name="safelistings"
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: {
                                  invalid,
                                  isTouched,
                                  isDirty,
                                  error,
                                },
                                formState,
                              }) => (
                                <InputSwitch
                                  onBlur={onBlur} // notify when input is touched
                                  onChange={(e) => setSafelistings(e.value)} // send value to hook form
                                  checked={value}
                                  inputRef={ref}
                                />
                              )}
                            />

                            <label htmlFor="safelistings" className="ml-2">
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Safelistings
                            </label>
                          </span>
                          <small className="block pt-1">
                            {errors.safelistings && (
                              <div className="text-red-500">
                                {errors.safelistings.message}
                              </div>
                            )}
                          </small>
                        </div>

                        <div className="field col">
                          <span className="p-float-label">
                            <Controller
                              control={control}
                              name="households"
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: {
                                  invalid,
                                  isTouched,
                                  isDirty,
                                  error,
                                },
                                formState,
                              }) => (
                                <InputSwitch
                                  onBlur={onBlur} // notify when input is touched
                                  onChange={(e) => setHouseholds(e.value)} // send value to hook form
                                  checked={value}
                                  inputRef={ref}
                                />
                              )}
                            />

                            <label htmlFor="households" className="ml-2">
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Households
                            </label>
                          </span>
                          <small className="block pt-1">
                            {errors.households && (
                              <div className="text-red-500">
                                {errors.households.message}
                              </div>
                            )}
                          </small>
                        </div>

                        <div className="field col">
                          <span className="p-float-label">
                            <Controller
                              control={control}
                              name="pools"
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: {
                                  invalid,
                                  isTouched,
                                  isDirty,
                                  error,
                                },
                                formState,
                              }) => (
                                <InputSwitch
                                  onBlur={onBlur} // notify when input is touched
                                  onChange={(e) => setPools(e.value)} // send value to hook form
                                  checked={value}
                                  inputRef={ref}
                                />
                              )}
                            />

                            <label htmlFor="pools" className="ml-2">
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Pools
                            </label>
                          </span>
                          <small className="block pt-1">
                            {errors.pools && (
                              <div className="text-red-500">
                                {errors.pools.message}
                              </div>
                            )}
                          </small>
                        </div>

                        <div className="field col">
                          <span className="p-float-label">
                            <Controller
                              control={control}
                              name="vehicles"
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: {
                                  invalid,
                                  isTouched,
                                  isDirty,
                                  error,
                                },
                                formState,
                              }) => (
                                <InputSwitch
                                  onBlur={onBlur} // notify when input is touched
                                  onChange={(e) => setVehicles(e.value)} // send value to hook form
                                  checked={value}
                                  inputRef={ref}
                                />
                              )}
                            />

                            <label htmlFor="vehicles" className="ml-2">
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Vehicles
                            </label>
                          </span>
                          <small className="block pt-1">
                            {errors.vehicles && (
                              <div className="text-red-500">
                                {errors.vehicles.message}
                              </div>
                            )}
                          </small>
                        </div>
                      </div>
                    </div>

                    {safelistings ? (
                      <>
                        <div className="field">
                          <div className="formgrid grid">
                            <div className="field col">
                              <Controller
                                control={control}
                                name="selected_safelisting_option"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                  fieldState: {
                                    invalid,
                                    isTouched,
                                    isDirty,
                                    error,
                                  },
                                  formState,
                                }) => (
                                  <div className="flex align-items-center text-xs">
                                    <label
                                      htmlFor="selected_safelisting_option"
                                      className="white-space-nowrap"
                                    >
                                      Safelistings by &nbsp; &nbsp;
                                    </label>
                                    <SelectButton
                                      value={selected_safelisting_option}
                                      onChange={(e) =>
                                        setSelectedSafelistingOption(e.value)
                                      }
                                      optionLabel="name"
                                      className={safelistings_options.className}
                                      options={safelistings_options}
                                    />
                                  </div>
                                )}
                              />
                            </div>

                            <div className="field col">
                              <Controller
                                control={control}
                                name="selected_safelisting_period_option"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                  fieldState: {
                                    invalid,
                                    isTouched,
                                    isDirty,
                                    error,
                                  },
                                  formState,
                                }) => (
                                  <div className="flex align-items-center text-xs">
                                    <label
                                      htmlFor="selected_safelisting_period_option"
                                      className="white-space-nowrap"
                                    >
                                      Period by &nbsp; &nbsp;&nbsp;
                                    </label>
                                    <SelectButton
                                      value={selected_safelisting_period_option}
                                      onChange={(e) =>
                                        setSelectedSafelistingPeriodOption(
                                          e.value
                                        )
                                      }
                                      optionLabel="name"
                                      className={
                                        safelisting_period_options.className
                                      }
                                      options={safelisting_period_options}
                                    />
                                  </div>
                                )}
                              />
                            </div>

                            <div className="field col">
                              <Controller
                                name={"number_of_days"}
                                control={control}
                                rules={{
                                  required: "Number of days is required.",
                                  min: 0,
                                  max: 100,
                                }}
                                render={({ field, fieldState }) => (
                                  <InputNumber
                                    id={field.name}
                                    ref={field.ref}
                                    value={field.value}
                                    onBlur={field.onBlur}
                                    onValueChange={(e) => field.onChange(e)}
                                    suffix=" days"
                                    className="w-6rem h-1rem "
                                    min={0}
                                    max={100}
                                    useGrouping={false}
                                    disabled={
                                      selected_safelisting_period_option == 1
                                        ? false
                                        : true
                                    }
                                    inputClassName="text-xs text-center"
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="field">
                          <div className="formgrid grid ">
                            <div className="field col ">
                              <Controller
                                name={"number_of_parkings"}
                                control={control}
                                rules={{
                                  required: "Number of days is required.",
                                  min: 0,
                                  max: 100,
                                }}
                                render={({ field, fieldState }) => (
                                  <div className="align-items-center text-xs">
                                    <label htmlFor="selected_safelisting_option">
                                      Number of parking permits granted per
                                      selected period &nbsp; &nbsp;
                                    </label>
                                    <InputNumber
                                      id={field.name}
                                      ref={field.ref}
                                      value={field.value}
                                      onBlur={field.onBlur}
                                      onValueChange={(e) => field.onChange(e)}
                                      className="w-6rem h-1rem "
                                      min={0}
                                      max={100}
                                      useGrouping={false}
                                      inputClassName="text-xs text-center"
                                    />
                                    <small className="block pt-1">
                                      {errors.number_of_parkings && (
                                        <div className="text-red-500">
                                          {errors.number_of_parkings.message}
                                        </div>
                                      )}
                                    </small>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="field">
                          <div className="formgrid grid ">
                            <div className="field col ">
                              <Controller
                                name={"days_in_a_row"}
                                control={control}
                                rules={{
                                  required: "Number of days is required.",
                                  min: 0,
                                  max: 100,
                                }}
                                render={({ field, fieldState }) => (
                                  <div className="align-items-center text-xs">
                                    <label htmlFor="selected_safelisting_option">
                                      How many days in a row can be requested?
                                      &nbsp; &nbsp;
                                    </label>
                                    <InputNumber
                                      id={field.name}
                                      ref={field.ref}
                                      value={field.value}
                                      onBlur={field.onBlur}
                                      onValueChange={(e) => field.onChange(e)}
                                      className="w-6rem h-1rem "
                                      min={0}
                                      max={100}
                                      useGrouping={false}
                                      inputClassName="text-xs text-center"
                                    />
                                    <small className="block pt-1">
                                      {errors.days_in_a_row && (
                                        <div className="text-red-500">
                                          {errors.days_in_a_row.message}
                                        </div>
                                      )}
                                    </small>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>

                  <div className="field text-xs">
                    <div className="field">
                      <span className="align-items-center inline-flex ">
                        <h3>User Management Sections</h3>
                      </span>
                    </div>

                    <div className="field">
                      <div className="formgrid grid">
                        <div className="field col">
                          <span className="p-float-label">
                            <Controller
                              control={control}
                              name="users"
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: {
                                  invalid,
                                  isTouched,
                                  isDirty,
                                  error,
                                },
                                formState,
                              }) => (
                                <InputSwitch
                                  onBlur={onBlur} // notify when input is touched
                                  onChange={(e) => setUsers(e.value)} // send value to hook form
                                  checked={value}
                                  inputRef={ref}
                                />
                              )}
                            />

                            <label htmlFor="users" className="ml-2">
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Users
                            </label>
                          </span>
                          <small className="block pt-1">
                            {errors.users && (
                              <div className="text-red-500">
                                {errors.users.message}
                              </div>
                            )}
                          </small>
                        </div>

                        <div className="field col">
                          <span className="p-float-label">
                            <Controller
                              control={control}
                              name="schedules"
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: {
                                  invalid,
                                  isTouched,
                                  isDirty,
                                  error,
                                },
                                formState,
                              }) => (
                                <InputSwitch
                                  onBlur={onBlur} // notify when input is touched
                                  onChange={(e) => setSchedules(e.value)} // send value to hook form
                                  checked={value}
                                  inputRef={ref}
                                />
                              )}
                            />

                            <label htmlFor="schedules" className="ml-2">
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Schedules
                            </label>
                          </span>
                          <small className="block pt-1">
                            {errors.schedules && (
                              <div className="text-red-500">
                                {errors.schedules.message}
                              </div>
                            )}
                          </small>
                        </div>

                        <div className="field col">
                          <span className="p-float-label">
                            <Controller
                              control={control}
                              name="shifts"
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: {
                                  invalid,
                                  isTouched,
                                  isDirty,
                                  error,
                                },
                                formState,
                              }) => (
                                <InputSwitch
                                  onBlur={onBlur} // notify when input is touched
                                  onChange={(e) => setShifts(e.value)} // send value to hook form
                                  checked={value}
                                  inputRef={ref}
                                />
                              )}
                            />

                            <label htmlFor="shifts" className="ml-2">
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Shifts
                            </label>
                          </span>
                          <small className="block pt-1">
                            {errors.shifts && (
                              <div className="text-red-500">
                                {errors.shifts.message}
                              </div>
                            )}
                          </small>
                        </div>

                        <div className="field col">
                          <span className="p-float-label">
                            <Controller
                              control={control}
                              name="vehicle_maintenance"
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: {
                                  invalid,
                                  isTouched,
                                  isDirty,
                                  error,
                                },
                                formState,
                              }) => (
                                <InputSwitch
                                  onBlur={onBlur} // notify when input is touched
                                  onChange={(e) =>
                                    setVehicleMaintenance(e.value)
                                  } // send value to hook form
                                  checked={value}
                                  inputRef={ref}
                                />
                              )}
                            />

                            <label
                              htmlFor="vehicle_maintenance"
                              className="ml-2"
                            >
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Vehicle
                              Maintenance
                            </label>
                          </span>
                          <small className="block pt-1">
                            {errors.vehicle_maintenance && (
                              <div className="text-red-500">
                                {errors.vehicle_maintenance.message}
                              </div>
                            )}
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="field">
                      <div className="formgrid grid">
                        <div className="field col">
                          <Controller
                            control={control}
                            name="selected_launch_deductible_option"
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: {
                                invalid,
                                isTouched,
                                isDirty,
                                error,
                              },
                              formState,
                            }) => (
                              <div className="flex align-items-center text-xs">
                                <label
                                  htmlFor="selected_launch_deductible_option"
                                  className="white-space-nowrap"
                                >
                                  Launch deductible &nbsp; &nbsp;
                                </label>
                                <SelectButton
                                  value={selected_launch_deductible_option}
                                  onChange={(e) =>
                                    setSelectedLaunchDeductibleOption(e.value)
                                  }
                                  optionLabel="name"
                                  className={
                                    launch_deductible_options.className
                                  }
                                  options={launch_deductible_options}
                                />
                              </div>
                            )}
                          />
                        </div>

                        <div className="field col">
                          <Controller
                            name={"launch_duration"}
                            control={control}
                            rules={{
                              required: "Launch duration is required.",
                              min: 0,
                              max: 100,
                            }}
                            render={({ field, fieldState }) => (
                              <div className="align-items-center text-xs">
                                <label htmlFor="launch_duration">
                                  Launch duration &nbsp; &nbsp;
                                </label>
                                <InputNumber
                                  id={field.name}
                                  ref={field.ref}
                                  value={field.value}
                                  onBlur={field.onBlur}
                                  onValueChange={(e) => field.onChange(e)}
                                  className="w-6rem h-1rem "
                                  min={0}
                                  max={100}
                                  useGrouping={false}
                                  inputClassName="text-xs text-center"
                                />
                                <small className="block pt-1">
                                  {errors.launch_duration && (
                                    <div className="text-red-500">
                                      {errors.launch_duration.message}
                                    </div>
                                  )}
                                </small>
                              </div>
                            )}
                          />
                        </div>

                        <div className="field col">
                          <Controller
                            name={"break_duration"}
                            control={control}
                            rules={{
                              required: "Break duration is required.",
                              min: 0,
                              max: 100,
                            }}
                            render={({ field, fieldState }) => (
                              <div className="align-items-center text-xs">
                                <label htmlFor="break_duration">
                                  Breaks duration &nbsp; &nbsp;
                                </label>
                                <InputNumber
                                  id={field.name}
                                  ref={field.ref}
                                  value={field.value}
                                  onBlur={field.onBlur}
                                  onValueChange={(e) => field.onChange(e)}
                                  className="w-6rem h-1rem "
                                  min={0}
                                  max={100}
                                  useGrouping={false}
                                  inputClassName="text-xs text-center"
                                />
                                <small className="block pt-1">
                                  {errors.break_duration && (
                                    <div className="text-red-500">
                                      {errors.break_duration.message}
                                    </div>
                                  )}
                                </small>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="field">
                      <div className="formgrid grid ">
                        <div className="field col ">
                          <Controller
                            name={"allowed_distance"}
                            control={control}
                            rules={{
                              required: "Allowed distance is required.",
                              min: 0,
                              max: 100,
                            }}
                            render={({ field, fieldState }) => (
                              <div className="align-items-center text-xs">
                                <label htmlFor="allowed_distance">
                                  Allowed distance loging from property &nbsp;
                                  &nbsp;
                                </label>
                                <InputNumber
                                  id={field.name}
                                  ref={field.ref}
                                  value={field.value}
                                  onBlur={field.onBlur}
                                  onValueChange={(e) => field.onChange(e)}
                                  className="w-6rem h-1rem "
                                  min={0}
                                  max={100}
                                  useGrouping={false}
                                  inputClassName="text-xs text-center"
                                />
                                <small className="block pt-1">
                                  {errors.allowed_distance && (
                                    <div className="text-red-500">
                                      {errors.allowed_distance.message}
                                    </div>
                                  )}
                                </small>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field text-xs">
                    <div className="field">
                      <span className="align-items-center inline-flex ">
                        <h3>Report Sections</h3>
                      </span>
                    </div>

                    <div className="field">
                      <div className="formgrid grid">
                        <div className="field col">
                          <span className="p-float-label">
                            <Controller
                              control={control}
                              name="dars"
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: {
                                  invalid,
                                  isTouched,
                                  isDirty,
                                  error,
                                },
                                formState,
                              }) => (
                                <InputSwitch
                                  onBlur={onBlur} // notify when input is touched
                                  onChange={(e) => setDars(e.value)} // send value to hook form
                                  checked={value}
                                  inputRef={ref}
                                />
                              )}
                            />

                            <label htmlFor="dars" className="ml-2">
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; DARs
                            </label>
                          </span>
                          <small className="block pt-1">
                            {errors.dars && (
                              <div className="text-red-500">
                                {errors.dars.message}
                              </div>
                            )}
                          </small>
                        </div>

                        <div className="field col">
                          <span className="p-float-label">
                            <Controller
                              control={control}
                              name="incident_reports"
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: {
                                  invalid,
                                  isTouched,
                                  isDirty,
                                  error,
                                },
                                formState,
                              }) => (
                                <InputSwitch
                                  onBlur={onBlur} // notify when input is touched
                                  onChange={(e) => setIncidentReports(e.value)} // send value to hook form
                                  checked={value}
                                  inputRef={ref}
                                />
                              )}
                            />

                            <label htmlFor="incident_reports" className="ml-2">
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Incident
                              Reports
                            </label>
                          </span>
                          <small className="block pt-1">
                            {errors.incident_reports && (
                              <div className="text-red-500">
                                {errors.incident_reports.message}
                              </div>
                            )}
                          </small>
                        </div>

                        <div className="field col">
                          <span className="p-float-label">
                            <Controller
                              control={control}
                              name="citations"
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: {
                                  invalid,
                                  isTouched,
                                  isDirty,
                                  error,
                                },
                                formState,
                              }) => (
                                <InputSwitch
                                  onBlur={onBlur} // notify when input is touched
                                  onChange={(e) => setCitations(e.value)} // send value to hook form
                                  checked={value}
                                  inputRef={ref}
                                />
                              )}
                            />

                            <label htmlFor="citations" className="ml-2">
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Citations
                            </label>
                          </span>
                          <small className="block pt-1">
                            {errors.citations && (
                              <div className="text-red-500">
                                {errors.citations.message}
                              </div>
                            )}
                          </small>
                        </div>

                        <div className="field col">
                          <span className="p-float-label">
                            <Controller
                              control={control}
                              name="pool_access_log"
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: {
                                  invalid,
                                  isTouched,
                                  isDirty,
                                  error,
                                },
                                formState,
                              }) => (
                                <InputSwitch
                                  onBlur={onBlur} // notify when input is touched
                                  onChange={(e) => setPoolAccessLogs(e.value)} // send value to hook form
                                  checked={value}
                                  inputRef={ref}
                                />
                              )}
                            />

                            <label htmlFor="pool_access_log" className="ml-2">
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Pool Access
                              Logs
                            </label>
                          </span>
                          <small className="block pt-1">
                            {errors.pool_access_log && (
                              <div className="text-red-500">
                                {errors.pool_access_log.message}
                              </div>
                            )}
                          </small>
                        </div>
                      </div>
                    </div>
                    {pool_access_log ? (
                      <div className="field">
                        <div className="formgrid grid ">
                          <div className="field col ">
                            <Controller
                              name={"number_guest_pool"}
                              control={control}
                              rules={{
                                required:
                                  "Number of enabled guest in the pool is required.",
                                min: 0,
                                max: 100,
                              }}
                              render={({ field, fieldState }) => (
                                <div className="align-items-center text-xs">
                                  <label htmlFor="number_guest_pool">
                                    Number of enabled guest in the pool &nbsp;
                                    &nbsp;
                                  </label>
                                  <InputNumber
                                    id={field.name}
                                    ref={field.ref}
                                    value={field.value}
                                    onBlur={field.onBlur}
                                    onValueChange={(e) => field.onChange(e)}
                                    className="w-6rem h-1rem "
                                    min={0}
                                    max={100}
                                    useGrouping={false}
                                    inputClassName="text-xs text-center"
                                  />
                                  <small className="block pt-1">
                                    {errors.number_guest_pool && (
                                      <div className="text-red-500">
                                        {errors.number_guest_pool.message}
                                      </div>
                                    )}
                                  </small>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="field">
                      <div className="formgrid grid">
                        <div className="field col">
                          <Controller
                            control={control}
                            name="permits_by"
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: {
                                invalid,
                                isTouched,
                                isDirty,
                                error,
                              },
                              formState,
                            }) => (
                              <div className="flex align-items-center text-xs">
                                <label
                                  htmlFor="permits_by"
                                  className="white-space-nowrap"
                                >
                                  Safelistings by &nbsp; &nbsp;
                                </label>
                                <SelectButton
                                  value={permits_by}
                                  onChange={(e) => setPermitsBy(e.value)}
                                  optionLabel="name"
                                  className={permits_by_options.className}
                                  options={permits_by_options}
                                />
                              </div>
                            )}
                          />
                        </div>

                        <div className="field col">
                          <Controller
                            control={control}
                            name="citations_apply"
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: {
                                invalid,
                                isTouched,
                                isDirty,
                                error,
                              },
                              formState,
                            }) => (
                              <div className="flex align-items-center text-xs">
                                <label
                                  htmlFor="citations_apply"
                                  className="white-space-nowrap"
                                >
                                  Citations apply by &nbsp; &nbsp;
                                </label>
                                <SelectButton
                                  value={citations_apply}
                                  onChange={(e) => setCitationsApplyBy(e.value)}
                                  optionLabel="name"
                                  className={
                                    citations_apply_by_options.className
                                  }
                                  options={citations_apply_by_options}
                                />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel
                  header="Property set up2"
                  disabled={isValid ? false : false}
                >
                  {sections.map((section, i) => (
                    <div className="field">
                      <span className="p-float-label">
                        <Controller
                          control={control}
                          name="contact_is_property_manager"
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <InputSwitch
                              onBlur={onBlur} // notify when input is touched
                              onChange={(e) =>
                                setContactIsPropertyManager(e.value)
                              } // send value to hook form
                              checked={value}
                              inputRef={ref}
                            />
                          )}
                        />

                        <label htmlFor="    " className="ml-2">
                          &nbsp; &nbsp; &nbsp; &nbsp; {section.label}
                        </label>
                      </span>
                      <small className="block pt-1">
                        {errors.contact_is_property_manager && (
                          <div className="text-red-500">
                            {errors.contact_is_property_manager.message}
                          </div>
                        )}
                      </small>
                    </div>
                  ))}
                </TabPanel>
              </TabView>

              <Button
                visible={activeIndex === 1 ? false : true}
                disabled={isValid ? false : true}
                type="button"
                label={"Next"}
                className="mt-2"
                onClick={() => handleTab1Validation()}
              />

              <Button
                visible={activeIndex === 0 ? false : true}
                disabled={isSubmitting}
                type="submit"
                label={isSubmitting ? "Loading..." : "Create"}
                className="mt-2"
              />

              {errors.root && (
                <div className="text-red-500">{errors.root.message}</div>
              )}
            </form>
          </>
        )}
      </Sidebar>
    </>
  );
};

function InfoPanel({ control, propertyTypes, errors, isPropertyManager }) {
  return (
    <>
      <div className="field">
        <div className="formgrid grid">
          <div className="field col">
            <span className="p-float-label">
              <Controller
                name="name"
                control={control}
                rules={{ required: "Property Name is required." }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    // value={field.value ?? ""}
                    autoFocus
                    maxLength={50}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />

              <label htmlFor="name">Property Name</label>
            </span>
            <small className="block pt-1">
              {errors.name && (
                <div className="text-red-500">{errors.name.message}</div>
              )}
            </small>
          </div>

          <div className="field col">
            <span className="p-float-label">
              <Controller
                control={control}
                name="propertyTypeId"
                rules={{
                  validate: ({ value } = {}) =>
                    value !== undefined || "You must select a type",
                  valueAsNumber: true,
                }}
                render={({ field }) => (
                  <Dropdown
                    id={field.name}
                    filter
                    placeholder="Select a type"
                    optionLabel="type"
                    options={propertyTypes}
                    {...field}
                  />
                )}
              />
              {errors.propertyTypeId && (
                <div className="text-red-500">
                  {errors.propertyTypeId.message}
                </div>
              )}

              <label htmlFor="type">Type</label>
            </span>
          </div>
        </div>
      </div>

      <div className="field">
        <div className="formgrid grid">
          <div className="field col">
            <span className="p-float-label">
              <Controller
                name={"address"}
                control={control}
                rules={{ required: "Address is required.", min: 0 }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    maxLength={50}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />

              <label htmlFor="address">Address</label>
            </span>
            <small className="block pt-1">
              {errors.address && (
                <div className="text-red-500">{errors.address.message}</div>
              )}
            </small>
          </div>

          <div className="field col">
            <span className="p-float-label">
              <Controller
                name="gps_coordinates"
                control={control}
                rules={{ required: "GPS Coordinates is required." }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    maxLength={50}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />

              <label htmlFor="gps_coordinates">GPS Coordinates</label>
            </span>
            <small className="block pt-1">
              {errors.gps_coordinates && (
                <div className="text-red-500">
                  {errors.gps_coordinates.message}
                </div>
              )}
            </small>
          </div>
        </div>
      </div>

      <div className="field">
        <span className="p-float-label">
          <Controller
            name="contact_name"
            control={control}
            rules={{ required: "Contact Name is required." }}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                {...field}
                maxLength={50}
                className={classNames({
                  "p-invalid": fieldState.invalid,
                })}
              />
            )}
          />

          <label htmlFor="name">Contact Name</label>
        </span>
        <small className="block pt-1">
          {errors.contact_name && (
            <div className="text-red-500">{errors.contact_name.message}</div>
          )}
        </small>
      </div>

      <div className="field">
        <div className="formgrid grid">
          <div className="field col">
            <span className="p-float-label">
              <Controller
                name={"telephone"}
                control={control}
                rules={{
                  required: "Contact Telephone is required.",
                  min: 0,
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    maxLength={50}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />

              <label htmlFor="telephone">Contact Telephone</label>
            </span>
            <small className="block pt-1">
              {errors.telephone && (
                <div className="text-red-500">{errors.telephone.message}</div>
              )}
            </small>
          </div>

          <div className="field col">
            <span className="p-float-label">
              <Controller
                name="email"
                control={control}
                rules={{ required: "Contact Email is required." }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    maxLength={50}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />

              <label htmlFor="email">Contact Email</label>
            </span>
            <small className="block pt-1">
              {errors.email && (
                <div className="text-red-500">{errors.email.message}</div>
              )}
            </small>
          </div>
        </div>
      </div>

      <div className="field">
        <div className="flex gap-2">
          <Controller
            control={control}
            name="contact_is_property_manager"
            render={({ field }) => (
              <InputSwitch checked={field.value} {...field} />
            )}
          />

          <label htmlFor="contact_is_property_manager" className="ml-2">
            The contact is the Property Manager
          </label>
        </div>
        <small className="block pt-1">
          {errors.contact_is_property_manager && (
            <div className="text-red-500">
              {errors.contact_is_property_manager.message}
            </div>
          )}
        </small>
      </div>

      <div className="field">
        <div className="formgrid grid">
          <div className="field col">
            <span className="p-float-label">
              <Controller
                name={"maintenance_email"}
                control={control}
                rules={{
                  required: "Maintenance email is required.",
                  min: 0,
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    maxLength={50}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />

              <label htmlFor="maintenance_email">Maintenance email</label>
            </span>
            <small className="block pt-1">
              {errors.maintenance_email && (
                <div className="text-red-500">
                  {errors.maintenance_email.message}
                </div>
              )}
            </small>
          </div>

          <div className="field col">
            <span className="p-float-label">
              <Controller
                name="emergency_contact"
                control={control}
                rules={{
                  required: "Emergency Contact is required.",
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    value={field.value ?? ""}
                    maxLength={50}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />

              <label htmlFor="emergency_contact">Emergency Contact</label>
            </span>
            <small className="block pt-1">
              {errors.emergency_contact && (
                <div className="text-red-500">
                  {errors.emergency_contact.message}
                </div>
              )}
            </small>
          </div>

          <div className="field col">
            <span className="p-float-label">
              <Controller
                name="emergency_telephone"
                control={control}
                rules={{
                  required: "Emergency Telephone is required.",
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    maxLength={50}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />

              <label htmlFor="emergency_telephone">Emergency Telephone</label>
            </span>
            <small className="block pt-1">
              {errors.emergency_telephone && (
                <div className="text-red-500">
                  {errors.emergency_telephone.message}
                </div>
              )}
            </small>
          </div>
        </div>
      </div>

      <div className="field">
        <span className="p-float-label">
          <Controller
            name="details"
            control={control}
            rules={{ required: "Notes is required." }}
            render={({ field, fieldState }) => (
              <InputTextarea
                id={field.name}
                {...field}
                autoFocus
                maxLength={500}
                rows={5}
                cols={30}
                className={classNames({
                  "p-invalid": fieldState.invalid,
                })}
              />
            )}
          />

          <label htmlFor="details">
            Notes <small>(max 500 characters)</small>
          </label>
        </span>
        <small className="block pt-1">
          {errors.details && (
            <div className="text-red-500">{errors.details.message}</div>
          )}
        </small>
      </div>

      {!isPropertyManager ? (
        <Message
          severity="warn"
          className=" w-full justify-content-start"
          text="Remember that you have to assign a Property Manager to the property from the user section"
        />
      ) : null}
    </>
  );
}

// function PermissionsPanel({control,}) {
//   return (
//     <TabPanel header="Property set up" disabled={isValid ? false : false}>
//       <div className="field text-xs">
//         <div className="field">
//           <span className="align-items-center inline-flex ">
//             <h3>Property Management Sections</h3>
//           </span>
//         </div>

//         <div className="field">
//           <div className="formgrid grid">
//             <div className="field col">
//               <span className="p-float-label">
//                 <Controller
//                   control={control}
//                   name="safelistings"
//                   render={({
//                     field: { onChange, onBlur, value, name, ref },
//                     fieldState: { invalid, isTouched, isDirty, error },
//                     formState,
//                   }) => (
//                     <InputSwitch
//                       onBlur={onBlur} // notify when input is touched
//                       onChange={(e) => setSafelistings(e.value)} // send value to hook form
//                       checked={value}
//                       inputRef={ref}
//                     />
//                   )}
//                 />

//                 <label htmlFor="safelistings" className="ml-2">
//                   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Safelistings
//                 </label>
//               </span>
//               <small className="block pt-1">
//                 {errors.safelistings && (
//                   <div className="text-red-500">
//                     {errors.safelistings.message}
//                   </div>
//                 )}
//               </small>
//             </div>

//             <div className="field col">
//               <span className="p-float-label">
//                 <Controller
//                   control={control}
//                   name="households"
//                   render={({
//                     field: { onChange, onBlur, value, name, ref },
//                     fieldState: { invalid, isTouched, isDirty, error },
//                     formState,
//                   }) => (
//                     <InputSwitch
//                       onBlur={onBlur} // notify when input is touched
//                       onChange={(e) => setHouseholds(e.value)} // send value to hook form
//                       checked={value}
//                       inputRef={ref}
//                     />
//                   )}
//                 />

//                 <label htmlFor="households" className="ml-2">
//                   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Households
//                 </label>
//               </span>
//               <small className="block pt-1">
//                 {errors.households && (
//                   <div className="text-red-500">
//                     {errors.households.message}
//                   </div>
//                 )}
//               </small>
//             </div>

//             <div className="field col">
//               <span className="p-float-label">
//                 <Controller
//                   control={control}
//                   name="pools"
//                   render={({
//                     field: { onChange, onBlur, value, name, ref },
//                     fieldState: { invalid, isTouched, isDirty, error },
//                     formState,
//                   }) => (
//                     <InputSwitch
//                       onBlur={onBlur} // notify when input is touched
//                       onChange={(e) => setPools(e.value)} // send value to hook form
//                       checked={value}
//                       inputRef={ref}
//                     />
//                   )}
//                 />

//                 <label htmlFor="pools" className="ml-2">
//                   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Pools
//                 </label>
//               </span>
//               <small className="block pt-1">
//                 {errors.pools && (
//                   <div className="text-red-500">{errors.pools.message}</div>
//                 )}
//               </small>
//             </div>

//             <div className="field col">
//               <span className="p-float-label">
//                 <Controller
//                   control={control}
//                   name="vehicles"
//                   render={({
//                     field: { onChange, onBlur, value, name, ref },
//                     fieldState: { invalid, isTouched, isDirty, error },
//                     formState,
//                   }) => (
//                     <InputSwitch
//                       onBlur={onBlur} // notify when input is touched
//                       onChange={(e) => setVehicles(e.value)} // send value to hook form
//                       checked={value}
//                       inputRef={ref}
//                     />
//                   )}
//                 />

//                 <label htmlFor="vehicles" className="ml-2">
//                   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Vehicles
//                 </label>
//               </span>
//               <small className="block pt-1">
//                 {errors.vehicles && (
//                   <div className="text-red-500">{errors.vehicles.message}</div>
//                 )}
//               </small>
//             </div>
//           </div>
//         </div>

//         {safelistings ? (
//           <>
//             <div className="field">
//               <div className="formgrid grid">
//                 <div className="field col">
//                   <Controller
//                     control={control}
//                     name="selected_safelisting_option"
//                     render={({
//                       field: { onChange, onBlur, value, name, ref },
//                       fieldState: { invalid, isTouched, isDirty, error },
//                       formState,
//                     }) => (
//                       <div className="flex align-items-center text-xs">
//                         <label
//                           htmlFor="selected_safelisting_option"
//                           className="white-space-nowrap"
//                         >
//                           Safelistings by &nbsp; &nbsp;
//                         </label>
//                         <SelectButton
//                           value={selected_safelisting_option}
//                           onChange={(e) =>
//                             setSelectedSafelistingOption(e.value)
//                           }
//                           optionLabel="name"
//                           className={safelistings_options.className}
//                           options={safelistings_options}
//                         />
//                       </div>
//                     )}
//                   />
//                 </div>

//                 <div className="field col">
//                   <Controller
//                     control={control}
//                     name="selected_safelisting_period_option"
//                     render={({
//                       field: { onChange, onBlur, value, name, ref },
//                       fieldState: { invalid, isTouched, isDirty, error },
//                       formState,
//                     }) => (
//                       <div className="flex align-items-center text-xs">
//                         <label
//                           htmlFor="selected_safelisting_period_option"
//                           className="white-space-nowrap"
//                         >
//                           Period by &nbsp; &nbsp;&nbsp;
//                         </label>
//                         <SelectButton
//                           value={selected_safelisting_period_option}
//                           onChange={(e) =>
//                             setSelectedSafelistingPeriodOption(e.value)
//                           }
//                           optionLabel="name"
//                           className={safelisting_period_options.className}
//                           options={safelisting_period_options}
//                         />
//                       </div>
//                     )}
//                   />
//                 </div>

//                 <div className="field col">
//                   <Controller
//                     name={"number_of_days"}
//                     control={control}
//                     rules={{
//                       required: "Number of days is required.",
//                       min: 0,
//                       max: 100,
//                     }}
//                     render={({ field, fieldState }) => (
//                       <InputNumber
//                         id={field.name}
//                         ref={field.ref}
//                         value={field.value}
//                         onBlur={field.onBlur}
//                         onValueChange={(e) => field.onChange(e)}
//                         suffix=" days"
//                         className="w-6rem h-1rem "
//                         min={0}
//                         max={100}
//                         useGrouping={false}
//                         disabled={
//                           selected_safelisting_period_option == 1 ? false : true
//                         }
//                         inputClassName="text-xs text-center"
//                       />
//                     )}
//                   />
//                 </div>
//               </div>
//             </div>

//             <div className="field">
//               <div className="formgrid grid ">
//                 <div className="field col ">
//                   <Controller
//                     name={"number_of_parkings"}
//                     control={control}
//                     rules={{
//                       required: "Number of days is required.",
//                       min: 0,
//                       max: 100,
//                     }}
//                     render={({ field, fieldState }) => (
//                       <div className="align-items-center text-xs">
//                         <label htmlFor="selected_safelisting_option">
//                           Number of parking permits granted per selected period
//                           &nbsp; &nbsp;
//                         </label>
//                         <InputNumber
//                           id={field.name}
//                           ref={field.ref}
//                           value={field.value}
//                           onBlur={field.onBlur}
//                           onValueChange={(e) => field.onChange(e)}
//                           className="w-6rem h-1rem "
//                           min={0}
//                           max={100}
//                           useGrouping={false}
//                           inputClassName="text-xs text-center"
//                         />
//                         <small className="block pt-1">
//                           {errors.number_of_parkings && (
//                             <div className="text-red-500">
//                               {errors.number_of_parkings.message}
//                             </div>
//                           )}
//                         </small>
//                       </div>
//                     )}
//                   />
//                 </div>
//               </div>
//             </div>

//             <div className="field">
//               <div className="formgrid grid ">
//                 <div className="field col ">
//                   <Controller
//                     name={"days_in_a_row"}
//                     control={control}
//                     rules={{
//                       required: "Number of days is required.",
//                       min: 0,
//                       max: 100,
//                     }}
//                     render={({ field, fieldState }) => (
//                       <div className="align-items-center text-xs">
//                         <label htmlFor="selected_safelisting_option">
//                           How many days in a row can be requested? &nbsp; &nbsp;
//                         </label>
//                         <InputNumber
//                           id={field.name}
//                           ref={field.ref}
//                           value={field.value}
//                           onBlur={field.onBlur}
//                           onValueChange={(e) => field.onChange(e)}
//                           className="w-6rem h-1rem "
//                           min={0}
//                           max={100}
//                           useGrouping={false}
//                           inputClassName="text-xs text-center"
//                         />
//                         <small className="block pt-1">
//                           {errors.days_in_a_row && (
//                             <div className="text-red-500">
//                               {errors.days_in_a_row.message}
//                             </div>
//                           )}
//                         </small>
//                       </div>
//                     )}
//                   />
//                 </div>
//               </div>
//             </div>
//           </>
//         ) : null}
//       </div>

//       <div className="field text-xs">
//         <div className="field">
//           <span className="align-items-center inline-flex ">
//             <h3>User Management Sections</h3>
//           </span>
//         </div>

//         <div className="field">
//           <div className="formgrid grid">
//             <div className="field col">
//               <span className="p-float-label">
//                 <Controller
//                   control={control}
//                   name="users"
//                   render={({
//                     field: { onChange, onBlur, value, name, ref },
//                     fieldState: { invalid, isTouched, isDirty, error },
//                     formState,
//                   }) => (
//                     <InputSwitch
//                       onBlur={onBlur} // notify when input is touched
//                       onChange={(e) => setUsers(e.value)} // send value to hook form
//                       checked={value}
//                       inputRef={ref}
//                     />
//                   )}
//                 />

//                 <label htmlFor="users" className="ml-2">
//                   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Users
//                 </label>
//               </span>
//               <small className="block pt-1">
//                 {errors.users && (
//                   <div className="text-red-500">{errors.users.message}</div>
//                 )}
//               </small>
//             </div>

//             <div className="field col">
//               <span className="p-float-label">
//                 <Controller
//                   control={control}
//                   name="schedules"
//                   render={({
//                     field: { onChange, onBlur, value, name, ref },
//                     fieldState: { invalid, isTouched, isDirty, error },
//                     formState,
//                   }) => (
//                     <InputSwitch
//                       onBlur={onBlur} // notify when input is touched
//                       onChange={(e) => setSchedules(e.value)} // send value to hook form
//                       checked={value}
//                       inputRef={ref}
//                     />
//                   )}
//                 />

//                 <label htmlFor="schedules" className="ml-2">
//                   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Schedules
//                 </label>
//               </span>
//               <small className="block pt-1">
//                 {errors.schedules && (
//                   <div className="text-red-500">{errors.schedules.message}</div>
//                 )}
//               </small>
//             </div>

//             <div className="field col">
//               <span className="p-float-label">
//                 <Controller
//                   control={control}
//                   name="shifts"
//                   render={({
//                     field: { onChange, onBlur, value, name, ref },
//                     fieldState: { invalid, isTouched, isDirty, error },
//                     formState,
//                   }) => (
//                     <InputSwitch
//                       onBlur={onBlur} // notify when input is touched
//                       onChange={(e) => setShifts(e.value)} // send value to hook form
//                       checked={value}
//                       inputRef={ref}
//                     />
//                   )}
//                 />

//                 <label htmlFor="shifts" className="ml-2">
//                   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Shifts
//                 </label>
//               </span>
//               <small className="block pt-1">
//                 {errors.shifts && (
//                   <div className="text-red-500">{errors.shifts.message}</div>
//                 )}
//               </small>
//             </div>

//             <div className="field col">
//               <span className="p-float-label">
//                 <Controller
//                   control={control}
//                   name="vehicle_maintenance"
//                   render={({
//                     field: { onChange, onBlur, value, name, ref },
//                     fieldState: { invalid, isTouched, isDirty, error },
//                     formState,
//                   }) => (
//                     <InputSwitch
//                       onBlur={onBlur} // notify when input is touched
//                       onChange={(e) => setVehicleMaintenance(e.value)} // send value to hook form
//                       checked={value}
//                       inputRef={ref}
//                     />
//                   )}
//                 />

//                 <label htmlFor="vehicle_maintenance" className="ml-2">
//                   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Vehicle Maintenance
//                 </label>
//               </span>
//               <small className="block pt-1">
//                 {errors.vehicle_maintenance && (
//                   <div className="text-red-500">
//                     {errors.vehicle_maintenance.message}
//                   </div>
//                 )}
//               </small>
//             </div>
//           </div>
//         </div>

//         <div className="field">
//           <div className="formgrid grid">
//             <div className="field col">
//               <Controller
//                 control={control}
//                 name="selected_launch_deductible_option"
//                 render={({
//                   field: { onChange, onBlur, value, name, ref },
//                   fieldState: { invalid, isTouched, isDirty, error },
//                   formState,
//                 }) => (
//                   <div className="flex align-items-center text-xs">
//                     <label
//                       htmlFor="selected_launch_deductible_option"
//                       className="white-space-nowrap"
//                     >
//                       Launch deductible &nbsp; &nbsp;
//                     </label>
//                     <SelectButton
//                       value={selected_launch_deductible_option}
//                       onChange={(e) =>
//                         setSelectedLaunchDeductibleOption(e.value)
//                       }
//                       optionLabel="name"
//                       className={launch_deductible_options.className}
//                       options={launch_deductible_options}
//                     />
//                   </div>
//                 )}
//               />
//             </div>

//             <div className="field col">
//               <Controller
//                 name={"launch_duration"}
//                 control={control}
//                 rules={{
//                   required: "Launch duration is required.",
//                   min: 0,
//                   max: 100,
//                 }}
//                 render={({ field, fieldState }) => (
//                   <div className="align-items-center text-xs">
//                     <label htmlFor="launch_duration">
//                       Launch duration &nbsp; &nbsp;
//                     </label>
//                     <InputNumber
//                       id={field.name}
//                       ref={field.ref}
//                       value={field.value}
//                       onBlur={field.onBlur}
//                       onValueChange={(e) => field.onChange(e)}
//                       className="w-6rem h-1rem "
//                       min={0}
//                       max={100}
//                       useGrouping={false}
//                       inputClassName="text-xs text-center"
//                     />
//                     <small className="block pt-1">
//                       {errors.launch_duration && (
//                         <div className="text-red-500">
//                           {errors.launch_duration.message}
//                         </div>
//                       )}
//                     </small>
//                   </div>
//                 )}
//               />
//             </div>

//             <div className="field col">
//               <Controller
//                 name={"break_duration"}
//                 control={control}
//                 rules={{
//                   required: "Break duration is required.",
//                   min: 0,
//                   max: 100,
//                 }}
//                 render={({ field, fieldState }) => (
//                   <div className="align-items-center text-xs">
//                     <label htmlFor="break_duration">
//                       Breaks duration &nbsp; &nbsp;
//                     </label>
//                     <InputNumber
//                       id={field.name}
//                       ref={field.ref}
//                       value={field.value}
//                       onBlur={field.onBlur}
//                       onValueChange={(e) => field.onChange(e)}
//                       className="w-6rem h-1rem "
//                       min={0}
//                       max={100}
//                       useGrouping={false}
//                       inputClassName="text-xs text-center"
//                     />
//                     <small className="block pt-1">
//                       {errors.break_duration && (
//                         <div className="text-red-500">
//                           {errors.break_duration.message}
//                         </div>
//                       )}
//                     </small>
//                   </div>
//                 )}
//               />
//             </div>
//           </div>
//         </div>

//         <div className="field">
//           <div className="formgrid grid ">
//             <div className="field col ">
//               <Controller
//                 name={"allowed_distance"}
//                 control={control}
//                 rules={{
//                   required: "Allowed distance is required.",
//                   min: 0,
//                   max: 100,
//                 }}
//                 render={({ field, fieldState }) => (
//                   <div className="align-items-center text-xs">
//                     <label htmlFor="allowed_distance">
//                       Allowed distance loging from property &nbsp; &nbsp;
//                     </label>
//                     <InputNumber
//                       id={field.name}
//                       ref={field.ref}
//                       value={field.value}
//                       onBlur={field.onBlur}
//                       onValueChange={(e) => field.onChange(e)}
//                       className="w-6rem h-1rem "
//                       min={0}
//                       max={100}
//                       useGrouping={false}
//                       inputClassName="text-xs text-center"
//                     />
//                     <small className="block pt-1">
//                       {errors.allowed_distance && (
//                         <div className="text-red-500">
//                           {errors.allowed_distance.message}
//                         </div>
//                       )}
//                     </small>
//                   </div>
//                 )}
//               />
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="field text-xs">
//         <div className="field">
//           <span className="align-items-center inline-flex ">
//             <h3>Report Sections</h3>
//           </span>
//         </div>

//         <div className="field">
//           <div className="formgrid grid">
//             <div className="field col">
//               <span className="p-float-label">
//                 <Controller
//                   control={control}
//                   name="dars"
//                   render={({
//                     field: { onChange, onBlur, value, name, ref },
//                     fieldState: { invalid, isTouched, isDirty, error },
//                     formState,
//                   }) => (
//                     <InputSwitch
//                       onBlur={onBlur} // notify when input is touched
//                       onChange={(e) => setDars(e.value)} // send value to hook form
//                       checked={value}
//                       inputRef={ref}
//                     />
//                   )}
//                 />

//                 <label htmlFor="dars" className="ml-2">
//                   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; DARs
//                 </label>
//               </span>
//               <small className="block pt-1">
//                 {errors.dars && (
//                   <div className="text-red-500">{errors.dars.message}</div>
//                 )}
//               </small>
//             </div>

//             <div className="field col">
//               <span className="p-float-label">
//                 <Controller
//                   control={control}
//                   name="incident_reports"
//                   render={({
//                     field: { onChange, onBlur, value, name, ref },
//                     fieldState: { invalid, isTouched, isDirty, error },
//                     formState,
//                   }) => (
//                     <InputSwitch
//                       onBlur={onBlur} // notify when input is touched
//                       onChange={(e) => setIncidentReports(e.value)} // send value to hook form
//                       checked={value}
//                       inputRef={ref}
//                     />
//                   )}
//                 />

//                 <label htmlFor="incident_reports" className="ml-2">
//                   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Incident Reports
//                 </label>
//               </span>
//               <small className="block pt-1">
//                 {errors.incident_reports && (
//                   <div className="text-red-500">
//                     {errors.incident_reports.message}
//                   </div>
//                 )}
//               </small>
//             </div>

//             <div className="field col">
//               <span className="p-float-label">
//                 <Controller
//                   control={control}
//                   name="citations"
//                   render={({
//                     field: { onChange, onBlur, value, name, ref },
//                     fieldState: { invalid, isTouched, isDirty, error },
//                     formState,
//                   }) => (
//                     <InputSwitch
//                       onBlur={onBlur} // notify when input is touched
//                       onChange={(e) => setCitations(e.value)} // send value to hook form
//                       checked={value}
//                       inputRef={ref}
//                     />
//                   )}
//                 />

//                 <label htmlFor="citations" className="ml-2">
//                   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Citations
//                 </label>
//               </span>
//               <small className="block pt-1">
//                 {errors.citations && (
//                   <div className="text-red-500">{errors.citations.message}</div>
//                 )}
//               </small>
//             </div>

//             <div className="field col">
//               <span className="p-float-label">
//                 <Controller
//                   control={control}
//                   name="pool_access_log"
//                   render={({
//                     field: { onChange, onBlur, value, name, ref },
//                     fieldState: { invalid, isTouched, isDirty, error },
//                     formState,
//                   }) => (
//                     <InputSwitch
//                       onBlur={onBlur} // notify when input is touched
//                       onChange={(e) => setPoolAccessLogs(e.value)} // send value to hook form
//                       checked={value}
//                       inputRef={ref}
//                     />
//                   )}
//                 />

//                 <label htmlFor="pool_access_log" className="ml-2">
//                   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Pool Access Logs
//                 </label>
//               </span>
//               <small className="block pt-1">
//                 {errors.pool_access_log && (
//                   <div className="text-red-500">
//                     {errors.pool_access_log.message}
//                   </div>
//                 )}
//               </small>
//             </div>
//           </div>
//         </div>
//         {pool_access_log ? (
//           <div className="field">
//             <div className="formgrid grid ">
//               <div className="field col ">
//                 <Controller
//                   name={"number_guest_pool"}
//                   control={control}
//                   rules={{
//                     required:
//                       "Number of enabled guest in the pool is required.",
//                     min: 0,
//                     max: 100,
//                   }}
//                   render={({ field, fieldState }) => (
//                     <div className="align-items-center text-xs">
//                       <label htmlFor="number_guest_pool">
//                         Number of enabled guest in the pool &nbsp; &nbsp;
//                       </label>
//                       <InputNumber
//                         id={field.name}
//                         ref={field.ref}
//                         value={field.value}
//                         onBlur={field.onBlur}
//                         onValueChange={(e) => field.onChange(e)}
//                         className="w-6rem h-1rem "
//                         min={0}
//                         max={100}
//                         useGrouping={false}
//                         inputClassName="text-xs text-center"
//                       />
//                       <small className="block pt-1">
//                         {errors.number_guest_pool && (
//                           <div className="text-red-500">
//                             {errors.number_guest_pool.message}
//                           </div>
//                         )}
//                       </small>
//                     </div>
//                   )}
//                 />
//               </div>
//             </div>
//           </div>
//         ) : null}
//         <div className="field">
//           <div className="formgrid grid">
//             <div className="field col">
//               <Controller
//                 control={control}
//                 name="permits_by"
//                 render={({
//                   field: { onChange, onBlur, value, name, ref },
//                   fieldState: { invalid, isTouched, isDirty, error },
//                   formState,
//                 }) => (
//                   <div className="flex align-items-center text-xs">
//                     <label htmlFor="permits_by" className="white-space-nowrap">
//                       Safelistings by &nbsp; &nbsp;
//                     </label>
//                     <SelectButton
//                       value={permits_by}
//                       onChange={(e) => setPermitsBy(e.value)}
//                       optionLabel="name"
//                       className={permits_by_options.className}
//                       options={permits_by_options}
//                     />
//                   </div>
//                 )}
//               />
//             </div>

//             <div className="field col">
//               <Controller
//                 control={control}
//                 name="citations_apply"
//                 render={({
//                   field: { onChange, onBlur, value, name, ref },
//                   fieldState: { invalid, isTouched, isDirty, error },
//                   formState,
//                 }) => (
//                   <div className="flex align-items-center text-xs">
//                     <label
//                       htmlFor="citations_apply"
//                       className="white-space-nowrap"
//                     >
//                       Citations apply by &nbsp; &nbsp;
//                     </label>
//                     <SelectButton
//                       value={citations_apply}
//                       onChange={(e) => setCitationsApplyBy(e.value)}
//                       optionLabel="name"
//                       className={citations_apply_by_options.className}
//                       options={citations_apply_by_options}
//                     />
//                   </div>
//                 )}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </TabPanel>
//   );
// }

export default function SecondSheet({ isVisible, propertyId, onClose }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const isLoadingProperty = false;
  const [propertyTypes] = useLocalState("", "propertyTypes");

  console.log(">>>", propertyTypes);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting, isValid },
    setError,
    setValue,
    form,
    trigger,
    watch,
  } = useForm({
    values: {
      name: "", // name,
      propertyTypeId: "", // propertyType,
      address: "", // address,
      gps_coordinates: "", // gps_coordinates,
      contact_name: "", // contact_name,
      telephone: "", // telephone,
      email: "", // email,
      contact_is_property_manager: "", // contact_is_property_manager,
      maintenance_email: "", // maintenance_email,
      emergency_contact: "", // emergency_contact,
      emergency_telephone: "", // emergency_telephone,
      details: "", // details,

      // safelistings: safelistings,
      // selected_safelisting_option: selected_safelisting_option,
      // selected_safelisting_period_option: selected_safelisting_period_option,
      // number_of_days: number_of_days,
      // number_of_parkings: number_of_parkings,

      // households: households,
      // pools: pools,
      // vehicles: vehicles,

      // users: users,
      // schedules: schedules,
      // shifts: shifts,
      // vehicle_maintenance: vehicle_maintenance,

      // launch_duration: launch_duration,
      // break_duration: break_duration,
      // allowed_distance: allowed_distance,

      // dars: dars,
      // incident_reports: incident_reports,
      // citations: citations,
      // pool_access_log: pool_access_log,
    },
    resolver: zodResolver(schema),
  });

  const isPropertyManager = watch("contact_is_property_manager");

  const onSubmit = (data) => {
    console.log(">>>data", data);
  };

  const handleTab1Validation = () => {};

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      onHide={onClose}
      className="w-full md:w-7 lg:w-7"
    >
      {isLoadingProperty ? (
        <div className="flex flex-wrap justify-content-between gap-2 mb-1">
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <ProgressSpinner
              aria-label="Loading"
              style={{ width: "50px", height: "100vm" }}
              strokeWidth="2"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />

            <p>Loading</p>
          </div>
        </div>
      ) : (
        <>
          <h2>{propertyId ? "Create property" : "Update property"}</h2>

          <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
            <TabView
              activeIndex={activeTabIndex}
              onTabChange={(e) => {
                setActiveTabIndex(e.index);
              }}
            >
              <TabPanel header="Property Information">
                <InfoPanel
                  isPropertyManager={isPropertyManager}
                  control={control}
                  propertyTypes={propertyTypes}
                  errors={errors}
                />
              </TabPanel>
              {/* <PermissionsPanel /> */}
              <TabPanel
                header="Property set up2"
                disabled={isValid ? false : false}
              >
                {[].map((section, i) => (
                  <div className="field">
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="contact_is_property_manager"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <InputSwitch
                            onBlur={onBlur} // notify when input is touched
                            onChange={
                              (e) => {}
                              // setContactIsPropertyManager(e.value)
                            } // send value to hook form
                            checked={value}
                            inputRef={ref}
                          />
                        )}
                      />

                      <label htmlFor="    " className="ml-2">
                        &nbsp; &nbsp; &nbsp; &nbsp; {section.label}
                      </label>
                    </span>
                    <small className="block pt-1">
                      {errors.contact_is_property_manager && (
                        <div className="text-red-500">
                          {errors.contact_is_property_manager.message}
                        </div>
                      )}
                    </small>
                  </div>
                ))}
              </TabPanel>
            </TabView>

            <Button
              visible={activeTabIndex === 0}
              disabled={!isValid}
              type="button"
              label={"Next"}
              className="mt-2"
              onClick={() => handleTab1Validation()}
            />

            <Button
              visible={activeTabIndex === 1}
              disabled={isSubmitting}
              type="submit"
              label={isSubmitting ? "Loading..." : "Create"}
              className="mt-2"
            />

            {errors.root && (
              <div className="text-red-500">{errors.root.message}</div>
            )}
          </form>
        </>
      )}
    </Sidebar>
  );
}

// export default PropertySheet;
