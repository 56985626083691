import React, { useContext, useState } from "react";
import VisibleContext from "../../context/VisibleContext";
import { useUpdateEffect } from "primereact/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { Avatar } from "primereact/avatar";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import secureLocalStorage from "react-secure-storage";

import { useVehicleByPropertyId } from "../../hooks/vehicleQueries";

import SideBar from "../citations/SideBar";
import  SideBarVehicles  from "../vehicles/SideBar";

import * as moment from "moment";
import { utcToLocale } from "../../helpers/helpers";

const VehicleProfile = () => {
  let userContextProperty = secureLocalStorage.getItem("userContextProperty");
  const [property_id, setProperty] = useState(userContextProperty);
  const [vehicles, setVehicles] = useState([]);
  const [vehicleId, setVehicleId] = useState(0);
  const [totalCitations, setTotalCitations] = useState(0);
  const [totalSafelistings, setTotalSafelistings] = useState(0);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const { VisibleFormContext, visibleLoadingModalContext } =
    useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;

  const queryClient = useQueryClient();

  const refetchVehicleProfile = () => {
    queryClient.invalidateQueries({
      queryKey: ["vehicles_details"],
      refetchActive: true,
      refetchInactive: false,
    });
  };

  function getVehicle(vehicle_id) {
    //console.log(vehicle_id)
    try {
      return vehicles.find(
        (element) => element.vehicle_id == vehicle_id
      );
    } catch (error) {
      console.log(error);
    }
  }

  const {
    isPending: isPendingVehicleByPropertyId,
    isSuccess: isSuccessVehicleByPropertyId,
    isError: isErrorVehicleByPropertyId,
    data: fetchedVehicleByPropertyId,
    error: errorVehicleByPropertyId,
    refetch: refectVehicleByPropertyId,
  } = useVehicleByPropertyId(property_id);

  useUpdateEffect(() => {
    if (isSuccessVehicleByPropertyId) {
      setVehicles(fetchedVehicleByPropertyId?.data.vehicles);
      //setTotalCitations(fetchedVehicleByPropertyId?.data.vehicles.citations_count);
      //setTotalSafelistings(fetchedVehicleByPropertyId?.data.vehicles.safelistings_count);
      //console.log(fetchedVehicleByPropertyId?.data.vehicles)
      //setVehicleId(getVehicle(vehicleId.vehicle_id));
    }
  }, [fetchedVehicleByPropertyId]);

  useUpdateEffect(() => {
    if(vehicleId.vehicle_id>0){
      setVehicleId(getVehicle(vehicleId.vehicle_id));
    }
    
  }, [vehicles]);

  const createdDateBodyTemplate = (data) => {
    return moment(utcToLocale(data.created_at)).format("yyyy-MM-DD HH:mm");
  };

  const citationDateBodyTemplate = (citation) => {
    return moment(utcToLocale(citation.citation_date)).format(
      "yyyy-MM-DD HH:mm"
    );
  };

  const startDateBodyTemplate = (safelisting) => {
    return moment(safelisting.start_date).format("yyyy-MM-DD");
  };

  const endDateBodyTemplate = (safelisting) => {
    return moment(safelisting.end_date).format("yyyy-MM-DD");
  };

  const durationDaysBodyTemplate = (safelisting) => {
    const firstDate = new Date(safelisting.start_date);
    const secondDate = new Date(safelisting.end_date);

    const firstDateInMs = firstDate.getTime();
    const secondDateInMs = secondDate.getTime();

    const differenceBtwDates = secondDateInMs - firstDateInMs;

    const aDayInMs = 24 * 60 * 60 * 1000;

    const daysDiff = Math.round(differenceBtwDates / aDayInMs);
    //console.log(daysDiff)

    return `${daysDiff} days`;
  };

  const statusBodyTemplate = (safelisting) => {
    const firstDate = new Date(safelisting.end_date);
    const secondDate = new Date();

    if(secondDate >= firstDate ) {
      return (
        <Tag
          severity="danger"
          className="w-3rem md:w-4rem lg:w-6rem "
          text="white"
          style={{padding: "0.4rem",backgroundColor: "#d32f2fd1", textAlign: "center"}}
        >
          Expired
        </Tag>)
    }else{
      return (
        <Tag
          severity="success"
          className="w-3rem md:w-4rem lg:w-6rem "
          text="white"
          style={{padding: "0.4rem",backgroundColor: "#689f38d1", textAlign: "center"}}
        >
          Active
        </Tag>)
    }

  };

  const header_citation = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Citations <small className="font-normal">(current count: {vehicleId.citations_count})</small></span>
    </div>
  );

  const header_safelisting = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Safelistings <small className="font-normal">(current count: {vehicleId.safelistings_count})</small></span>
    </div>
  );

  const openSidebarForm = (label) => {
    setVisibleForm({
      visible: true,
      itemId: null,
      title: label,
    });
  };

  return (
    <>
      {vehicleId == 0 ? ( 
        
      <SideBarVehicles />

      ) : (

      <SideBar vehicle={vehicleId} vehicles={vehicles}/>

      )}

      <div className="card">
        <div className="flex flex-wrap justify-content-between gap-2 mb-1">
          <div className="p-toolbar-group-start p-toolbar-group-left">
            <Avatar
              label="Vehicle Profile"
              size="large"
              style={{ backgroundColor: "white", width: "10rem" }}
            />
            <Button
              icon="pi pi-refresh"
              rounded
              text
              aria-label="Refresh"
              tooltip="Refresh"
              onClick={(e) => refetchVehicleProfile(e)}
            />
          </div>
        </div>

        
        <div className="flex flex-wrap justify-content-between gap-2 mb-1">
       
           
              <Dropdown
                className="w-full md:w-20rem"
                filter
                placeholder="Select a vehicle"
                optionLabel="label"
                options={vehicles}
                onChange={(e) => {
                  setVehicleId(e.value);
                }}
                //optionValue={type}
                value={vehicleId}
              />

              
            

            <Button
              onClick={() => openSidebarForm("New Vehicle")}
              label="New Vehicle"
              id="newBtn"
              //style={{ backgroundColor: "#3F51B580" }}
            />
      
            <Button
              onClick={() => openSidebarForm("New Citation")}
              label="New Citation"
              id="newBtn"
              //style={{ backgroundColor: "#3F51B580" }}
            />
   
        </div>

        {vehicleId ? (
          <>
            <br />
            <div className="grid">
              {vehicleId?.safelistings?.length > 0 ? (
                <div
                  className="col"
                >
                  <Message
                    className="w-full"
                    style={{ justifyContent: "left" }}
                    severity="success"
                    text="Has a permit"
                  />
                </div>
              ) : (
                <div
                  className="col"
                >
                  <Message
                    className="w-full"
                    style={{ justifyContent: "left" }}
                    severity="error"
                    text="No permit"
                  />
                </div>
              )}
              {vehicleId?.citations_left < 0 ? (
                <div
                  className="col"
                >
                  <Message
                    className="w-full "
                    style={{ justifyContent: "left" }}
                    severity="error"
                    text="Will be towed"
                  />
                </div>
              ) : null}

              {vehicleId?.citations_left == 0 ? (
                <div
                  className="col"
                >
                  <Message
                    className="w-full "
                    style={{ justifyContent: "left" }}
                    severity="warn"
                    text="May be towed"
                  />
                </div>
              ) : null}
            </div>

            <DataTable
              className={"h-fit "}
              header={header_safelisting}
              value={vehicleId?.safelistings}
              tableStyle={{ minWidth: "50rem" }}
              dataKey="safelisting_id_format"
              stripedRows
              scrollable
              size="small"
              lazy
              emptyMessage="No data available"
              totalRecords={totalSafelistings}
            >
              
              <Column
                field="status"
                header="Status"
                body={statusBodyTemplate}
                filterPlaceholder="Search"
                className=" w-rem md:w-9rem lg:w-11rem "
                style={{  'whiteSpace': 'unset' }}/>

            
              <Column
                header="License Plate"
                field="license_plate"
                style={{ whiteSpace: "unset" }}
              />

              <Column
                field="start_date"
                header="Start Date"
                body={startDateBodyTemplate}
                filterPlaceholder="Search"
                className=" w-rem md:w-9rem lg:w-11rem "
                style={{  'whiteSpace': 'unset' }}/>

              <Column
                field="end_date"
                header="End Date"
                body={endDateBodyTemplate}
                filterPlaceholder="Search"
                className=" w-rem md:w-9rem lg:w-11rem "
                style={{  'whiteSpace': 'unset' }}/>

              <Column
                field="duration"
                header="Duration"
                body={durationDaysBodyTemplate}
                filterPlaceholder="Search"
                className=" w-rem md:w-9rem lg:w-11rem "
                style={{  'whiteSpace': 'unset' }}/>
                
                <Column
                header="Household"
                field="household_name"
                style={{ whiteSpace: "unset" }}
              />

              <Column
                field="created_at"
                dataType="date"
                header="Created At"
                body={createdDateBodyTemplate}
                //headerClassName=" w-19rem md:w-9rem lg:w-11rem "
                style={{ whiteSpace: "unset" }}
              />
              
            
            </DataTable>

            <br />

            <DataTable
              className={"h-fit "}
              header={header_citation}
              value={vehicleId?.citations}
              loading={isPendingVehicleByPropertyId}
              tableStyle={{ minWidth: "50rem" }}
              dataKey="citation_id_format"
              stripedRows
              scrollable
              size="small"
              lazy
              emptyMessage="No data available"
              
            >
              <Column
                field="created_at"
                dataType="date"
                header="Created At"
                body={createdDateBodyTemplate}
                //headerClassName=" w-19rem md:w-9rem lg:w-11rem "
                style={{ whiteSpace: "unset" }}
              />

              <Column
                field="citation_date"
                dataType="date"
                header="Citation Date"
                body={citationDateBodyTemplate}
                filterPlaceholder="Search"
                style={{ whiteSpace: "unset" }}
              />

              <Column
                header="License Plate"
                field="license_plate"
                style={{ whiteSpace: "unset" }}
              />

              <Column
                header="Citation Received"
                field="citation_types"
                style={{ whiteSpace: "unset" }}
              />

              <Column
                field="officer_name"
                header="User"
                style={{ whiteSpace: "unset" }}
              />

              <Column
                field="details"
                header="Details"
                style={{ whiteSpace: "unset" }}
              />
            </DataTable>

          </>
        ) : null}
      </div>
      <br /><br />
    </>
  );
};

export default VehicleProfile;
