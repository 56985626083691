import { useNavigate } from "react-router-dom"

import { Button } from "primereact/button";

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <>
            <h1>Unauthorized</h1>
            <p>You do not have access to the requested page.</p>
            <div className="flex">
                <Button
                    icon="pi pi-undo"
                    label="Go Back"
                    onClick={goBack}
                    severity="danger"
                    size="small"
                    style={{ backgroundColor: "#d32f2f80" }}
                />
            </div>
        </>
    )
}

export default Unauthorized