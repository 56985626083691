import { toast } from "react-toastify";
import { TOAST_CONFIG } from "./Constansts";

function HandleFetchErrors(response) {
  if (response?.response.status == 403) {
    console.log(response);
    toast.error(
      `An error ocurred during login, please try again.`,
      TOAST_CONFIG
    );
    return true;
  }

  if (response?.response.status == 401) {
    console.log(response);
    toast.error(`Unauthorized`, TOAST_CONFIG);
    return true;
  }

  return false;
}

export default HandleFetchErrors;
